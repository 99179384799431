
import Service from "../Service";

import MasterProposalEntity from "@coreEntity/master-proposal/MasterProposal.entity";
import BankEntity from "@coreEntity/master-proposal/proposal/bank/Bank.entity";
import ProposalApplicantCollection from "@coreEntity/master-proposal/proposal/Applicant.collection";
import ProposalApplicantEntity from "@coreEntity/master-proposal/proposal/ProposalApplicant.entity";
import ApplicantEntity from '@/core/entity/master-proposal/proposal/applicant/Applicant.entity';
import AppAddressCollection from '@/core/entity/master-proposal/proposal/applicant/ApplicantAddress.collection';
import AppEmploymentCollection from '@/core/entity/master-proposal/proposal/applicant/AppicantEmployment.collection';
import ApplicantAddressEntity from '@/core/entity/master-proposal/proposal/applicant/ApplicantAddress.entity';
import ApplicantEmploymentEntity from '@/core/entity/master-proposal/proposal/applicant/ApplicantEmployment.entity';

export default class MasterProposalService extends Service
{

    protected serviceName = "Master Proposal Service";

    masterProposalSearch (filters: any)
    {
        return this.post("/mp/list", filters);
    }

    getMasterProposal (masterProposalID: number, objGetFilters: any)
    {
        return this.post(`/mp/${masterProposalID}`, objGetFilters);
    }

    updateMasterProposal (mpEntity: MasterProposalEntity)
    {        
        return this.put(`/mp/${mpEntity.getID()}`, this.createApiFormat(mpEntity));
    }

    sign (masterProposalID, processID)
    {
        return this.post(`/mp/${masterProposalID}/sign`, {
            sign_method_id: processID
        });
    }

    getEsignDetails(masterProposalID, esignId)
    {
        return this.get(`/mp/${masterProposalID}/sign/${esignId}`);
    }

    createOpenBanking(masterProposalID)
    {
        return this.get(`/mp/${masterProposalID}/open-banking/start`)
    }

    getProofDocument(masterProposalID, documentId){

        return this.get(`/mp/${masterProposalID}/document/proof/${documentId}`);
    }

    getEsignDocument(masterProposalID, documentId){

        return this.get(`/mp/${masterProposalID}/document/esign/${documentId}`);
    }
    
    getWetSignDocument(masterProposalID, documentId){

        return this.get(`/mp/${masterProposalID}/document/wetSign/${documentId}`);
    }

    getOpenBankingRequests(masterProposalID)
    {
        return this.get(`/mp/${masterProposalID}/open-banking/list`);
    }
    /**
     * Get all master proposal events
     */
    getEvents (data: any = {})
    {
        return this.post(`/event/list`, data);
    }

    readEvents (eventIDs: number[])
    {
        return this.post(`/event/seen`, {
            proposal_master_event_ids: eventIDs
        });
    }

    /***
     *
     * Format methods
     *
     */

     /**
     *
     * Format methods
     *
     */

    protected createApiFormat (mpEntity: MasterProposalEntity):any {
        const proposalEntity = mpEntity.getProposalEntity();

        return {
            "proposal_master_funder_id": mpEntity.funderID,
            "proposal_master_broker_id": mpEntity.brokerID,
            "proposal_master_dealer_id": mpEntity.dealerID,
            "bank_entity": this.formatBankEntity(proposalEntity.getBankEntity()),
            "applicant_collection": this.formatApplicantCollection(proposalEntity.getAppCollection()),
            "dealer_fields": this.formatDealerFields(mpEntity)
        }
    }

    protected formatBankEntity (bankEntity: BankEntity) {
        return {
            "bank_name": bankEntity.name,
            "bank_account_num": bankEntity.accountNum,
            "bank_sortcode": bankEntity.sortcode,
            "bank_year_month": bankEntity.yearMonth
        };
    }

    protected formatApplicantCollection (proposalAppCollection: ProposalApplicantCollection): Array<any> {
        const arr = [];

        proposalAppCollection.getItems().forEach((val: ProposalApplicantEntity) => {
            const appEntity: ApplicantEntity = val.getApplicant();

            arr.push({
                "applicant_marital_id": appEntity.maritalID,
                "applicant_pre_id": appEntity.preID,
                "applicant_country_id": appEntity.countryID,
                "applicant_first_name": appEntity.firstName,
                "applicant_middle_name": appEntity.middleName,
                "applicant_last_name": appEntity.lastName,
                "applicant_dob": appEntity.dob,
                "applicant_gender_id": appEntity.genderID,
                "applicant_email": appEntity.email,
                "applicant_home_tel": appEntity.homeTel,
                "applicant_mobile_tel": appEntity.mobileTel,
                "applicant_driving_license": appEntity.drivingLicense,
                "applicant_monthly_income": appEntity.monthlyIncome,
                "applicant_national_insurance": appEntity.nationalInsurance,
                "applicant_passport_number": appEntity.passportNumber,
                "applicant_address_collection": this.formatAddressCollection(appEntity.getAddressCollection()),
                "applicant_employment_collection": this.formatEmploymentCollection(appEntity.getEmploymentCollection())
            });
        });

        return arr;
    }

    protected formatAddressCollection(appAddressCollection: AppAddressCollection): Array<any> {
        const arr = [];

        appAddressCollection.getItems().forEach((appAddressEntity: ApplicantAddressEntity) => {
            const addressEntity = appAddressEntity.getAddressEntity();

            arr.push({
                "applicant_address_type_id": appAddressEntity.addressTypeID,
                "applicant_address_residence_type_id": appAddressEntity.residenceTypeID,
                "applicant_address_start_date": appAddressEntity.startDate,
                "applicant_address_month_at": appAddressEntity.monthAt,
                "address_flat_num": addressEntity.flatNumber,
                "address_house_num": addressEntity.houseNum,
                "address_house_name": addressEntity.houseName,
                "address_line_1": addressEntity.line1,
                "address_town": addressEntity.town,
                "address_county": addressEntity.county,
                "address_postcode": addressEntity.postcode,
                "address_country_id": addressEntity.countryID
            });
        });

        return arr;
    }

    protected formatEmploymentCollection(appEmploymentCollection: AppEmploymentCollection): Array<any> {
        const arr = [];

        appEmploymentCollection.getItems().forEach((appEmploymentEntity: ApplicantEmploymentEntity) => {
            const employmentEntity = appEmploymentEntity.getEmploymentEntity();

            arr.push({
                "applicant_employment_employment_type_id": appEmploymentEntity.employmentTypeID,
                "applicant_employment_job": appEmploymentEntity.jobName,
                "applicant_employment_start_date": appEmploymentEntity.startDate,
                "applicant_employment_month_at": appEmploymentEntity.monthAt,
                "employment_name": employmentEntity.name,
                "employment_building_name": employmentEntity.buildingName,
                "employment_building_num": employmentEntity.buildingNum,
                "employment_line_1": employmentEntity.line1,
                "employment_town": employmentEntity.town,
                "employment_county": employmentEntity.county,
                "employment_postcode": employmentEntity.postcode
            });

        });

        return arr;
    }

    protected formatDealerFields(mpEntity: MasterProposalEntity)
    {
        const dealerFieldCollection = mpEntity.getDealerFieldsCollection();

        if(!dealerFieldCollection.hasItems()) {
            return null;
        }

        return dealerFieldCollection.mapDatabaseToObject();
    }

}