
import EntityCollection from "npm-marsh-core/src/entity/EntityCollection";

import ProposalApplicantEntity from "./ProposalApplicant.entity";

export default class AppCollection extends EntityCollection {

    public getNewItem (): ProposalApplicantEntity {
        return new ProposalApplicantEntity();
    }

}