
    import Vue from "vue";

    import StoreMap from "@/core/store/store.map";

    import AppUserEntity from "@secure/entity/user/AppUserEntity";
    import UserQuoteService from "@core/service/my/quote/Quote.service";

    export default Vue.extend({

        methods: {

            /**
             *
             * Getters
             *
             */

            storeUserGetSessionEntity (): AppUserEntity {
                return this.$store.getters[`${StoreMap.app_user_store}usrStoreGetUserEntity`];
            },

            /**
             * Get the users active supplier map
             * @returns
             */
            storeUserGetActiveSupplierGroup () {
                const userEntity: AppUserEntity = this.storeUserGetSessionEntity();

                if(!userEntity) {
                    return null;
                }

                return userEntity.getActiveSupplierMap();
            },

            /**
             * Get all the suppliers the user can access
             * @returns
             */
            storeUserSupGetCollection () {
                const userEntity: AppUserEntity = this.storeUserGetSessionEntity();

                if(!userEntity) {
                    return null;
                }

                return userEntity.getSupplierCollectionMap();
            },

            /**
             * Get the suppliers that are linked to the current active supplier
             */
            async apiUserGetActiveLinkedSuppliers () {
                const activeSupplierMap = this.storeUserGetActiveSupplierGroup();

                if(!activeSupplierMap) {
                    return null;
                }

                const serv = new UserQuoteService(this);
                return await serv.getActiveSupplierLinks();
            },

            /**
             * Get the active supplier data
             * @returns
             */
            storeUserGetActiveSupplierData () {
                return this.$store.getters[`${StoreMap.app_user_store}usrStoreGetActiveSupplierData`];
            },

            storeUserGetFullName () {
                return this.$store.getters[`${StoreMap.app_user_store}usrStoreGetName`];
            },

            storeUserGetAdmin () {
                return this.$store.getters[`${StoreMap.app_user_store}usrStoreGetAdmin`];
            },

            /**
             *
             * Actions
             *
             */

            storeUserActionUpdateUser (userEntity: AppUserEntity) {
                this.$store.dispatch(`${StoreMap.app_user_store}userStoreActUpdateUser`, userEntity);
            },

            async storeUserActionUpdateActiveSupplierData (quoteSupplierData) {
                this.$store.dispatch(`${StoreMap.app_user_store}userStoreActUpdateActiveSupplierData`, quoteSupplierData);
            },


            /**
             *
             * Methods
             *
             */

            /**
             * Does a user have a certain permission
             * @param permission
             * @returns
             */
            userIsAllowed (permission: string) {
                return this.storeUserGetSessionEntity().userCan(permission);
            },

            /**
             * Get the active terms of the current supplier
             * @returns
             */
            getActiveSupplierTermCollection () {
                const supp = this.storeUserGetActiveSupplierData();

                if(!supp) {
                    return null;
                }

                return supp.term_collection ? supp.term_collection: null;
            },

            /**
             * Get the active suppliers terms record
             * @returns
             */
            getActiveSupplierTerms () {
                const termCollection = this.getActiveSupplierTermCollection();

                if(!termCollection) {
                    return;
                }

                return termCollection.term_collection_terms;
            },

            /**
             * Get the supplier's products
             * @returns
             */
            getActiveSupplierTermGetProducts () {
                const   terms = this.getActiveSupplierTerms(),
                        arr = [];

                if(!terms) {
                    return null;
                }

                terms.forEach(quoteTerm => {
                    let prod = quoteTerm.term_product;

                    if(arr.indexOf(prod) === -1) {
                        arr.push(prod);
                    }
                });

                return arr;
            },

            /**
             * Get the suppliers terms via tier or (product?)
             * @param tier
             * @param product
             * @returns
             */
            getActiveSupplierTermGetTier (tier: number|null, product: string|null) {
                const   terms = this.getActiveSupplierTerms(),
                        arr = [];

                if(!terms) {
                    return null;
                }

                return terms.filter(ele => {
                    if(tier === null) {
                        return true;
                    }

                    if(ele.term_tier !== tier) {
                        return false;
                    }

                    if(product && ele.term_product !== product) {
                        return false;
                    }

                    return true;
                });
            }

        }

    });