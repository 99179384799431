

import Vue from 'vue';

import MasterProposalService from "@coreService/master-proposal/Index.service";

import EventBus from "@core/event-bus/EventBus";
import ListFilter from "./filter/Filter.vue";
import List from "./List.vue";

export default Vue.extend({

    props: {

        titleText: {
            type: String,
            default: "Master Proposal Search"
        },

        showResultTitle: {
            type: Boolean,
            default: true
        },

        noneFoundText: {
            type: String,
            default: null
        }

    },

    components: {
        ListFilter, List
    },

    data () {
        return {
            env: {
                defaultProposalStates: process.env.VUE_APP_MP_FILTER_DEFAULT_PROP_STATES ? process.env.VUE_APP_MP_FILTER_DEFAULT_PROP_STATES.split(",").map( Number ): null
            },
            proposal: {
                filter: {
                    mpID: null,
                    proposalStatus: [],
                    customerName: "",
                    dateFrom: "",
                    dateTo: "",
                    page: 1,
                    itemCount: 15,
                    orderListByColumn: 'mp_id',
                    orderListBy: (process.env.VUE_APP_MP_SEARCH_ORDER_BY ? process.env.VUE_APP_MP_SEARCH_ORDER_BY: 'asc')
                },
                pagination: {
                    list: [],
                    firstPage: null,
                    lastPage: null,
                    totalRows: null
                },
                httpSearch: {
                    errors: []
                }
            }
        };
    },

    mounted () 
    {
        if(this.env.defaultProposalStates.length) {
            this.proposal.filter.proposalStatus = this.env.defaultProposalStates;
        }

        EventBus.onUserSupplierGroupChanged(this.supplierChanged);
        this.search();
    },

    methods: {

        async search() 
        {
            this.proposal.httpSearch.errors = [];

            const   serv = new MasterProposalService(this),
                    httpData = await serv.masterProposalSearch(this.proposal.filter);

            let errors = httpData.getErrors(),
                data = httpData.getData(),
                dataErrors = data.errors ? data.errors : [];

            if(errors.length || dataErrors.length) {
                this.proposal.httpSearch.errors.push(errors);
                this.proposal.httpSearch.errors.push(dataErrors);
                return;
            }

            this.proposal.pagination.list = data.data;
            this.proposal.filter.page = data.current_page;
            this.proposal.pagination.firstPage = data.first_page;
            this.proposal.pagination.lastPage = data.last_page;
            this.proposal.pagination.totalRows = data.total_rows;
        },

        eventPageChangeEvent(pageNumber: number)
        {
            this.proposal.filter.page = pageNumber;
            this.search();
        },

        mpListOrderingChanged(orderVal)
        {
            this.proposal.filter.orderListBy = orderVal;
            this.search();
        },

        filterChanged()
        {
            this.reset();
            this.search();
        },

        supplierChanged()
        {
            this.reset();
            this.search();
        },

        reset()
        {
            this.proposal.filter.page = 1;
            this.proposal.pagination.list = [];
            this.proposal.pagination.firstPage = 0;
            this.proposal.pagination.lastPage = 0;
            this.proposal.pagination.totalRows = 0;
        }

    }

});
