import { DealerFieldEnum } from "./DealerField.enum";

/**
 * 
 * Interfaces
 * 
 */

interface DatabaseInterface
{
    field_type: string;
    value: any;
}

interface EnumConfig
{
    title: string;
    description: string;
}

interface FindEnumCallable 
{ 
    (value: DealerFieldEnum): EnumConfig|null 
}

interface ValidatorInterface 
{ 
    (value: any): ValidatorResultInterface
}

interface ValidatorResultInterface
{
    value: any;
    isValid: boolean;
}


/**
 * Enum Map
 * @returns 
 */
function getEnumMap()
{
    const result = {};

    const vehDetails = DealerFieldEnum.VEHICLE_DETAILS;
    const vehMileage = DealerFieldEnum.VEHICLE_MILEAGE;
    const quoteCash = DealerFieldEnum.QUOTE_CASHPRICE;
    const quoteAdvance = DealerFieldEnum.QUOTE_ADVANCE;
    const quotePartEx = DealerFieldEnum.QUOTE_PARTEX_VEHICLE;
    const quoteSettlement = DealerFieldEnum.QUOTE_SETTLEMENT;
    const quoteTerm = DealerFieldEnum.QUOTE_TERM;
    const quoteDeposit = DealerFieldEnum.QUOTE_DEPOSIT;

    result[vehDetails] = {
        title: 'Vehicle reg and description',
        description: 'Description of the vehicle the customer wants.'
    };

    result[vehMileage] = {
        title: 'Vehicle mileage',
        description: 'Current mileage of the vehicle.'
    };

    result[quoteCash] = {
        title: 'Quote cash price',
        description: 'Cash price of the vehicle.'
    };

    result[quoteAdvance] = {
        title: 'Quote advance',
        description: 'Advance'
    };

    result[quotePartEx] = {
        title: 'Quote partex vehicle',
        description: 'partex vehicle description'
    };

    result[quoteSettlement] = {
        title: 'Quote settlement',
        description: 'Settlement amount, if the customer has one.'
    };

    result[quoteTerm] = {
        title: 'Quote term',
        description: 'Number of months for the loan.'
    };

    result[quoteDeposit] = {
        title: 'Quote deposit',
        description: 'Customers max deposit.'
    };

    return result;
}

/**
 * Find an enum 
 * @param value 
 * @returns 
 */
const findEnumMap: FindEnumCallable = function(value: DealerFieldEnum)
{
    const map = getEnumMap();
    const config = map[value];

    if(!config) {
        return null;
    }

    return config;
}

export {
    DealerFieldEnum,
    DatabaseInterface,
    FindEnumCallable,
    getEnumMap,
    findEnumMap
}