<template>
    <v-container>
        <v-row>
            <AppFormInput label="Email" required>
                <AppTextField v-model.trim="form.model.username" />
            </AppFormInput>
        </v-row>

        <v-row>
            <AppErrorList :errors="form.errors" />
        </v-row>

        <v-row class="form-button-row">
            <AppButton class="success-button" colour="success" text="Next" @click="checkAccountStatus" />
        </v-row>
    </v-container>
</template>
<script>

    import AuthService from "@coreService/user/Auth.service";
    import AuthMixin from "@coreMixin/auth/Auth.mixin.js";

    export default {

        mixins: [ AuthMixin ],

        data () {
            return {
                form: {
                    model: {
                        username: ""
                    },
                    errors: []
                }
            };
        },

        mounted () {
            this.deleteAuth();
        },

        methods: {

            async checkAccountStatus () {
                const authService = new AuthService(this);
                const httpRes = await authService.checkAccountStatus(this.form.model.username);

                if(httpRes.hasErrors() === true) {
                    this.form.errors = httpRes.getErrors();
                    return;
                }

                const data = httpRes.getData();
                
                //the user needs to setup their
                if(data.account_needs_to_setup) {
                    this.$toasted.info('An email has been sent with a link to setup your 2FA');
                    // this.$emit("need2FA", {username: this.form.model.username, http_data: data});
                    // this.$emit("accountNeedsToSetup", {username: this.form.model.username, http_data: data});
                    return;
                }

                this.$emit("canLogin", {username: this.form.model.username, http_data: data});
            }

        }

    }
</script>