import Entity from "npm-marsh-core/src/entity/Entity";

import EntityChecklist from "@coreEntity/checklist/Checklist.entity";

export default class MasterProposalChecklist extends Entity {

    protected id: number|null = null;
    protected mpID: number|null = null;
    protected checklistID: number|null = null;
    protected supplierVisible: number = 0;
    protected createdAt: string|null = null;

    protected checklistEntity: EntityChecklist;

    protected mapDbProperties = {
        "proposal_master_checklist_id": "id",
        "proposal_master_checklist_proposal_master_id": "mpID",
        "proposal_master_checklist_checklist_id": "checklistID",
        "proposal_master_checklist_supplier_visible": "supplierVisible",
        "proposal_master_checklist_created_at": "createdAt",
        "proposal_master_checklist_entity": {
            entity_getter: "checklistEntity",
            entity_setter: "checklistEntity",
        }
    };

    constructor (checklistEntity: EntityChecklist|null = null) {
        super();

        this.checklistEntity = checklistEntity ? checklistEntity: new EntityChecklist();
    }

    /**
     *
     * Entity getters
     *
     */

    public getChecklistEntity (): EntityChecklist {
        return this.checklistEntity;
    }

    /**
     *
     * Getters
     *
     */

    public getID () {
        return this.id;
    }

    public getName () {
        return this.checklistEntity.getName();
    }

    public getDesc () {
        return this.checklistEntity.getDesc();
    }

    public getSupplierVisible () {
        return this.supplierVisible;
    }
}