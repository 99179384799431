
export default {

    namespaced: true,

    state: {

        supplier_collection: []

    },

    getters: {

        userSupStoreGetCollection (state)
        {
            return state.supplier_collection;
        }

    },

    mutations: {

        userSupStoreSetCollection (state, collection) {
            state.supplier_collection = collection;
        }

    }

}