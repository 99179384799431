
import Vue from 'vue';

export default Vue.extend({

    props: {

        centre: {
            type: Boolean,
            default: true
        }

    },

    computed: {

        classList () {
            const arr = ["app-social-links"];

            if(this.centre) {
                arr.push("centre");
            }

            return arr;
        }

    }

});
