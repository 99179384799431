
import Vue from 'vue';

import MixinUser from "@coreMixin/store/User/User.mixin";
import { AdminUserService } from "@coreService/admin/user/User.service";

export default Vue.extend({

    mixins: [ MixinUser ],

    computed: {

        formErrors () {
            const arr  = [];

            if(!this.model.user_email) {
                arr.push("Please enter an email");
            }

            return arr;
        },

        formSuccess () {
            return this.formErrors.length > 0 ? false: true;
        },

        isUserAdmin () {
            return this.storeUserGetAdmin();
        }

    },

    data () {
        return {
            boolSelect: [{text: "Yes", value: 1}, {text: "No", value: 0}],
            twoFaTypes: [{text: "Email", value: 'EMAIL'}, {text: "QR Code", value: 'QR_CODE'}],
            model: {
                user_admin: 0,
                user_email: "",
                user_first_name: "",
                user_last_name: "",
                user_2fa_type: "EMAIL",
            },
            httpErrors: []
        };
    },

    methods: {

        async createUser () {
            this.httpErrors = [];

            const   serv = new AdminUserService(this),
                    httpRes = await serv.create(this.model),
                    httpData = httpRes.getData();

            if(httpRes.hasErrors()) {
                this.httpErrors = httpRes.getErrors();
                this.$toasted.error("Failed to create user, see errors");
                return;
            }

            const httpErrors: any[] = httpData.errors;

            if(httpErrors.length) {
                this.$toasted.error("Failed to create user, see errors");
                this.httpErrors = httpErrors;
                return;
            }

            const userID = httpData.user_id;
            this.$toasted.success("User Created, redirecting...");
            this.$router.push({name: "user_id", params: { userID } });
        }

    }

});
