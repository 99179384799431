
import Entity from "npm-marsh-core/src/entity/Entity"

export default class EntityMpNote extends Entity {

    public id: number|null = null;
    public mpID: number|null = null;
    public typeID: number|null = null;
    public userID: number|null = null;
    public typeName: string = "";
    public text: string = "";
    public createdAt: string|null = "";

    protected mapDbProperties = {
        proposal_master_note_id: "id",
        proposal_master_note_proposal_master_id: "mpID",
        proposal_master_note_type_id: "typeID",
        proposal_master_note_type_name: "typeName",
        proposal_master_note_text: "text",
        proposal_master_note_created_at: "createdAt"
    }

    constructor () {
        super();
    }

    /**
     *
     * Getters
     *
     */



    public getID () {
        return this.id;
    }

    public getTypeID () {
        return this.typeID;
    }

    public getTypeName () {
        return this.typeName;
    }

    public getText () {
        return this.text;
    }

    public getCreatedAt () {
        return this.createdAt;
    }

}