



































































































































    import Vue from 'vue';

    //service
    import ServiceMpQuote from "@coreService/master-proposal/quote/MpQuote.service";

    //entities
    import EntityCollectionMpVehicle from "@coreEntity/master-proposal/vehicle/Vehicle.collection";
    import EntityMpVehicle from "@coreEntity/master-proposal/vehicle/Vehicle.entity";

    //mixins
    import QuoteStore from "@coreMixin/store/ApiQuote.mixin";
    import ConstantStore from "@coreMixin/store/Constant.mixin";
    import MixinUser from "@coreMixin/store/User/User.mixin";

    import TermsView from "@coreComponent/quote/TermsView.vue";

    /**
     *
     *
     * Abstract this later
     *
     *
     */
    export default Vue.extend({

        components: {
            TermsView
        },

        mixins: [ ConstantStore, QuoteStore, MixinUser ],

        props: {

            mpID: {
                type: Number
            },

            funderID: {
                type: [ Number, null ],
                default: null
            },
            brokerID: {
                type: [ Number, null ],
                default: null
            },
            dealerID: {
                type: [ Number, null ],
                default: null
            },

            mpVehicleCollection: {
                type: EntityCollectionMpVehicle
            }

        },

        computed: {

            localFormErrors (): String[] {
               let errors = [];

                if(!this.model.funderID && !this.model.dealerID && !this.model.brokerID) {
                    errors.push("A funder, broker or dealer must be choosen");
                }

                if(this.model.cash_price === 0 || !this.model.cash_price) {
                    errors.push("Invalid cash price");
                }

                if(this.isPCP === true && (!this.model.vehicle.id && !this.model.vehicle.registration && !this.model.vehicle.cap_code)) {
                    errors.push("PCP quote requires a vehicle cap code or registration");
                }

                if(this.isPCP === true && !this.model.vehicle.annual_mileage) {
                    errors.push("PCP quote requires an annual mileage");
                }

                return errors;
            },

            isHP () {
                let hpIDs = [1, 4];

                if(!(hpIDs.indexOf(this.model.loan_type_id) === -1)) {
                    return true;
                }

                return false;
            },

            isPCP () {
                let pcpID = [2, 3];

                if(!(pcpID.indexOf(this.model.loan_type_id) === -1)) {
                    return true;
                }

                return false;
            },

            vehicleListOpts () {
                let arr = [];

                arr.push({mp_veh_id: null, mp_veh_text: "Add New Vehicle" });

                //loop through each mp veh and normalise them
                if(this.mpVehicleCollection && this.mpVehicleCollection.getItemCount()) {
                    let mpVehicleCollItems: Array<EntityMpVehicle> = this.mpVehicleCollection.getItems();

                    mpVehicleCollItems.forEach((mpVehicleEntity: EntityMpVehicle) => {
                        arr.push({ mp_veh_id: mpVehicleEntity.getID(), mp_veh_text: mpVehicleEntity.getVehicleText()})
                    });
                }

                return arr;
            },

            termProducts () {
                return this.getActiveSupplierTermGetProducts();
            },

            loanTypeList () {
                const   productList = this.storeConstantGetList("quote_loan"),
                        termProducts = this.termProducts;

                const arr = productList.filter(cons => {
                    return termProducts.indexOf(cons.standardized) !== -1;
                });

                this.setupProduct(arr);

                return arr;
            },

            paymentFrequencyList () {
                return this.storeConstantGetList("proposal_payment_freq");
            },

            supplierTerms () {
                return this.getActiveSupplierTerms();
            },

            vehicleMileageList () {
                return this.storeConstantGetList("vehicle_mileage");
            },

            //KM or MILES
            defaultMileageType () {
                return process.env.VUE_APP_QUOTE_AUTO_SELECT_MILEAGE_TYPE ? process.env.VUE_APP_QUOTE_AUTO_SELECT_MILEAGE_TYPE: "MILES";
            },

            showCapCode () {
                const val = process.env.VUE_APP_SHOW_QUOTE_CAPCODE ? parseInt(process.env.VUE_APP_SHOW_QUOTE_CAPCODE): 1;

                return val === 1;
            },

            showVehicleReg () {
                const val =  process.env.VUE_APP_SHOW_QUOTE_VEH_REG ? parseInt(process.env.VUE_APP_SHOW_QUOTE_VEH_REG): 1;

                return val === 1;
            }

        },

        data () {
            return {
                show: {
                    uplift: parseInt(process.env.VUE_APP_SHOW_QUOTE_UPLIFT) ? true: false
                },
                modal: {
                    terms: false
                },
                model: {
                    funderID: null,
                    dealerID: null,
                    brokerID: null,
                    loan_type_id: 1,
                    payment_frequency_id : 1,
                    cash_price: 0,
                    deposit: 0,
                    part_ex: 0,
                    term: 36,
                    settlement: 0,
                    uplift: 0,
                    mileage_unit: "MILES",
                    vehicle: {
                        id: null,
                        cap_code: null,
                        registration: null,
				        registration_date: null,
				        mileage: null,
				        annual_mileage: 0
                    }
                },
                http: {
                    loadingRequest: false,
                    errors: []
                }
            };
        },

        mounted () {
            this.model.mileage_unit = this.defaultMileageType;

            this.init();
        },

        methods: {

            init () {
                this.mpQuoteService = new ServiceMpQuote(this);
                this.setInitProps();
            },

            /**
             * Create the quote
             */
            async createMpQuote () {
                this.http.loadingRequest = true;

                const   httpRes = await this.mpQuoteService.createFullQuote(this.mpID, this.model),
                        httpData = httpRes.getData();

                this.http.loadingRequest = false;

                if(httpRes.hasErrors()) {
                    this.http.errors = httpRes.getErrors();
                    return;
                }

                const   dataErrors = [].concat(httpData.errors, httpData.quoting_errors),
                        quoteCollection = httpData.quote_collection;

                //errors and no quotes
                if(dataErrors.length && !quoteCollection.length) {
                    this.http.errors = dataErrors;
                    return;
                }

                this.$toasted.success("Quote created");
                this.$emit("mp-refresh");
            },

            setInitProps () {
                if(this.funderID) {
                    this.model.funderID = this.funderID;
                }

                if(this.brokerID) {
                    this.model.brokerID = this.brokerID;
                }

                if(this.dealerID) {
                    this.model.dealerID = this.dealerID;
                }
            },

            setupProduct (products) {
                if(products.length > 1) {
                    return;
                }

                this.model.loan_type_id = products[0].id;
            }

        }

    });
