
import Vue from 'vue';

import CoreStoreMixin from "npm-marsh-core/src/vue/mixins/Store.Core.mixin";

import UserMixin from "@coreMixin/store/User/User.mixin";

//components
import RegionLogo from "@coreComponent/_ui/logo/Logo.vue";
import UserMenu from "./menu/Index.vue";
import EventViewer from "./event/EventViewer.vue";
import SupplierViewer from "./supplier/SupplierView.vue";

export default Vue.extend({

    mixins: [ CoreStoreMixin, UserMixin ],

    components: {
        RegionLogo, UserMenu, EventViewer, SupplierViewer
    },

    computed: {

        showLoadingSpinner () {
            return this.storeGetLoadingSpinner();
        }

    }

});
