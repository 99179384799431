
import Entity from "npm-marsh-core/src/entity/Entity";

import EntityQuote from "@/core/entity/quote/Quote.entity";
import EntityVehicle from "@coreEntity/vehicle/Vehicle.entity";
import EntityUser from "@coreEntity/user/User.entity";

import CollectionCommission from "./commission/Commission.collection";

export default class EntityProposalQuote extends Entity {

    protected   id: number|null = null;
    protected   proposalID: number|null = null;
    protected   quoteID: number|null = null;
    protected   vehicleID: number|null = null;
    protected   userID: number|null = null;
    protected   inputJson: string|null = null;
    protected   createdAt: string|null = null;

    protected   quoteEntity: EntityQuote;
    protected   vehicleEntity: EntityVehicle;
    protected   userEntity: EntityUser;
    protected   collectionCommission: CollectionCommission;

    protected mapDbProperties = {
        "proposal_quote_id": "id",
        "proposal_quote_proposal_id": "proposalID",
        "proposal_quote_quote_id": "quoteID",
        "proposal_quote_vehicle_id": "vehicleID",
        "proposal_quote_user_id": "userID",
        "proposal_quote_input_json": "inputJson",
        "proposal_quote_created_at": "createdAt",
        "proposal_quote_quote_entity": {
            entity_getter: "quoteEntity",
            entity_setter: "quoteEntity"
        },
        "proposal_quote_vehicle_entity": {
            entity_getter: "vehicleEntity",
            entity_setter: "vehicleEntity"
        },
        "proposal_quote_user_entity": {
            entity_getter: "userEntity",
            entity_setter: "userEntity"
        },
        "proposal_quote_commission_collection": {
            entity_getter: "collectionCommission",
            entity_setter: "collectionCommission"
        }
    };

    constructor (quoteEntity: EntityQuote|null = null, vehicleEntity: EntityVehicle|null = null,  userEntity: EntityUser|null = null) {
        super();

        this.vehicleEntity = vehicleEntity ? vehicleEntity: new EntityVehicle();
        this.quoteEntity = quoteEntity ? quoteEntity: new EntityQuote();
        this.userEntity = userEntity ? userEntity: new EntityUser();
        this.collectionCommission = new CollectionCommission();
    }

    /**
     *
     * Entity methods
     *
     */

    public getQuoteEntity (): EntityQuote {
        return this.quoteEntity;
    }

    public getVehicleEntity (): EntityVehicle {
        return this.vehicleEntity;
    }

    public getUserEntity (): EntityUser {
        return this.userEntity;
    }

    public hasVehicleEntity (): boolean {
        return this.vehicleEntity ? !this.vehicleEntity.isEmpty(): false;
    }

    public hasUserEntity (): boolean {
        return this.userEntity ? !this.userEntity.isEmpty(): false;
    }

    public getCollectionCommission () {
        return this.collectionCommission;
    }

    /**
     *
     * Methods
     *
     */

    public canActivate(activateQuoteID: number|null = null): boolean 
    {
        if(activateQuoteID && activateQuoteID === this.getID()) {
            return false;
        }        

        if(this.hasVehicleEntity() === false) {
            return false;
        }

        if(this.getQuoteEntity().canActivate() === false) {
            return false;
        }

        return true;
    }

    public getQuoteSummary (): string
    {
        const   vehicleSummary = this.hasVehicleEntity() ? this.getVehicleEntity().getShortDecription(): "",
                quoteSummary = this.getQuoteEntity().getShortDecription();

        return `${vehicleSummary} ${quoteSummary}`.trim();
    }

    public getVehicleText (): string 
    {
        const vehicleText = this.vehicleEntity.getDescriptiveText(false);
        const quoteMileage = this.quoteEntity.getVehicleMileage();
        const quoteMileageUnit = this.quoteEntity.getVehicleMileageUnit();
        
        if(!vehicleText) {
            return "No Vehicle";
        }

        return `${vehicleText}, ${quoteMileage} ${quoteMileageUnit}`;
    }

    public getVehicleShortText (): string {
        return this.vehicleEntity.getShortDecription();
    }

    public getUserText (): string {
        return this.hasUserEntity() ? this.getUserEntity().getName(): "No User";
    }

    public getProductTypeID () {
        return this.quoteEntity.getTypeID();
    }

    public getInputJson () {
        return this.inputJson;
    }

    /**
     *
     * Getters
     *
     */

    public getID () {
        return this.id;
    }

    public getTier ()
    {
        return this.getQuoteEntity().getTier();
    }

    public getCreatedAt () {
        return this.createdAt;
    }

}