



























































import MasterProposalService from "@coreService/master-proposal/Index.service";
import MasterProposalEntity from "@coreEntity/master-proposal/MasterProposal.entity";
import OpenBankingEntity from "@coreEntity/open-banking/OpenBanking.entity"
import OpenBankingCollection from "@coreEntity/open-banking/OpenBanking.collection"
//mixin
import MixinConstant from "@coreMixin/store/Constant.mixin";

//component
// import BankList from './list.vue';

export default
    {
        mixins: [MixinConstant],

        components: {
            // BankList,
        },

        props: {
            mpEntity: {
                type: MasterProposalEntity
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            masterProposalID() {
                return this.mpEntity.getID();
            }
        },

        data() {
            return {
                proposalApplicantID: 0,
                proposalApplicants: [
                    {
                        text: "All",
                        value: 0
                    }
                ],
                openBank: {
                    list: [],
                    errors: []
                },
                proposalExpired: false, // add this in
                createActive: false
            };
        },

        mounted() {
            this.masterProposalService = new MasterProposalService(this);
            this.getCases();
        },

        methods: {

            copyLink(index) {
                Array.from(document.getElementsByClassName("copied-check")).forEach((element) => {
                    element.classList.remove("visible");
                });

                if (!navigator.clipboard) {
                    this.$refs["open-bank-link-" + index][0].select();

                    if (document.execCommand("copy")) {
                        this.$refs["check-" + index][0].classList.add("visible");
                    }
                    else {
                        this.$toasted.error("Could not copy link");
                    }
                }
                else {
                    navigator.clipboard.writeText(this.$refs["open-bank-link-" + index][0].value).then(() => {
                        this.$refs["check-" + index][0].classList.add("visible");
                    })
                        .catch(() => {
                            this.$toasted.error("Could not copy link");
                        });
                }

            },


            getStatusText(openBankStatusID) {
                let bankStatusConstant = this.storeConstantGetListItemID("bank_status", openBankStatusID);

                if (bankStatusConstant === null) {
                    return "Not Found";
                }

                return bankStatusConstant.text;
            },
            //start a banking process
            createCase() {
                this.createActive = true;

                this.httpCreateCase(this.masterProposalID).then(res => {
                    if (!res) {
                        console.log(res);
                        return;
                    }
                    else if (res["errors"].length > 0) {
                        for (var error of res["errors"]) {
                            this.$toasted.error(error);
                        }
                        return;
                    }

                    this.getCases();
                    this.createActive = false;

                })
                    .catch(err => {
                        this.$toasted.error("Failed to make open banking request");
                        console.log(err);
                    });
            },

            // get open banking cases
            getCases() {
                const me = this;

                this.httpGetCases(this.masterProposalID).then(res => {
                    me.openBank.errors = res["errors"];

                    if (me.openBank.errors.length) {
                        me.$toasted.error("Failed to get open banking cases");
                    }
                    else if (res["data"]) {
                        Array.from(document.getElementsByClassName("copied-check")).forEach((element) => {
                            element.classList.remove("visible");
                        });

                        const openBankingCollection = new OpenBankingCollection();
                        openBankingCollection.mapApplyDatabase(res["data"]);
                        
                        me.openBank.list = openBankingCollection.getItems().slice().reverse();
                    }
                })
                .catch(err => {
                    console.log(err);
                    me.$toasted.error("Failed to make open banking request");
                });
            },

            //http
            httpCreateCase(masterProposalID) {
                const mpService = new MasterProposalService(this);
                return mpService.createOpenBanking(masterProposalID);
            },

            httpGetCases(masterProposalID) {
                const mpService = new MasterProposalService(this);
                return mpService.getOpenBankingRequests(masterProposalID);
            }
        }

    };

