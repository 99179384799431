    import MasterProposalEntity from '@/core/entity/master-proposal/MasterProposal.entity';
    import ApplicantEntity from '@/core/entity/master-proposal/proposal/applicant/Applicant.entity';
    import ApplicantAddressEntity from '@/core/entity/master-proposal/proposal/applicant/ApplicantAddress.entity';
    import ApplicantEmploymentEntity from '@/core/entity/master-proposal/proposal/applicant/ApplicantEmployment.entity';
    import ProposalApplicant from '@/core/entity/master-proposal/proposal/ProposalApplicant.entity';
    import AddressEntity from '@/core/entity/master-proposal/proposal/applicant/address/Address.entity';
    import EmploymentEntity from '@/core/entity/master-proposal/proposal/applicant/employment/Employment.entity';

    export default function (mpEntity: MasterProposalEntity) {

        const proposalEn = mpEntity.getProposalEntity(),
            appColl = proposalEn.getAppCollection().getItems();

        if(!appColl.length) {
            return;
        }

        const proposalAppEntity: ProposalApplicant = appColl[0],
            appEntity: ApplicantEntity = proposalAppEntity.getApplicant();

            setApplicantData(appEntity);
            setAddressData(appEntity);
            setEmploymentData(appEntity);
    }

    function setApplicantData (appEntity: ApplicantEntity) {
        appEntity.preID = 1;
        appEntity.maritalID = 1;
        appEntity.genderID = 1;
        appEntity.countryID = 1;

        appEntity.firstName = "MARK";
        appEntity.middleName = "";
        appEntity.lastName = "MERCEDES";
        appEntity.email = "abutler@marshfinance.co.uk";
        appEntity.dob = "1969-03-24";
        appEntity.monthlyIncome = null;
        appEntity.homeTel = "";
        appEntity.mobileTel = "";
        appEntity.drivingLicense = "";
    }

    function setAddressData (appEntity: ApplicantEntity) {
        const addressCollection: ApplicantAddressEntity[] = appEntity.getAddressCollection().getItems();

        if(!addressCollection.length) {
            let appAddressEntity: ApplicantAddressEntity = new ApplicantAddressEntity();
            addressCollection.push(appAddressEntity);
        }

        let appAddressEntity: ApplicantAddressEntity = addressCollection[0],
            addressEntity: AddressEntity = appAddressEntity.getAddressEntity();

        appAddressEntity.monthAt = 39;
        appAddressEntity.residenceTypeID = 1;
        appAddressEntity.addressTypeID = 1;

        addressEntity.flatNumber = "6";
        addressEntity.houseNum = "64";
        addressEntity.houseName = "TEST HOUSE";
        addressEntity.line1 = "TOP GEAR LANE";
        addressEntity.town = "TEST TOWN";
        addressEntity.county = "";
        addressEntity.postcode = "X9 9LF";
        addressEntity.countryID = 1;
        appAddressEntity.startDate =  null;
    }

    function setEmploymentData (appEntity: ApplicantEntity) {
        const employmentCollection: ApplicantEmploymentEntity[] = appEntity.getEmploymentCollection().getItems();

        if(!employmentCollection.length) {
            let appEmploymentEntity: ApplicantEmploymentEntity = new ApplicantEmploymentEntity();
            employmentCollection.push(appEmploymentEntity);
        }

        let appEmploymentEntity: ApplicantEmploymentEntity = employmentCollection[0],
            employmentEntity: EmploymentEntity = appEmploymentEntity.getEmploymentEntity();

        appEmploymentEntity.jobName = "Test";
        appEmploymentEntity.employmentTypeID = 1;
        appEmploymentEntity.monthAt = 40;
        appEmploymentEntity.startDate =  null;

        employmentEntity.name = "Test Job Title";
        employmentEntity.line1 = "test";
        employmentEntity.town = "test";
        employmentEntity.county = "test";
        employmentEntity.postcode = "OL";
    }
