<template>

    <div v-if="entityVehicle" class="component-proposal-vehicle">

        <v-row>

            <v-col cols="6">
                <AppContentContainer>
                    <AppPageSubHeader2 text="Details" />

                    <AppFormInput label="Cap code">
                        <AppTextField :disabled="disabled" v-model.trim="entityVehicle.capCode" />
                    </AppFormInput>

                    <AppFormInput label="Vin">
                        <AppTextField :disabled="disabled" v-model.trim="entityVehicle.vin" />
                    </AppFormInput>

                    <AppFormInput label="Registration">
                        <AppTextField :disabled="disabled" v-model.trim="entityVehicle.reg" />
                    </AppFormInput>

                    <AppFormInput label="Registration Date">
                        <AppDateField :disabled="disabled" v-model="entityVehicle.regDate" />
                    </AppFormInput>

                    <AppFormInput label="Mileage">
                        <AppTextField :disabled="disabled" v-model.number="entityVehicle.mileage" />
                    </AppFormInput>

                    <AppFormInput label="Cash Price">
                        <AppTextField :disabled="disabled" v-model.number="entityVehicle.cashPrice" />
                    </AppFormInput>

                    <AppFormInput label="Cash Price (GGR)">
                        <AppTextField :disabled="disabled" v-model.number="entityVehicle.cashPriceGGR" />
                    </AppFormInput>

                    <AppFormInput label="Imported">
                        <AppSelect :items="[{text: 'No', value: 0}, {text: 'Yes', value: 0}]"
                            :disabled="disabled" v-model.number="entityVehicle.imported"
                        />
                    </AppFormInput>

                    <AppFormInput label="Exported">
                        <AppSelect :items="[{text: 'No', value: 0}, {text: 'Yes', value: 0}]"
                            :disabled="disabled" v-model.number="entityVehicle.exported"
                        />
                    </AppFormInput>

                    <AppFormInput label="Scrapped">
                        <AppSelect :items="[{text: 'No', value: 0}, {text: 'Yes', value: 0}]"
                            :disabled="disabled" v-model.number="entityVehicle.scrapped"
                        />
                    </AppFormInput>
                </AppContentContainer>
            </v-col>

            <v-col cols="6">
                <!-- car text -->
                <AppContentContainer>
                    <AppPageSubHeader2 text="Car" />

                    <AppFormInput label="Make">
                        <AppTextField :disabled="disabled" v-model="entityVehicle.make" />
                    </AppFormInput>

                    <AppFormInput label="Model">
                        <AppTextField :disabled="disabled" v-model="entityVehicle.model" />
                    </AppFormInput>

                    <AppFormInput label="Trim">
                        <AppTextField :disabled="disabled" v-model="entityVehicle.trim" />
                    </AppFormInput>

                    <AppFormInput label="Range">
                        <AppTextField :disabled="disabled" v-model="entityVehicle.desc" />
                    </AppFormInput>

                    <AppFormInput label="Colour">
                        <AppTextField :disabled="disabled" v-model="entityVehicle.colour" />
                    </AppFormInput>

                    <AppFormInput label="Doors">
                        <AppTextField :disabled="disabled" v-model.number="entityVehicle.doors" />
                    </AppFormInput>

                    <AppFormInput label="Fuel">
                        <AppSelect :items="vehicleFuelList" itemTextKey="text" itemValueKey="id" addEmptyValue
                            :disabled="disabled" v-model.number="entityVehicle.fuelID"
                        />
                    </AppFormInput>

                    <AppFormInput label="Transmission">
                        <AppSelect :items="vehicleTransmissionList" itemTextKey="text" itemValueKey="id" addEmptyValue
                            :disabled="disabled" v-model.number="entityVehicle.transmID"
                        />
                    </AppFormInput>
                </AppContentContainer>
            </v-col>

        </v-row>

    </div>

</template>

<script>

    import ConstantMixin from "@coreMixin/store/Constant.mixin";

    import EntityVehicle from "@coreEntity/vehicle/Vehicle.entity";

    export default {

        mixins: [ ConstantMixin ],

        props: {
            entityVehicle: {
                type: EntityVehicle
            },

            disabled: {
                type: Boolean,
                default: false
            },

            userCanEnterFinance: {
                type: Boolean,
                default: false
            }
        },

        filters: {
            boolToText (bool) {
                return bool === true ? "Yes": "No";
            },
            nullToText (value) {
                return value === null ? "Not set": value;
            }
        },

        computed: {

            vehicleFuelList () {
                return this.storeConstantGetList("vehicle_fuel");
            },

            vehicleTransmissionList () {
                return this.storeConstantGetList("vehicle_transmission");
            }
        },

        data () {
            return {
                vehicle: {
                    hadVehicleReg: false,
                    hadVehicleVin: false,
                    hadVehicleRegDate: false
                },
            };
        }

    };

</script>

<style lang="scss">

    .component-proposal-vehicle {

        .app-c-e-content-container {
            padding: 0;
            margin-bottom: 2rem;
        }

        .form-control {
            font-size: .8rem;
        }
    }

</style>
