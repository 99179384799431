
import Entity from "npm-marsh-core/src/entity/Entity";

export default class EntityUserSupplier extends Entity {

    protected   id: number|null = null;
    protected   userID: number|null = null;
    protected   supplierID: number|null = null;
    protected   supplierEnum: string|null = null;

    protected mapDbProperties = {
        user_supplier_map_id: "id",
        user_supplier_map_user_id: "userID",
        user_supplier_map_supplier_enum: "supplierEnum",
        user_supplier_map_supplier_id: "supplierID"
    };

    public getID ()
    {
        return this.id;
    }

    public getSupplierID ()
    {
        return this.supplierID;
    }

    public getSupplierEnum ()
    {
        return this.supplierEnum;
    }

}