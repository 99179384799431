

//services
import MpDocService from "@coreService/master-proposal/document/MpDocument.service";

//mixin
import MixinConstant from "@coreMixin/store/Constant.mixin";

//entity
import CollectionMpUpload from "@coreEntity/master-proposal/upload/MpUpload.collection";

import MasterProposalService from "@coreService/master-proposal/Index.service";
import CollectionWetSign from "@coreEntity/master-proposal/wetsign/WetSign.collection"
import MasterProposalEntity from "@/core/entity/master-proposal/MasterProposal.entity";

export default {

    mixins: [MixinConstant],

    props: {
        mpEntity:
        {
            type: MasterProposalEntity
        },
        masterProposalID: {
            type: [Number]
        },

        collectionMpUploads: {
            type: CollectionMpUpload
        }
    },

    computed: {

        proposalDocTypes() {
            return this.storeConstantGetList("proposal_document");
        },

        mpDocList() {
            return this.collectionMpUploads.getItems();
        }

    },

    data() {
        return {
            view_id_collection: [],
            updated_collection: [],
            modal: {
                    edit: false,
                    create: false,
                    split: false
                },
            editDocument: null,
            deleteUpload: {
                id: null,
                open: false
            },
            split: {
                modal_open: false,
                mp_doc_id: null
            }
        };
    },
    created() {
        this.masterProposalService = new MasterProposalService(this);
    },

    mounted() {
    },
    methods: {
        getDocumentType(typeId: number)
        {   
            for(const type of this.proposalDocTypes){
                if(type.id == typeId){
                    return type.text
                }
            }
        },

        async openEditProof (entityMpUpload) {
            this.modal.edit = true;
            this.editDocument = entityMpUpload;
        },

        //view the upload document contents
        getWetDocByID(wetDocID) 
        {
            const mpService = new MasterProposalService(this);
            return mpService.getWetSignDocument(this.masterProposalID, wetDocID);
        },

        async viewProof(documentID) 
        {
            const mpService = new MasterProposalService(this);
            const httpRes = await mpService.getProofDocument(this.masterProposalID, documentID);

            //check for errors and display each of them.
            if (httpRes.hasErrors()) {
                for (let error of httpRes.getErrors()) {
                    this.$toasted.error(error);
                }

                return;
            }

            //okay
            const data = httpRes.getData();

            const byteCharacters = atob(data.document_base64);
            const byteArray = new Uint8Array(byteCharacters.length);
            
            for (let i = 0; i < byteCharacters.length; i++) {
                byteArray[i] = byteCharacters.charCodeAt(i);
            }

            // Create a Blob from the Uint8Array
            const blob = new Blob([byteArray], { type: data.document_name });

            // Create a download link
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = data.document_name;

            // Simulate a click on the link to trigger the download
            downloadLink.click();

            // Clean up
            URL.revokeObjectURL(downloadLink.href);

            //tell the user
            this.$toasted.success('Your proof has been downloaded.');
        },

    }

};

