
import Service from "../../Service";

import MpService from "../Index.service";

import MasterProposalEntity from "@coreEntity/master-proposal/MasterProposal.entity";

export default class CreateMasterProposalService extends MpService
{

    protected serviceName = "Create Master Proposal Service";

    public async createMP (mpEntity: MasterProposalEntity) 
    {        
        return this.post("/mp/create", this.createApiFormat(mpEntity));
    }

}