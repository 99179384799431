
import Vue from 'vue';

import HttpResponse from '@/core/service/_lib/Response';
import MixinUser from "@coreMixin/store/User/User.mixin";
import MixinQuote from "@coreMixin/store/ApiQuote.mixin";
import EventBus from "@core/event-bus/EventBus";

import MpSupplierForm from "@coreComponent/master-proposal/supplier/Form.vue";
import BankForm from "@coreComponent/bank/Form/Form.vue";
import DealerFieldsCreate from "@coreComponent/master-proposal/id/dealer-fields/create/Create.vue";
import ApplicantsForm from "./Applicant/Applicants.vue";

export default Vue.extend({

    mixins: [ MixinUser, MixinQuote ],

    components: {
        BankForm, MpSupplierForm, ApplicantsForm, DealerFieldsCreate
    },

    props: {

        mpEntity: {

        }

    },

    computed: {

        showSuppliersForm () {
            return this.brokerList.length >= 1 || this.dealerList.length >= 1;
        },

        brokerList () {
            if(!this.linked_suppliers.length) {
                return [];
            }

           return this.buildBrokerList();
        },

        dealerList () {
            if(!this.linked_suppliers.length) {
                return [];
            }

            return this.buildDealerList();
        }
    },

    data () {
        return {
            linked_suppliers: [],
            no_active_supplier: false,
            show: {
                dealer_fields: parseInt(process.env.VUE_APP_MP_SHOW_DEALER_FIELDS) ? true: false
            }
        }
    },

    created () {
        EventBus.onUserSupplierGroupChanged(this.getLinkedSuppliers);
        this.getLinkedSuppliers();
    },

    methods: {

        async getLinkedSuppliers () {
            this.linked_suppliers = [];

            const httpRes: HttpResponse = await this.apiUserGetActiveLinkedSuppliers();

            if(httpRes.hasErrors()) {
                this.no_active_supplier = true;
                return;
            }

            this.linked_suppliers = httpRes.getData();
        },

        buildBrokerList () {
            const arr = [],
                  brokers: any[] = this.storeSuppliersGetBrokers();

            this.linked_suppliers.forEach(element => {

                if(element.supplier_relationship_supplier_type_id !== 2) {
                    return;
                }

                let broker = brokers.find(broker => {
                    return broker.broker_id === element.supplier_relationship_supplier_id;
                });

                if(!broker) {
                    return;
                }

                arr.push(broker);
            });

            return arr;
        },

        buildDealerList () {
            const arr = [],
                  dealers: any[] = this.storeSuppliersGetDealers();

            this.linked_suppliers.forEach(element => {

                if(element.supplier_relationship_supplier_type_id !== 3) {
                    return;
                }

                let dealer = dealers.find(dealer => {
                    return dealer.dealer_id === element.supplier_relationship_supplier_id;
                });

                if(!dealer) {
                    return;
                }

                arr.push(dealer);
            });

            return arr;
        }

    }

});
