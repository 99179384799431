
import Vue from 'vue';

import MpService from "@coreService/master-proposal/Index.service";
import MixinAll from "@coreMixin/store/Index.mixin";

import EventManager from "@coreComponent/event/Manager.vue";

export default Vue.extend({

    mixins: [ MixinAll ],

    components: {
        EventManager
    },

    computed:
    {
        eventCount ()
        {
            const pag = this.mixinEventGetPaginationData();
            return pag ? pag.data.length: 0;
        }
    },

    data () {
        return {
            modal: false,
            config: {
                intervalTimer: null,
                msInterval: process.env.VUE_APP_EVENT_LOOP_TIME ? parseInt(process.env.VUE_APP_EVENT_LOOP_TIME): 300000,
                browserNotificationsAllowed: false
            }
        };
    },

    destroyed () {
        this.clearIntervalTimer();
    },

    mounted ()
    {
        this.setupBrowserNotification();
        this.setupEvents();
    },

    methods: {

        setupEvents ()
        {
            this.clearIntervalTimer();

            this.config.intervalTimer = setInterval(() => {
                    this.intervalProcess();
                },
                this.config.msInterval
            );

            this.intervalProcess();
        },

        intervalProcess ()
        {
            this.getEvents();
            this.setNextIntervalTime();
        },

        //get our events
        async getEvents ()
        {
            const serv = new MpService(this);
            const httpRes = await serv.getEvents(this.mixinEventGetRequestFilters());

            //set the events in the store
            this.mixinEventSetEventHttp(httpRes);

            if(this.config.browserNotificationsAllowed === true) {
                this.sendBrowserNotifications(httpRes.getData());
            }
        },

        async fireReadEvents (obj)
        {
            const serv = new MpService(this);
            const httpRes = await serv.readEvents(obj.event_ids);

            if(httpRes.hasErrors()) {
                this.$toasted.error("Failed to read the events");
                return;
            }

            this.$toasted.success("Events read");
            this.setupEvents();
            this.moveToMp(obj.mp_id);
        },

        //jsut get it working
        refresh ()
        {
            this.getEvents();
            this.$toasted.success("Events refreshed");
        },

        setNextIntervalTime ()
        {
            const ms: number = Date.now() + this.config.msInterval;
            const dateTime = this.$moment(ms).format("DD/MM/YYYY H:m:s");

            this.mixinEventUpdateNextRunTime(dateTime);
        },

        openModal ()
        {
            this.modal = true;
        },

        clearIntervalTimer ()
        {
            if(this.config.intervalTimer) {
                clearInterval(this.config.intervalTimer);
            }
        },

        /**
         * Setup the browser notifications if we can
         */
        setupBrowserNotification ()
        {
            if(!window.Notification) {
                return;
            }

            if(Notification.permission === "granted") {
                this.config.browserNotificationsAllowed = true;
                return;
            }

            const me = this;
            Notification.requestPermission().then(function (permission) {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    me.config.browserNotificationsAllowed = true;
                    return;
                }
            });
        },

        sendBrowserNotifications (data)
        {
            const totalItems: number = data.pagination.total_rows;

            if(totalItems === 0) {
                return null;
            }

            //get the notifications that are unseen
            const unseenCount: any[] = data.pagination.data.filter(ele => {
                return ele.proposal_master_event_user_viewed_id === null;
            });

            if(unseenCount.length === 0) {
                return null;
            }

            //build the notification config
            const opts: NotificationOptions = {
                tag: 'Salesdot events unseen',
                renotify: true,
                body: "Unseen salesdot events are waiting",
                requireInteraction: false,
                silent: true
            };

            //fire notification api
            new Notification(`(${unseenCount.length}) New notifications are waiting`, opts);
        },

        moveToMp (mpID)
        {
            this.$router.push({name: 'mp_id', params: { id: mpID }})
        }

    }

});
