
import EntityCollection from "npm-marsh-core/src/entity/EntityCollection";

import EntityCommission from "./Commission.entity";

export default class EntityCollectionCollection extends EntityCollection {

    public getNewItem (): EntityCommission {
        return new EntityCommission();
    }

}