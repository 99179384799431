
/**
 *
 *
 * Validation methods
 *
 *
 */
export class Validation
{

    private static emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


    public static validateEmail (email: string): boolean
    {
        return Validation.emailRegex.test(email);
    }

}