<script>

    export default {

        props: {

            termCollection: {
                type: Array
            },

            showCommission: {
                type: Boolean,
                default: false
            },

            //not "important columns"
            showOtherColumns: {
                type: Boolean,
                default: false
            }

        }

    }

</script>
<template>

    <div class="supplier-term-readonly-container">
        <AppPageHeader text="Your Terms" />

       <div class="table-overflow">
            <AppTable>
                <thead>
                   <tr>
                        <td>Product</td>
                        <td>Credit Tier</td>
                        <td v-if="showCommission">Commission Structure</td>
                        <td v-if="showOtherColumns">Flat Rate</td>
                        <td>Apr</td>
                        <td>Term Freq</td>
                        <td v-if="showCommission">Fixed Commission</td>
                        <td>POA %</td>
                        <td v-if="showOtherColumns">DIC Formula</td>
                        <td>Min Advance</td>
                        <td>Max Advance</td>
                        <td>Min Term</td>
                        <td>Max Term</td>
                        <td v-if="showCommission">Max Commission</td>
                        <td v-if="showCommission">Commission Cap</td>
                        <td v-if="showCommission">Commission Supplier Display %</td>
                        <td v-if="showOtherColumns">Max Insurance Group</td>
                        <td>Max LTV</td>
                        <td>Max Mileage</td>
                        <td>Option Fee</td>
                        <td>Doc Fee</td>
                        <td v-if="showOtherColumns">Device Required</td>
                        <td v-if="showOtherColumns">GFV %</td>
                        <td>Term Vaea</td>
                        <td>Term Vasa</td>
                   </tr>
                </thead>
                <tbody v-if="termCollection.length">
                    <tr v-for="(dbTermData, index) in termCollection" :key="index">
                        <td>{{dbTermData.term_product}}</td>
                        <td>{{dbTermData.term_tier}}</td>
                        <td v-if="showCommission">{{dbTermData.term_commission_structure}}</td>
                        <td v-if="showOtherColumns">{{dbTermData.term_rate}}</td>
                        <td>{{dbTermData.term_apr}}%</td>
                        <td>{{dbTermData.term_frequency}}</td>
                        <td v-if="showCommission">{{dbTermData.term_fixed_formula}}</td>
                        <td>{{dbTermData.term_advance_percentage}}</td>
                        <td v-if="showOtherColumns">{{dbTermData.term_dic_percentage}}</td>
                        <td>{{dbTermData.term_min_advance}}</td>
                        <td>{{dbTermData.term_max_advance}}</td>
                        <td>{{dbTermData.term_min_term}}</td>
                        <td>{{dbTermData.term_max_term}}</td>
                        <td v-if="showCommission">{{dbTermData.term_max_commission}}</td>
                        <td v-if="showCommission">{{dbTermData.term_commission_cap}}</td>
                        <td v-if="showCommission"><strong>{{dbTermData.term_sup_comission_percentage}}</strong> - {{dbTermData.term_sup_comission_percentage * 100}}%</td>
                        <td v-if="showOtherColumns">{{dbTermData.term_max_insurance_group}}</td>
                        <td>{{dbTermData.term_max_ltv}}</td>
                        <td>{{dbTermData.term_max_mileage}}</td>
                        <td>{{dbTermData.term_option_fee}}</td>
                        <td>{{dbTermData.term_documentation_fee}}</td>
                        <td v-if="showOtherColumns">{{dbTermData.term_device_required}}</td>
                        <td v-if="showOtherColumns">{{dbTermData.gfv_percentage}}</td>
                        <td>{{dbTermData.term_vaea}}</td>
                        <td>{{dbTermData.term_vasa}}</td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="30">
                            No Terms have been created
                        </td>
                    </tr>
                </tbody>
            </AppTable>
       </div>

    </div>

</template>