
import Vue from 'vue';

import MixinConstant from "@coreMixin/store/Constant.mixin";
import UserMixin from "@coreMixin/store/User/User.mixin";

import AppAddressEntity from "@coreEntity/master-proposal/proposal/applicant/ApplicantAddress.entity"
import AddressEntity from "@coreEntity/master-proposal/proposal/applicant/address/Address.entity";

import YearMonth from "@coreComponent/master-proposal/id/YearMonth.vue";

export default Vue.extend({

    components: { YearMonth },

    mixins: [  MixinConstant, UserMixin ],

    props: {
        entity: {
            type: AppAddressEntity
        },

        disabled: {
            type: Boolean
        }
    },

    computed: {

        activeSupplierGroup () {
            return this.storeUserGetActiveSupplierGroup();
        },

        activeRegion () {
            return process.env.VUE_APP_REGION && process.env.VUE_APP_REGION === 'UK' ? 'UK': "LMO";
        },

        addressEntity (): AddressEntity {
            return this.entity.getAddressEntity();
        },

        getCountryItems () {
            return this.storeConstantGetListWithEmpty("country");
        },

        getAddressTypeItems () {
            return this.storeConstantGetListWithEmpty("address_type");
        },

        getResidenceTypeItems () {
            return this.storeConstantGetListWithEmpty("address_residence_type");
        },

        formErrors () {
            const obj = {
                "address_flat_number": [],
                "applicant_address_residence_type_id": [],
                "applicant_address_month_at": [],
                "applicant_address_start_date": [],
                "address_country_id": [],
                "address_line_1": [],
                "address_town": [],
                "address_postcode": []
            };

            if(!this.addressEntity.flatNumber && !this.addressEntity.houseName && !this.addressEntity.houseNum) {
                obj.address_flat_number.push("Address requires a flat number or house name or house number");
            }

            if(!this.entity.monthAt) {
                obj.applicant_address_month_at.push("Address requires the months spent there.");
            }

            if(!this.entity.startDate) {
                obj.applicant_address_start_date.push("Start date required");
            }

            if(!this.entity.residenceTypeID) {
                obj.applicant_address_residence_type_id.push("Address requires a resident type");
            }

            if(!this.addressEntity.countryID) {
                obj.address_country_id.push("Address requires a country");
            }

            if(!this.addressEntity.line1) {
                obj.address_line_1.push("Address requires a street");
            }

            if(!this.addressEntity.town) {
                obj.address_town.push("Address requires a town");
            }

            if(!this.addressEntity.postcode) {
                obj.address_postcode.push("Address requires " + ((this.activeRegion === 'UK') ? 'a postcode' : 'an eircode'));
            }

            return obj;
        }

    },

    methods: {

        getFormErrors (text) {
            return this.formErrors[text] ? this.formErrors[text]: [];
        },

        updateMonthAt (val) {
            this.entity.monthAt = val;
        }

    }

});
