

export default {

    props: {

        value: {
            type: [String, Number]
        },

        label: {
            type: String,
            default: "Time At"
        },

        disabled: {
            type: Boolean
        },

        errors: {
            type: Array,
            default () {
                return [];
            }
        }

    },

    computed: {

        year: {

            get () {
                const raw: number = this.value / 12;

                //11 months for example
                if(raw < 1) {
                    return 0;
                }

                return Math.floor(raw);
            },

            set (val) {
                this.emitUpdate(val, this.month);
            }

        },

        month: {

            get () {
                const raw: number = this.value / 12;
                const decimals = raw - Math.floor(raw);

                return Math.round(12 * decimals);

            },

            set (val) {
                this.emitUpdate(this.year, val);
            }

        },

    },

    data () {
        return {
            totalMonths: 0
        }
    },

    mounted () {
        this.calcTotalMonths(this.year, this.month);
    },

    methods: {

        emitUpdate (year: number, month: number) {
            this.calcTotalMonths(year, month);
            this.$emit("input", this.totalMonths);
        },

        calcTotalMonths (year: number, month: number) {
            this.totalMonths = (year * 12) + month;
        }


    }

};
