









import Vue from 'vue';

import EntityMpChecklist from "@coreEntity/checklist/item/Item.collection";

export default Vue.extend({

    props: {

        itemCollection: {
            type: EntityMpChecklist
        }

    },

    computed: {

        getPercentileCompleted () {
            const   totalCount = this.itemCollection.getItemCount(),
                    checkedCount = this.itemCollection.getCheckedItems().length;

            return (checkedCount / totalCount) * 100;
        }

    }


});
