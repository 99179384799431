
import Entity from "npm-marsh-core/src/entity/Entity";

import { DealerFieldEnum, makeDealerFieldEnum } from "./lib/DealerField.enum";

export class DealerFieldEntity extends Entity
{

    public type: string|null = null;
    public title: string|null = null;
    public value: any = null;

    protected mapDbProperties = {
        'field_type': {
            getter: 'dbEnumToValue',
            setter: 'dbValueToEnum'
        },
        'value': 'value'
    };

    public getType()
    {
        return this.type;
    }

    public getTitle()
    {
        return this.title;
    }

    public getValue()
    {
        return this.value;
    }

    public setType(enumVal: DealerFieldEnum)
    {
        this.type = enumVal;
    }

    public setValue(val: any)
    {
        this.value = val;   
    }

    public dbValueToEnum(val: string)
    {
        const type = makeDealerFieldEnum(val);

        this.setType(type);
    }

    public dbEnumToValue()
    {                   
        return this.getType(); 
    }

}