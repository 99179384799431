//external components
import Toasted from 'vue-toasted';
import Vue2Filters from 'vue2-filters';
import MarshCoreInstall from "npm-marsh-core/src/vue/index";

//ui components
import MpProposalStatusLabel from "./components/master-proposal/proposal/Status/Status.vue";
import MpProposalSubStatusLabel from "./components/master-proposal/proposal/Status/SubStatus.vue";
import SupplierTypeID from "./components/supplier/TypeID.vue";
import FunderID from "./components/supplier/FunderID.vue";
import BrokerID from "./components/supplier/BrokerID.vue";
import DealerID from "./components/supplier/DealerID.vue";

//directives
import ValueDisplayDr from "./directives/ui/text/ValueDisplay.directive";

export default function (Vue: any, opts: any) {

    Vue.config.productionTip = false;

    //external plugins
    Vue.use(Toasted, {
        position: "bottom-right", duration: 5000, keepOnHover: true
    });

    Vue.use(Vue2Filters, {
        currency: {
            symbol: process.env.VUE_APP_CURRENCY ? process.env.VUE_APP_CURRENCY: "£",
            thousandsSeparator: ",",
            fractionCount: 2,
            fractionSeparator: ".",
            symbolPosition: "front",
            symbolSpacing: false
        }
    });

    //marsh core install
    Vue.use(MarshCoreInstall, opts);

    Vue.component("MpProposalStatusLabel", MpProposalStatusLabel);
    Vue.component("MpProposalSubStatusLabel", MpProposalSubStatusLabel);

    Vue.component("SupplierFunderID", FunderID);
    Vue.component("SupplierBrokerID", BrokerID);
    Vue.component("SupplierDealerID", DealerID);
    Vue.component("SupplierTypeID", SupplierTypeID);

    //directives
    Vue.filter("valueDisplay", ValueDisplayDr);
}
