
import Vue from 'vue';

import MixinEvent from "@coreMixin/store/MasterProposal/Mp.mixin";

export default Vue.extend({

    mixins: [MixinEvent],

    computed: {

        filterMpId:
        {
            get () {
                return this.mixinEventGetRequestFilters().proposal_master_id;
            },
            set (value) {
                this.mixinEventUpdateFilter("proposal_master_id", value);
            }
        },

        filterSeen:
        {
            get () {
                return this.mixinEventGetRequestFilters().event_seen;
            },
            set (value) {
                this.mixinEventUpdateFilter("event_seen", value);
            }
        },

        filterDateFrom:
        {
            get () {
                return this.mixinEventGetRequestFilters().proposal_master_event_created_at;
            },
            set (value) {
                this.mixinEventUpdateFilter("proposal_master_event_created_at", value);
            }
        },

    },

    methods: {

        emitRefresh ()
        {
            this.$emit("refresh");
        },

        getSeenVals ()
        {
            return [
                { val: null, text: "All" },
                { val: 0, text: "Not Seen" },
                { val: 1, text: "Seen" }
            ];
        }

    }

});
