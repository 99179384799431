
import EntityCollection from "npm-marsh-core/src/entity/EntityCollection";

import AppAddressEntity from "./ApplicantAddress.entity"

export default class AppAddressCollection extends EntityCollection {

    public getNewItem (): AppAddressEntity {
        return new AppAddressEntity();
    }

    public addNewItem (entity: AppAddressEntity|null = null): AppAddressEntity {
        const en = entity ? entity: new AppAddressEntity(null);

        this.addItem(en);

        return en;
    }

    /**
     * Find an address
     * @param id
     */
    public findID (id: number): AppAddressEntity|null {
        const items: AppAddressEntity[] = this.getItems();

        if(items.length === 0) {
            return null;
        }

        return items.find((item: AppAddressEntity) => {
            return item.id === id;
        });
    }

}