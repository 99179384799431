
import Vue from 'vue';

//en
import MasterProposalEntity from "@coreEntity/master-proposal/MasterProposal.entity";
import CollectionMpVehicleEntity from "@coreEntity/master-proposal/vehicle/Vehicle.collection";

//serv
import MasterProposalService from "@coreService/master-proposal/Index.service";

//cmp
import MpVehicleTable from "@coreComponent/master-proposal/id/vehicle/list/Table.vue";

export default Vue.extend({

    components: {
        MpVehicleTable
    },

    props: {

        mpEntity: {
            type: MasterProposalEntity
        },

        disabled: {
            type: Boolean,
            default: false
        }

    },

    mounted () {
        this.mpService = new MasterProposalService(this);

        this.init();
    },

    data () {
        return {
            collectionMpEntity: new CollectionMpVehicleEntity(),
            modal: {
                newVehicle: false
            },
            http: {
                errors: []
            }
        };
    },

    methods: {

        init () {
            this.setVehicleCollection();
        },

        async setVehicleCollection () {
            const httpRes = await this.mpService.getVehicles(this.mpEntity.getID());

            if(httpRes.hasErrors()) {
                this.http.errors = httpRes.getErrors();
                return;
            }

            const   httpData = httpRes.getData(),
                    collectionMpVehicle = new CollectionMpVehicleEntity();

            httpData.forEach(sqlMpVehicle => {
                const entityMpVehicle = collectionMpVehicle.getNewItem();

                entityMpVehicle.mapApplyDatabase(sqlMpVehicle);
                entityMpVehicle.getEntityVehicle().mapApplyDatabase(sqlMpVehicle);
                entityMpVehicle.getEntityUser().mapApplyDatabase(sqlMpVehicle);

                collectionMpVehicle.addItem(entityMpVehicle);
            });

            this.collectionMpEntity = collectionMpVehicle;
        },

        newVehicleCreated () {
            this.modal.newVehicle = false;
            this.setVehicleCollection();

            this.$emit("newVehicle");
        },

        refresh () {
            this.setVehicleCollection();
        }

    }

});
