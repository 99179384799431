


























import Vue from 'vue';

import ProposalApplicantCollection from "@coreEntity/master-proposal/proposal/Applicant.collection";
import ProposalApplicant from '@coreEntity/master-proposal/proposal/ProposalApplicant.entity';

import ProposalApplicantForm from "@coreComponent/master-proposal/proposal/applicant/Form.vue";

export default Vue.extend({

    components: {
        ProposalApplicantForm
    },

    props: {

        proposalApplicantCollection: {
            type: ProposalApplicantCollection
        },

        disabled: {
            type: Boolean,
            default: false
        }

    },

    computed: {

        canAddApplicant () {
            return this.getApplicantCount() >= 2 || this.disabled === true ? false: true;
        },

        canDeleteApplicant () {
            return this.getApplicantCount() <= 1 || this.disabled === true ? false: true;
        }

    },

    data () {
        return {
            rules: {
                maxApplicants: 2
            }
        };
    },

    methods: {

        addNewApplicant () {
            const proposalApplicant = new ProposalApplicant();

            proposalApplicant.getApplicant().getAddressCollection().addNewItem();
            proposalApplicant.getApplicant().getEmploymentCollection().addNewItem();

            this.proposalApplicantCollection.addItem(proposalApplicant);
        },

        deleteApplicant (appIndex) {
            this.proposalApplicantCollection.deleteItemIndex(appIndex);
        },

        getApplicantCount () {
            return this.proposalApplicantCollection.getItemCount();
        },

        cloneAddress (applicantIndex, addressIndex) {
            let parentAddressItem = this.proposalApplicantCollection.getItem(0).getApplicant().getAddressCollection().getItem(addressIndex);
            if (!parentAddressItem) {
                return;
            }

            this.proposalApplicantCollection.getItem(applicantIndex).getApplicant().getAddressCollection().getItem(addressIndex).mapApplyDatabase(
                parentAddressItem.mapDatabaseToObject()
            );

        },

        getTabText (proposalAppEntity)
        {
            const name = proposalAppEntity.getName();

            if(!name.trim()) {
                return "New Applicant";
            }

            return name;
        }

    }

});
