
import Vue from 'vue';

import MixinUser from "@coreMixin/store/User/User.mixin";
import { AdminUserService } from "@coreService/admin/user/User.service";

import PersonalForm from "./component/PersonalForm.vue";
import SupplierList from "./component/SuppliersList.vue";

export default Vue.extend({

    components: {
        PersonalForm, SupplierList
    },

    mixins: [ MixinUser ],

    props: {

        userID: {
            type: [String, Number]
        }

    },

    data () {
        return {
            model: {
                userEntity: null,
                userSuppliers: []
            },
            httpErrors: []
        };
    },

    mounted ()  {
        this.getID();
    },

    methods: {

        async getID() 
        {
            this.httpErrors = [];

            const   serv = new AdminUserService(this),
                    httpRes = await serv.idGet(this.userID),
                    data = httpRes.getData();

            if(httpRes.hasErrors()) {
                this.$toasted.error("Failed to user data");
                this.httpErrors = httpRes.getErrors();
                return;
            }

            this.model.userEntity = data.user_entity;
            this.model.userSuppliers = data.user_suppliers;
        },

        async httpUpdateUser()
        {
            this.httpErrors = [];

            const   serv = new AdminUserService(this),
                httpRes = await serv.idUpdate(this.userID, this.model.userEntity);

            if(httpRes.hasErrors()) {
                this.httpErrors = httpRes.getErrors();
                this.$toasted.error("failed to update user, see errors");
                return;
            }

            this.$toasted.success("User updated, reloading data.");
            this.getID();
        },

        async httpResetUser()
        {
            this.httpErrors = [];

            const   serv = new AdminUserService(this),
                    httpRes = await serv.idResetPassword(this.userID);

            if(httpRes.hasErrors()) {
                this.httpErrors = httpRes.getErrors();
                this.$toasted.error("failed to reset user, see errors");
                return;
            }

            this.$toasted.success("User account reset. An email has been sent");
        },

        async httpReset2Fa()
        {
            this.httpErrors = [];

            const   serv = new AdminUserService(this),
                    httpRes = await serv.idReset2Fa(this.userID);

            if(httpRes.hasErrors()) {
                this.httpErrors = httpRes.getErrors();
                this.$toasted.error("failed to reset user, see errors");
                return;
            }

            this.$toasted.success("User account reset. An email has been sent");
        },

        async httpDeleteUser()
        {
            const   serv = new AdminUserService(this),
                    httpRes = await serv.idDelete(this.userID);

             if(httpRes.hasErrors()) {
                this.httpErrors = httpRes.getErrors();
                this.$toasted.error("failed to reset user, see errors");
                return;
            }

            this.$toasted.success("User account deleted");
            this.$router.push({name: 'user_list'});
        },

        httpAddSupplier(type: string, id: number) 
        {
            this.httpToggleSupplier(false, type, id);
        },

        httpRemoveSupplier(type: string, id: number) 
        {
            this.httpToggleSupplier(true, type, id);
        },

        async httpToggleSupplier(remove: boolean, type: string, id: number) 
        {
            const   serv = new AdminUserService(this),
                    httpRes = await serv.idToggleSupplier(this.userID, remove, type, id);

        }

    }

});
