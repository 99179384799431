
import Entity from "npm-marsh-core/src/entity/Entity";

export default class EmploymentEntity extends Entity {

    public id: number|null = null;
    public name: string|null  = "";
    public buildingName: string|null  = "";
    public buildingNum: string|null  = "";
    public line1: string|null  = "";
    public town: string|null  = "";
    public postcode: string|null  = "";
    public county: string|null  = "";
    public createdAt: string|null = null;

    protected mapDbProperties = {
        "employment_id": "id",
        "employment_name": "name",
        "employment_building_num": "buildingNum",
        "employment_building_name": "buildingName",
        "employment_line_1": "line1",
        "employment_postcode": "postcode",
        "employment_town": "town",
        "employment_county": "county",
        "employment_created_at": "createdAt"
    };
}