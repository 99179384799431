















import Vue from 'vue';

import ListItem from "./ListItem.vue";

export default Vue.extend({

    components: {
        ListItem
    },

    props: {

        items: {
            type: Array,
            default () { return []; }
        },

        compact: {
            type: Boolean,
            default: false
        },

        readOnly: {
            type: Boolean,
            default: false
        }

    },

    methods: {

        /**
         *
         * Events
         *
         */

        evChecklistCompleteItem (itemID: Number) {
            this.$emit("evChecklistCompleteItem", itemID);

        },

        evChecklistRemoveItem (itemID: Number) {
            this.$emit("evChecklistRemoveItem", itemID);
        },

         evChecklistItemAddComment (itemID: Number, comment: String) {
            this.$emit("evChecklistItemAddComment", itemID, comment);
        },

    }

});
