enum DealerFieldEnum {

    VEHICLE_DETAILS = 'vehicle_details',
    VEHICLE_MILEAGE = 'vehicle_mileage',
    QUOTE_CASHPRICE = 'quote_cash_price',
    QUOTE_ADVANCE = 'quote_advance',
    QUOTE_PARTEX_VEHICLE = 'quote_partex_vehicle',
    QUOTE_SETTLEMENT = 'quote_settlement',
    QUOTE_TERM = 'quote_term',
    QUOTE_DEPOSIT = 'quote_deposit'

}

function makeDealerFieldEnum(val: string): DealerFieldEnum | null 
{
    switch (val) {
        case DealerFieldEnum.VEHICLE_DETAILS:
            return DealerFieldEnum.VEHICLE_DETAILS;
        case DealerFieldEnum.VEHICLE_MILEAGE:
            return DealerFieldEnum.VEHICLE_MILEAGE;
        case DealerFieldEnum.QUOTE_CASHPRICE:
            return DealerFieldEnum.QUOTE_CASHPRICE;
        case DealerFieldEnum.QUOTE_ADVANCE:
            return DealerFieldEnum.QUOTE_ADVANCE;
        case DealerFieldEnum.QUOTE_PARTEX_VEHICLE:
            return DealerFieldEnum.QUOTE_PARTEX_VEHICLE;
        case DealerFieldEnum.QUOTE_SETTLEMENT:
            return DealerFieldEnum.QUOTE_SETTLEMENT;
        case DealerFieldEnum.QUOTE_TERM:
            return DealerFieldEnum.QUOTE_TERM;
        case DealerFieldEnum.QUOTE_DEPOSIT:
            return DealerFieldEnum.QUOTE_DEPOSIT;
    }

    return null;
}

export {
    DealerFieldEnum,
    makeDealerFieldEnum
}