
import Service from "../../Service";


export default class MasterProposalService extends Service
{

    protected serviceName = "Master Proposal Document Service";

    /**
     *
     * Create a new document
     *
     */
    createDocument (masterProposalID, formData)
    {
        return this.post(`/mp/${masterProposalID}/upload`, formData);
    }


}