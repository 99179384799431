
import Vue from 'vue';

export default Vue.extend({

    data () {
        return {
            model: {
                code: ""
            }
        };
    },

    methods: {

        attemptLogin () {

        }

    }

});
