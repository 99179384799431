
import EntityCollection from "npm-marsh-core/src/entity/EntityCollection";

import AppEmploymentEntity from "./ApplicantEmployment.entity";

export default class AppEmploymentCollection extends EntityCollection {


    public getNewItem (): AppEmploymentEntity {
        return new AppEmploymentEntity();
    }

    public addNewItem (entity: AppEmploymentEntity|null = null): AppEmploymentEntity {
        let en = entity ? entity: new AppEmploymentEntity();

        this.addItem(en);

        return en;
    }

}