<template>
    <div>
        <v-row>
            <AppFormInput label="Email" required>
                <AppTextField :autocomplete="false" :value="username" disabled />
            </AppFormInput>
        </v-row>

        <v-row>
            <AppFormInput label="Password" required>
                <AppTextField :autocomplete="false" type="password" v-model="form.model.password" />
            </AppFormInput>
        </v-row>

         <v-row>
            <AppFormInput label="2FA Code" required>
                <AppTextField :autocomplete="false" v-model="form.model.code" />
            </AppFormInput>
        </v-row>

        <v-row>
            <AppErrorList :errors="form.errors" />
        </v-row>

        <v-row class="form-button-row">
            <AppButton class="success-button" colour="success" text="Login" @click="attemptLogin" />
        </v-row>
    </div>
</template>
<script>

    import AuthService from "@coreService/user/Auth.service";
    import AuthMixin from "@coreMixin/auth/Auth.mixin.js";

    export default {

        mixins: [ AuthMixin ],

        props: {

            username: {
                type: String
            }

        },

        data () {
            return {
                form: {
                    model: {
                        password: "",
                        code: ""
                    },
                    errors: []
                }
            };
        },

        mounted () {
            this.deleteAuth();
        },

        methods: {

            async attemptLogin () {
                const   authService = new AuthService(this);
                const   httpRes = await authService.auth(this.username, this.form.model.password, this.form.model.code),
                        data = httpRes.getData();

                if(httpRes.hasErrors() === true) {
                    this.form.errors = httpRes.getErrors();
                    this.$toasted.error("Failed to login");
                    return;
                }

                this.setAuthToken(data.account_auth_token);
                this.$router.push({name: 'dashboard'});
            }

        }

    }
</script>