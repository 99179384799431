
import EntityCollection from "npm-marsh-core/src/entity/EntityCollection";

import EntityEsign from "./Esign.entity";

export default class CollectionEsign extends EntityCollection {

    public getNewItem (): EntityEsign {
        return new EntityEsign();
    }

}