








import Vue from 'vue';

import MasterProposalEntity from "@coreEntity/master-proposal/MasterProposal.entity";
import MPProposalTabs from "@coreViews/master-proposal/id/original/proposal/Tabs.vue";

export default Vue.extend({

    components: {
        MPProposalTabs
    },

    props: {

        mpEntity: {
            type: MasterProposalEntity
        },

        disabled: {
            type: Boolean,
            default: false
        }

    }

});
