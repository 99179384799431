
import EntityCollection from "npm-marsh-core/src/entity/EntityCollection";

import EntityQuest from "./Questionnaire.entity";

export default class CollectionQuestionnaire extends EntityCollection {

    public getNewItem (): EntityQuest {
        return new EntityQuest();
    }

}