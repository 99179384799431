<script>

import AuthService from "@coreService/user/Auth.service";
import AuthMixin from "@coreMixin/auth/Auth.mixin.js";

import LoginContainer from "../login/component/LoginContainer.vue";
import TwoFaEmail from "./email/Email.vue";
import TwoFaQrCode from "./qr/QrCode.vue";

export default {

    mixins:[ AuthMixin ],

    components: {
        LoginContainer,
        TwoFaEmail,
        TwoFaQrCode
    },

    computed: {

        passwordErrors() 
        {
            let arr = [];

            if(this.input.password !== this.input.second_password) {
                arr.push("Passwords don't match");
            }

            return arr;
        },

        twoFaComponent()
        {
            if(!this.two_fa.two_fa_type) {
                return null;
            }

            switch (this.two_fa.two_fa_type) {
                case 'QR_CODE':
                    return 'TwoFaQrCode';
                case 'EMAIL':
                    return 'TwoFaEmail';
            }

            return null;
        }

    },

    data() {
        return {
            hideMiscFields: false,
            input: {
                email: '',
                token: '',
                password: '',
                second_password: '',
                two_fa_code: ''
            },
            two_fa: {
                already_done: false,
                setup_completed: false,
                two_fa_type: false,
                two_type_output: null
            },
            response: {
                start_errors: [],
                complete_errors: [],
                two_fa_url: ''
            }
        }
    },

    created()
    {   
        this.input.email = this.$route.query.email;
        this.input.token = this.$route.query.token;

        if(!this.input.email) {
            this.$router.push({name: 'login'});
        }
    },

    mounted()
    {
        if(this.input.email && this.input.token){
            this.hideMiscFields = true;
            this.startAccountSetup();
        }
    },

    methods: {

        async startAccountSetup() 
        {
            this.response.start_errors = [];
            this.response.complete_errors = [];

            const serv = new AuthService(this);
            const httpRes = await serv.startAccountSetup(this.input.email, this.input.token);
                
            //check if we have failed.
            if(httpRes.hasErrors()) {
                this.response.start_errors = httpRes.getErrors();
                return;
            }

            const data = httpRes.getData();
            const twoFaData = data.two_fa_payload;
            
            //we have a auth token
            if(twoFaData.two_fa_already_done) {
                this.$toasted.info('Your account already has 2fa setup.')
                this.$router.push({name: 'login'});
                return;
            }

            //we need to 2fa
            this.two_fa.already_done = twoFaData.two_fa_already_done;
            this.two_fa.setup_completed = twoFaData.two_fa_done;
            this.two_fa.two_fa_type = twoFaData['2fa_type'];
            this.two_fa.two_type_output = twoFaData.output;
        },

        async completeAccountSetup() 
        {
            this.response.complete_errors = [];

            const serv = new AuthService(this);
            const httpRes = await serv.completeAccountSetup(
                this.input.email, 
                this.input.token,
                this.input.password,
                this.input.two_fa_code
            );

            //check if we have failed.
            if(httpRes.hasErrors()) {
                this.response.complete_errors = httpRes.getErrors();
                return;
            }

            const data = httpRes.getData();
            const authToken = data.auth_token;

            if(authToken) {
                this.setAuthToken(authToken);
                this.$router.push({name: 'dashboard'});
            }
        },

        twoFaCodeEntered(data)
        {
            this.input.two_fa_code = data.code;

            console.log(data);

            this.completeAccountSetup();
        }

    }

}

</script>
<template>

    <LoginContainer :showResetPassword="false">
        <div>
           <div>
                <v-row>
                    <v-col>
                        <h4>Lets setup your account</h4>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <AppFormInput label="Email" required>
                            <AppTextField :autocomplete="false" v-model.trim="input.email" />
                        </AppFormInput>
                    </v-col>
                </v-row>

                <v-row v-show="hideMiscFields === false">
                    <v-col>
                        <AppFormInput label="Token" required>
                            <AppTextField :autocomplete="false" v-model.trim="input.token" />
                        </AppFormInput>
                        <small>You should have an email with a secret token</small>
                    </v-col>
                </v-row>

                <v-row v-if="response.start_errors.length">
                    <v-col>
                        <AppErrorList :errors="response.start_errors" />
                    </v-col>
                </v-row>

                <v-row v-show="hideMiscFields === false" class="mb-2">
                    <v-col>
                        <div class="form-button-row">
                            <AppButton :disabled="!input.token" text="Next" @click="startAccountSetup" />
                        </div>
                    </v-col>
                </v-row>
           </div>

           <v-row v-if="twoFaComponent" class="mt-2">
                <v-col cols="12">
                    <h4 class="mb-4">1. Enter your password</h4>
                    <AppFormInput label="New Password" required>
                        <AppTextField :autocomplete="false" type="password" v-model.trim="input.password" />
                    </AppFormInput>

                    <AppFormInput label="New Password Again" required>
                        <AppTextField :autocomplete="false" type="password" v-model.trim="input.second_password" />
                    </AppFormInput>

                    <AppErrorList :errors="passwordErrors" />
                </v-col>
                <v-col cols="12">
                    <h4 class="mb-4">2. Complete your two factor auth process.</h4>
                    <component :is="twoFaComponent"
                        :twoFaData="two_fa" @codeEntered="twoFaCodeEntered"
                    />
                </v-col>
            </v-row>    

            <div v-if="response.complete_errors.length">
                <AppErrorList :errors="response.complete_errors" />
            </div>
    

            <!-- password form and the 2FA QR -->
            <!-- <template v-if="response.two_fa_url">
                <v-row class="mt-2">
                    <v-col cols="12">
                        <h4 class="mb-4">1. Enter your password</h4>
                        <AppFormInput label="New Password" required>
                            <AppTextField :autocomplete="false" type="password" v-model.trim="input.password" />
                        </AppFormInput>

                        <AppFormInput label="New Password Again" required>
                            <AppTextField :autocomplete="false" type="password" v-model.trim="input.second_password" />
                        </AppFormInput>

                        <AppErrorList :errors="passwordErrors" />
                    </v-col>
                    <v-col cols="12">
                        <h4 class="mb-4">2. Complete your two factor auth process.</h4>
                        <div>
                            <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_GB&gl=US">Phone App: Google Authenticator</a>
                        </div>
                        <iframe 
                            ref="iframe" style="height: 200px; width: 200px;" frameborder="0" scrolling="no" 
                            :src="response.two_fa_url"
                        />

                        <AppFormInput label="Test two fa code" required>
                            <AppTextField :autocomplete="false" v-model.trim="input.two_fa_code" />
                        </AppFormInput>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <div class="form-button-row">
                            <AppButton text="complete" :disabled="passwordErrors.length || !input.two_fa_code" @click="completeAccountSetup" />
                        </div>
                    </v-col>
                </v-row>
            </template> -->
        </div>
    </LoginContainer>

</template>
<style lang="scss">

.app-login-container {

    .login-form-container {
        max-width: 800px;
    }

}

</style>