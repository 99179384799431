








import Vue from 'vue';

import MasterProposalEntity from '@coreEntity/master-proposal/MasterProposal.entity';

import MasterProposalQuote from "@coreComponent/master-proposal/id/quote/Index.vue";

export default Vue.extend({
    components: {
        MasterProposalQuote
    },

    props: {

        mpEntity: {
            type: MasterProposalEntity
        },

        disabled: {
            type: Boolean,
            default: false
        }

    }

});
