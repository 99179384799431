










import Vue from 'vue';

import MpList from "../master-proposal/list/Index.vue";

export default Vue.extend({

    components: {
        MpList
    },

    mounted () {
        this.$refs["mp-search"].search();
    }

});
