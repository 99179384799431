<template>

    <LoginContainer>

        <!-- init login check -->
        <LoginStatus v-show="step === 1" @canLogin="userCanAttemptLogin" @accountNeedsToSetup="redirectToAccountSetup" />

        <!-- can login -->
        <LoginForm :username="login.username" v-show="step === 2" />

    </LoginContainer>

</template>
<script>
    import AuthMixin from "@coreMixin/auth/Auth.mixin.js";

    import LoginContainer from "./component/LoginContainer.vue";
    import LoginStatus from "./component/LoginStatus.vue";
    import LoginForm from "./component/LoginForm.vue";
    import TwoFactorForm from "./component/TwoFactorForm.vue";
    import SetupFactorAuth from "./component/SetupFactorAuth.vue";

    export default {

        components: {
            LoginContainer, LoginStatus, LoginForm, TwoFactorForm, SetupFactorAuth
        },

        mixins: [ AuthMixin ],

        data () {
            return {
               step: 1,
               login: {
                   username: ""
               },
               setup: {
                   twoFactorUrl: ""
               }
            };
        },

        mounted () {
            this.deleteAuth();
        },

        methods: {

            userCanAttemptLogin (data) 
            {
                this.login.username = data.username;
                this.step = 2;
            },

            redirectToAccountSetup (data) 
            {
                this.$router.push({name: 'user_account_setup', query: {email: data.username }})
            }

        }
    }

</script>
<style lang="scss">

    .form-input {
        width: 100%;
    }

</style>