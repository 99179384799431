



























import Vue from 'vue';

import MixinQuote from "@coreMixin/store/ApiQuote.mixin";

export default Vue.extend({

    mixins: [ MixinQuote ],

    computed: {

        outFunderCollection () {
            return this.storeSuppliersGetListWithEmpty(4);
        },

        funderCollection () {
            return this.storeSuppliersGetListWithEmpty(1);
        },

        brokerCollection () {
            return this.storeSuppliersGetListWithEmpty(2);
        },

        dealerCollection () {
            return this.storeSuppliersGetListWithEmpty(3);
        },

    },


    data () {
        return {
            model: {
                outFunderID: null,
                funderID: null,
                brokerID: null,
                dealerID: null
            }
        };
    },

});
