
    import Vue from "vue";

    import StoreMap from "@/core/store/store.map";

    export default Vue.extend({

        methods: {

            getItemTypeText (typeID: number, defaultText: string = "N/A"): string {
                const itemObj = this.storeComponentGetChecklistItemTypes().find(ele => {
                    return ele.checklist_item_type_id === typeID;
                });

                return itemObj ? itemObj.checklist_item_type_text: defaultText;
            },

            getListTypeText (typeID: number, defaultText: string = "N/A"): string {
                const itemObj = this.storeComponentGetChecklistListTypes().find(ele => {
                    return ele.checklist_item_type_id === typeID;
                });

                return itemObj ? itemObj.checklist_type_text: defaultText;
            },

            getRemovableList (valAsBools: boolean = true) {
                return [
                    { text: "No", value: valAsBools ? false: 0 },
                    { text: "Yes", value: valAsBools ? true: 1 }
                ];
            },

            getSupplierVisibleList (valAsBools: boolean = true) {
                return [
                    { text: "No", value: valAsBools ? false: 0 },
                    { text: "Yes", value: valAsBools ? true: 1 }
                ];
            },

            getEditableList (valAsBools: boolean = true) {
                return [
                    { text: "No", value: valAsBools ? false: 0 },
                    { text: "Yes", value: valAsBools ? true: 1 }
                ];
            },

            /**
             *
             * Getters
             *
             */

            storeComponentGetChecklistListTypesEmpty (list: string) {
                return [].concat([{checklist_type_id: null, checklist_type_text: "Please select"}], this.storeComponentGetChecklistListTypes());
            },

            storeComponentGetChecklistItemTypesEmpty (list: string) {
                return [].concat([{checklist_item_type_id: null, checklist_item_type_text: "Please select"}], this.storeComponentGetChecklistItemTypes());
            },

            storeComponentGetChecklistListTypes () {
                return this.$store.getters[`${StoreMap.app_checklist_store}checklistStoreGetListTypes`];
            },

            storeComponentGetChecklistItemTypes () {
                return this.$store.getters[`${StoreMap.app_checklist_store}checklistStoreGetItemTypes`];
            },

            /**
             *
             * Commits
             *
             */

            storeChecklistSetTypes (listTypes, itemTypes) {
                this.$store.commit(`${StoreMap.app_checklist_store}checklistStoreSetTypeCollection`, listTypes);
                this.$store.commit(`${StoreMap.app_checklist_store}checklistStoreSetItemTypeCollection`, itemTypes);
            }

        }

    });