
import EntityCollection from "npm-marsh-core/src/entity/EntityCollection";

import EntitySignatory from "./Signatory.entity";

export default class CollectionSignatory extends EntityCollection {

    public getNewItem (): EntitySignatory {
        return new EntitySignatory();
    }

}