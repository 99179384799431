import EntityCollection from "npm-marsh-core/src/entity/EntityCollection";

import EntityChecklistGroupItem from "./GroupItem.entity";

import CollectionItemCollection from "../../item/Item.collection";

export default class GroupItemCollection extends EntityCollection {

    public getNewItem (): EntityChecklistGroupItem {
        return new EntityChecklistGroupItem();
    }

    convertToItemCollection (): CollectionItemCollection {
        const collection = new CollectionItemCollection();

        this.getItems().forEach((entity:EntityChecklistGroupItem) => {
            collection.addItem(entity.convertToChecklistItem());
        });

        return collection;
    }



}