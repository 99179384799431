import Entity from "npm-marsh-core/src/entity/Entity";

import ApplicantEntity from "./applicant/Applicant.entity";
import AppAddressEntity from "./applicant/ApplicantAddress.entity";

export default class ProposalApplicant extends Entity {

    public id: number|null = null;
    public proposalID: number|null = null;
    public applicantID: number|null = null;
    public appAddressActiveID: number|null = null;
    public appEmploymentActiveID: number|null = null;
    public createdAt: string|null = null;

    public applicantEntity: ApplicantEntity|null;

    protected mapDbProperties = {
        "proposal_applicant_id": "id",
        "proposal_applicant_proposal_id": "proposalID",
        "proposal_applicant_applicant_id": "applicantID",
        "proposal_applicant_applicant_active_address_id": "appAddressActiveID",
        "proposal_applicant_applicant_active_employment_id": "appEmploymentActiveID",
        "proposal_applicant_created_at": "createdAt",
        "proposal_applicant_entity": {
            entity_getter: "applicantEntity",
            entity_setter: "applicantEntity"
        }
    };

    constructor () {
        super();

        this.applicantEntity = new ApplicantEntity();
    }

    public getApplicant (): ApplicantEntity {
        return this.applicantEntity;
    }

    public getName () {
        return this.applicantEntity.getName();
    }

    public getActiveAddress (): AppAddressEntity|null  {
        const activeAddressID = this.appAddressActiveID;

        if(!activeAddressID) {
            return null;
        }

        return this.getApplicant().getAddressCollection().findID(activeAddressID);
    }

    public getActiveAddressLine (): string {
        const activeAddress = this.getActiveAddress();

        return activeAddress ? activeAddress.getAddressEntity().getSummaryText(): "No active address";
    }

}