<script>

export default {

    props: {

        twoFaData: {

        }

    },

    data() {
        return {
            model: {
                two_fa_code: ''
            }
        }
    },

    methods: {

        emitCodeEntered()
        {
            this.$emit('codeEntered', { code: this.model.two_fa_code });
        }

    }

}

</script>
<template>

    <div>
        <p>An email has been sent to your account with an access code.</p>

        <AppFormInput label="Test two fa code" required>
            <AppTextField 
                :autocomplete="false" v-model.trim="model.two_fa_code"
            />
        </AppFormInput>

        <div class="form-button-row">
            <AppButton text="complete" :disabled="!model.two_fa_code" @click="emitCodeEntered" />
        </div>


    </div>

</template>