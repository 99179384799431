<template>

    <AppPill class="component-supplier-dealer">{{dealerName}}</AppPill>

</template>

<script>

    import ApiQuoteMixin from "@coreMixin/store/ApiQuote.mixin";

    export default {

        mixins: [ ApiQuoteMixin ],

        props: {
            id: {
                type: [String, Number],
                default: null
            },

            outFunder: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            dealerObj () {
                return this.storeSuppliersGetDealer(this.id);
            },

            dealerName () {
                return this.dealerObj ? this.dealerObj.dealer_name: "Dealer Not Found";
            }
        }
    };

</script>
<style lang="scss">

    .component-supplier-dealer {

        &.component-app-pill.component-app-pill {
            background-color: var(--v-info-lighten2)
        }

    }

</style>