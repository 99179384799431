import EntityUser from '@/core/entity/user/User.entity';
import MyUserService from "@coreService/my/User.service";

/**
 * AppUserEntity
 * This class wraps the user entity and allows the application to build from it
 *
 */
export default class AppUserEntity extends EntityUser {

    vueInstance: Vue;

    constructor (vue) {
        super();

        this.vueInstance = vue;
    }

    /**
     * Main function to setup the user application
     */
    public applicationSetup () {
        this.setupSettings();
    }


    /**
     *
     * Settings
     *
     */

     //update the user settings to the server
    public async fireSettingsUpdate () {
        let serv = new MyUserService(this.vueInstance);
        return await serv.updateUserSettings(this.getSettingJson());
    }

    public setupSettings () {
        let settingsObj = this.getSettingJson();

        if(settingsObj === null) {
            settingsObj = this.getInitUserSettings();
        }

        if(!settingsObj.sidebar) {
            settingsObj.sidebar = this.getDefaultSidebarConfig();
        }

        if(!settingsObj.theme) {
            settingsObj.theme = this.getDefaultThemeConfig();
        }

        this.userSettings = settingsObj;
    }

    public getSidebarSettings () {
        return this.userSettings.sidebar;
    }

    public getThemeSettings () {
        return this.userSettings.theme;
    }

    public getSidebarConfig () {
        return this.getSettingJson().sidebar;
    }

    public setSettingsSidebar (obj) {
        this.getSettingJson().sidebar = obj;
    }

    public setSettingsTheme (obj) {
        this.getSettingJson().theme = obj;
    }

    //if settings = null, then set the default
    public getInitUserSettings () {
        return {
            sidebar: this.getDefaultSidebarConfig(),
            theme: this.getDefaultThemeConfig()
        };
    }

    public getDefaultSidebarConfig () {
        return { tabs: [] };
    }

    public getDefaultThemeConfig () {
        return {
            id: 1,
            dark: false
        };
    }

}