
import Entity from "npm-marsh-core/src/entity/Entity";

import EmploymentEntity from "./employment/Employment.entity";

export default class ApplicantEmploymentEntity extends Entity {

    public id: number|null = null;
    public employmentID: number|null = null;
    public appID: number|null = null;

    public employmentTypeID: number|null = null;
    public jobName: string|null = "";
    public startDate: string|null = null;
    public monthAt: number|null = null;
    public createdAt: string|null = null;

    public employmentEntity: EmploymentEntity;

    protected mapDbProperties = {
        "applicant_employment_id": "id",
        "applicant_employment_emp_id": "employmentID",
        "applicant_employment_applicant_id": "appID",
        "applicant_employment_employment_type_id": "employmentTypeID",
        "applicant_employment_job": "jobName",
        "applicant_employment_start_date": "startDate",
        "applicant_employment_month_at": "monthAt",
        "employment_created_at": "createdAt",
        "applicant_employment_employment_entity": {
            entity_getter: "employmentEntity",
            entity_setter: "employmentEntity",
        },
    };

    constructor (employmentEntity: EmploymentEntity|null = null) {
        super();

        this.employmentEntity = employmentEntity ? employmentEntity: new EmploymentEntity();
    }

    getEmploymentEntity (): EmploymentEntity {
        return this.employmentEntity;
    }
}