const ConstantStore = {

    namespaced: true,

    state: {
        collection: {}
    },

    mutations: {
        updateConstantStore (state, data) {
            state.collection = data.collection;
        }
    },

    actions: {
        updateStore (context, payload) {
            context.commit("updateStore", payload);
        }
    },

    getters: {

        consStoreGetConstantObject: (state) => (payload) => {
            return state.collection[payload.key];
        },

        consStoreFilterConstantObject: (state) => (payload) => {
            let arr = state.collection[payload.constantKey].filter( data => { return data.id == payload.id } );

            return arr.length ? arr[0]: null;
        },

        consStoreFindByStandardName: (state) => (payload) => {
            let arr = state.collection[payload.constantKey].filter( data => { return data.standardized == payload.standardized } );

            return arr.length ? arr[0]: null;
        }

    }
};

export default ConstantStore;