
import Vue from 'vue';

import MixinEvent from "@coreMixin/store/MasterProposal/Mp.mixin";

import TypeFactory from "./Type/Factory.vue";

export default Vue.extend({

    mixins: [MixinEvent],

    components: {
        TypeFactory
    },

    props: {

        dbData: {

        }

    }

});
