
import Entity from "npm-marsh-core/src/entity/Entity";

import ProposalEntity from "./proposal/Proposal.entity";
import EntityCollectionMpVehicle from "./vehicle/Vehicle.collection";
import EntityMpVehicle from "./vehicle/Vehicle.entity";
import CollectionUploads from "./upload/MpUpload.collection";
import CollectionChecklist from "./checklist/MasterProposalChecklist.collection";
import CollectionNotes from "./note/MpNote.collection";
import CollectionWetSign from "./wetsign/WetSign.collection";
import { DealerFieldsCollection } from "./dealer-fields/DealerFields.collection";

export default class MasterProposalEntity extends Entity {

    public masterProposalID: number|null = null;
    public proposalActiveID: number|null = null;
    public applicationID: number|null = null;
    public outFunderID: number|null = null;
    public funderID: number|null = null;
    public brokerID: number|null = null;
    public dealerID: number|null = null;
    public activeAutoDecisionID: number|null = null;
    public userCreatedID: number|null = null;
    public externalReference: string|null = null;
    public createdAt: string|null = null;
    public deletedAt: string|null = null;

    public proposalEntity: ProposalEntity;
    public vehicleCollection: EntityCollectionMpVehicle;
    public collectionUploads: CollectionUploads;
    public collectionChecklist: CollectionChecklist;
    public collectionNotes: CollectionNotes;
    public collectionWetSign: CollectionWetSign;
    public dealerFieldsCollection: DealerFieldsCollection|null = null;

    protected mapDbProperties = {
        "proposal_master_id": "masterProposalID",
        "proposal_master_proposal_active_id": "proposalActiveID",
        "proposal_master_external_ref": "externalReference",
        "proposal_master_active_ad_id": "activeAutoDecisionID",
        "proposal_master_funder_outbound_id": "outFunderID",
        "proposal_master_funder_id": "funderID",
        "proposal_master_broker_id": "brokerID",
        "proposal_master_dealer_id": "dealerID",
        "proposal_master_created_at": "createdAt",
        "proposal_master_deleted_at": "deletedAt",
        "proposal_master_proposal_entity": {
            entity_getter: "proposalEntity",
            entity_setter: "proposalEntity"
        },
        "proposal_master_vehicle_collection_entity": {
            entity_getter: "vehicleCollection",
            entity_setter: "vehicleCollection"
        },
        "proposal_master_document_collection_entity": {
            entity_getter: "collectionUploads",
            entity_setter: "collectionUploads"
        },
        "proposal_master_checklist_collection_entity": {
            entity_getter: "collectionChecklist",
            entity_setter: "collectionChecklist"
        },
        "proposal_master_note_collection_entity": {
            entity_getter: "collectionNotes",
            entity_setter: "collectionNotes"
        },
        "proposal_master_wetsign_collection_entity": {
            entity_getter: "collectionWetSign",
            entity_setter: "collectionWetSign"
        },
        "proposal_master_dealer_fields_collection": {
            getter: "getDealerFieldsSql",
            setter: "setDealerFieldsSql"
        }
    };

    public constructor (proposalEntity: ProposalEntity|null = null, vehicleCollection: EntityCollectionMpVehicle|null = null) {
        super();

        this.proposalEntity = proposalEntity ? proposalEntity: new ProposalEntity();
        this.vehicleCollection = vehicleCollection ? vehicleCollection: new EntityCollectionMpVehicle();
        this.collectionUploads = new CollectionUploads();
        this.collectionChecklist = new CollectionChecklist();
        this.collectionNotes = new CollectionNotes();
        this.collectionWetSign = new CollectionWetSign();
        this.dealerFieldsCollection = new DealerFieldsCollection();
    }

    /**
     *
     * Methods
     *
     */

    //whether the proposal is edittable
    public canEdit (): boolean {
        const statusID = this.getProposalEntity().getStatusID(),
            deletedAt = this.getDeletedAt();

        //check status
        if(this.getDisabledStatusIDs().indexOf(statusID) !== -1) {
            return false;
        }

        const   today = new Date(),
                deletedDate = new Date(deletedAt);

        //check delete
        if(deletedDate.getTime() < today.getTime()) {
            return false;
        }

        return true;
    }

    public getDisabledStatusIDs ()
    {
        //NTU, Booked
        return [6, 11];
    }

    /**
     *
     * Entity methods
     *
     */

    public getProposalEntity (): ProposalEntity {
        return this.proposalEntity;
    }

    public getVehicleCollection (): EntityCollectionMpVehicle {
        return this.vehicleCollection;
    }

    public getEntityActiveQuote () {
        return this.proposalEntity.getEntityActiveQuote();
    }

    public getCollectionUploads () {
        return this.collectionUploads;
    }

    public getCollectionChecklist () {
        return this.collectionChecklist;
    }

    public getCollectionNotes () {
        return this.collectionNotes;
    }
    
    public getCollectionWetSign() {
        return this.collectionWetSign;
    }

    public setCollectionChecklist (collectionChecklist: CollectionChecklist) {
        this.collectionChecklist = collectionChecklist;
    }

    public addNewMpVehicleEntity (en: EntityMpVehicle) {
        this.getVehicleCollection().addItem(en);
    }

    public getDealerFieldsCollection()
    {
        return this.dealerFieldsCollection;
    }

    /**
     *
     * Setters
     *
     */

    public getID (): number|null {
        return this.masterProposalID;
    }

    /**
     *
     * Getters
     *
     */

    public getBookingFunderID () {
        return this.outFunderID;
    }

    public getFunderID (): number|null {
        return this.funderID;
    }

    public getBrokerID (): number|null {
        return this.brokerID;
    }

    public getDealerID (): number|null {
        return this.dealerID;
    }

    public getActiveProposalID () {
        return this.getProposalEntity().getID();
    }

    public getCreditTier () {
        return this.getProposalEntity().getCreditTier();
    }

    public getProposalStatus () {
        return this.getProposalEntity().getStatusID();
    }

    public getProposalSubStatus () {
        return this.getProposalEntity().getSubStatusID();
    }

    public getExternalRef () {
        return this.externalReference;
    }

    public getDeletedAt (): string {
        return this.deletedAt;
    }

    public getDealerFieldsSql()
    {
        return this.dealerFieldsCollection.mapDatabaseToObject()
    }

    public setDealerFieldsSql(dbValue: any)
    {
        const items = dbValue.items ?? null;        

        if(!items) {
            return;
        }

        if(!Array.isArray(items)) {
            return;
        }

        this.dealerFieldsCollection.buildItemsFromDatabase(items);
    }

}