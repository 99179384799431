import EntityCollection from "npm-marsh-core/src/entity/EntityCollection";

import EntityItem from "./Item.entity";

export default class MasterProposalChecklist extends EntityCollection {

    public getNewItem (): EntityItem {
        return new EntityItem();
    }

    public getItemCount (includeDeleted: boolean = false): number {
        return this.getItems().filter((entity: EntityItem) => {
            if(includeDeleted === false && this.filterDeleted(entity) === true) {
                return false;
            }

            return true;
        }).length;
    }

    public getUncheckedItems (includeDeleted: boolean = false) {
        return this.getItems().filter((entity: EntityItem) => {
            if(this.filterUnchecked(entity) === false) {
                return false;
            }

            if(includeDeleted === false && this.filterDeleted(entity) === true) {
                return false;
            }

            return true;
        });
    }

    public getCheckedItems (includeDeleted: boolean = false) {
        return this.getItems().filter((entity: EntityItem) => {
            if(this.filterChecked(entity) === false) {
                return false;
            }

            if(includeDeleted === false && this.filterDeleted(entity) === true) {
                return false;
            }

            return true;
        });
    }

    public getUncheckedCount () {
        return this.getUncheckedItems().length;
    }

    public removeItemsViaGroupID (groupID: number) {
        const arrItems = this.getItems();

        for (var i = arrItems.length - 1; i >= 0; i--) {

            if(arrItems[i].getGroupID() === groupID) {
                this.deleteItemIndex(i);
            }

        }

    }

    /**
     *
     * Filter methods
     *
     */

    private filterChecked (entity: EntityItem): boolean {
        return entity.getChecked() === 1;
    }

    private filterUnchecked (entity: EntityItem): boolean {
        return entity.getChecked() === 0;
    }

    private filterDeleted (entity: EntityItem): boolean {
        return entity.isDeleted() === true;
    }

    private filterNotDeleted (entity: EntityItem): boolean {
        return entity.isDeleted() === false;
    }

}