

import Vue from 'vue';

import MasterProposalEntity from "@coreEntity/master-proposal/MasterProposal.entity";
import CollectionMpVehicleEntity from "@coreEntity/master-proposal/vehicle/Vehicle.collection";

import MpVehicleIdEdit from "../id/Edit.vue";

export default Vue.extend({

    components: {
        MpVehicleIdEdit
    },

    props: {

        mpEntity: {
            type: MasterProposalEntity
        },

        collectionMpVehicle: {
            type: CollectionMpVehicleEntity
        },

        disabled: {
            type: Boolean,
            default: false
        }

    },

    computed: {

        mpVehicleList () {
            return this.collectionMpVehicle.getItems();
        }

    },

    data () {
        return {
            modal: {
                vehicleEdit: {
                    open: false,
                    entity: null,
                }
            }
        };
    },

    methods: {

        //expand a vehicle to see more info
        viewVehicle (entityMpVehicle) {
            this.modal.vehicleEdit.entity = entityMpVehicle;
            this.modal.vehicleEdit.open = true;
        },

        vehicleDeleted () {
            this.$emit("refresh");
        },

        vehicleUpdated () {
            this.$emit("refresh");
        }

    }

});
