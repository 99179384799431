
import Vue from 'vue';

import MixinConstant from "@coreMixin/store/Constant.mixin";
import MixinUser from "@coreMixin/store/User/User.mixin";

import Advance from "@coreComponent/quote/Advance.vue";

export default Vue.extend({

    components: {
        Advance
    },

    mixins: [ MixinConstant, MixinUser ],

    props: {
        filterObj:
        {
            type: Object
        },

        mpCollection: 
        {
            type: Array
        },

        noneFoundText: 
        {
            type: String,
            default: "No proposals found"
        },

    },

    computed: {

        activeTerms () 
        {
            return this.getActiveSupplierTerms();
        },

        currentOrdering()
        {
            return this.filterObj.orderListBy;
        }

    },

    data () {
        return {
            nowDateTime: null,
            showBookingFunder: true,
            showSuppliers: true
        };
    },

    mounted () {
        this.nowDateTime = this.$moment();
        this.showBookingFunder = parseInt(process.env.VUE_APP_SHOW_LIST_BOOKING_FUNDER) ? true: false;
        this.showSuppliers = parseInt(process.env.VUE_APP_SHOW_LIST_SUPPLIERS) ? true: false;
    },

    methods: {

        pageChangeEvent(pageNumber) 
        {
            this.proposal.currentPage = pageNumber;
            this.$emit("search");
        },

        //val: asc or desc
        toggleListOrdering(val)
        {
            this.filterObj.orderListBy = val;
            this.$emit('orderingChanged', val);
        },

        getQuoteText(dbProposalData) 
        {
            if(!dbProposalData.quote_type_id) {
                return "N/A";
            }

            return this.storeConstantGetListItemID("quote_loan", dbProposalData.quote_type_id).text;
        },

        getVehicleText(dbProposalData) 
        {
            if(dbProposalData.vehicle_reg) {
                return dbProposalData.vehicle_reg;
            }

            if(dbProposalData.vehicle_cap_code) {
                return dbProposalData.vehicle_cap_code;
            }

            if(dbProposalData.vehicle_make) {
                let model = dbProposalData.vehicle_model ? dbProposalData.vehicle_model: "";
                return ` <div>${dbProposalData.vehicle_make} ${model}</div>`;
            }

            return "N/A";
        },

        //check whether a date time is in the past from now
        dateTimeInPast (date)
        {
            let dateTime1 = this.$moment(date);

            return !this.nowDateTime.isBefore(dateTime1);
        }

    }

});
