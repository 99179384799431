
import Vue from 'vue';

import CmpMpChecklist from "@coreComponent/master-proposal/id/checklist/id/Index.vue";

export default Vue.extend({

    components: {
        CmpMpChecklist
    },

    props: {

        mpEntity: {

        },

        dbMpChecklists: {

        },

        optsShowChecked: {
            type: Number,
            default: null
        },

        disabled: {
            type: Boolean,
            default: false
        },

        compact: {
            type: Boolean,
            default: false
        },

        readOnly: {
            type: Boolean,
            default: false
        }

    },

    watch: {
        dbMpChecklists () {
            this.init();
        }
    },

    computed: {

        mpChecklistEntity () {
            return this.dbMpChecklists.find(ele => {
                return ele.id === this.selectedID;
            });
        }

    },

    data () {
        return {
            selectedID: null
        };
    },

    mounted () {
        this.init();
    },

    methods: {
        init () {
            if(this.dbMpChecklists.length) {
                this.selectedID = this.dbMpChecklists[0].id;
            }
        }
    }

});
