
















































//service
import MasterProposalService from "@coreService/master-proposal/Index.service";
import MasterProposalEntity from "@/core/entity/master-proposal/MasterProposal.entity";


//components
import DBEdocIDView from "./Session/ID.vue";

//mixin
import MixinConstant from "@coreMixin/store/Constant.mixin";

export default {

    components: {
        DBEdocIDView
    },


    mixins: [MixinConstant],

    props: {

        dbList: {
            type: Array,
            default: () => {
                return [];
            }
        },

        disabled: {
                type: Boolean,
                default: false
            },
        proposalExpired: {
            type: Boolean,
            default: false
        },
        mpEntity:
        {
            type: MasterProposalEntity
        }
    },

    computed: {

        masterProposalID ()
            {
                return this.mpEntity.getID();
            },
        ESignList() {
            return this.mpEntity.getProposalEntity().getCollectionEsign();
        },

        edocStatusStore() {
            return this.storeConstantGetList("edoc_status");
        }
    },

    data() {
        return {
            modal: {
                open: false,
                esignID: {
                    dbData: null,
                    dbDocuments: [],
                    dbSignatories: [],
                    dbQuestionnaires: [],
                    finalised: false
                }
            }
        };
    },
    created() {
        this.masterProposalService = new MasterProposalService(this);
    },

    mounted() {
        console.log('Esign list checking entity', this.modal.esignID.dbData)
    },
    methods: {
        viewEdocID(esignID) {
            let me = this;

            this.getEsignDetails(esignID).then(res => {
                me.modal.esignID.dbData = res["data"];
                me.modal.esignID.dbDocuments = res["data"].__documents;
                me.modal.esignID.dbSignatories = res["data"].__signatories;
                me.modal.esignID.dbQuestionnaires = res["data"].__questionnaires;
                me.modal.esignID.finalised = res["data"].finalised;

                if (me.modal.esignID) {
                    this.modal.open = true;
                }
            })
                .catch(err => {
                    me.$toasted.error("Failed to get signing process id");
                    console.log(err);
                });
        },


        getQuoteAdvance(quoteId: number) {

            return this.mpEntity.getProposalEntity().getQuoteCollection().getQuoteID(quoteId).getQuoteEntity().getAdvance();


        },
        getVehicleReg(quoteId: number) {

            return this.mpEntity.getProposalEntity().getQuoteCollection().getQuoteID(quoteId).getVehicleEntity().getReg();

        },

        getStatusText(esignStatusID) {
            let edocStatusConstant = this.getEdocStatusConstant(esignStatusID);

            if (edocStatusConstant === null) {
                return "Not Found";
            }

            return edocStatusConstant.text;
        },

        getEdocStatusConstant(esignStatusID) {
            for (let index = 0; index < this.edocStatusStore.length; index++) {
                const element = this.edocStatusStore[index];

                if (element.id == esignStatusID) {
                    return element;
                }
            }

            return null;
        },

        getUserText(dbEdoc) {
            if (!dbEdoc.user_id) {
                return "API";
            }

            return `${dbEdoc.user_first_name} ${dbEdoc.user_last_name}`;
        },

        //http
        getEsignRunIDData(id) {

            const mpService = new MasterProposalService(this);
            return mpService.getEsignDetails(this.masterProposalID, id);
        },
        
        getEsignDetails(esignId) {
            const mpService = new MasterProposalService(this);
            return mpService.getEsignDetails(this.masterProposalID, esignId);

        }

    }


}

