
import Entity from "npm-marsh-core/src/entity/Entity";

export default class EntityQuestionnaire extends Entity {

    public id: number|null = null;
    public signatoryID: number|null = null;
    public questionnaireID: number|null = null;

    public reference: string|null = null;
    public title: string|null = null;
    public options: string|null = null;
    public selected: string|null = null;
    public createdAt: string|null = null;


    protected mapDbProperties = {
        "edoc_questionnaire_id": "id",
        "edoc_questionnaire_signatory_id": "signatoryID",
        "edoc_questionnaire_questionnaire_id": "questionnaireID",
        "edoc_questionnaire_reference": "reference",
        "edoc_questionnaire_title": "title",
        "edoc_questionnaire_options": "options",
        "edoc_questionnaire_selected": "selected",
        "edoc_questionnaire_date_created": "createdAt"
    };

}