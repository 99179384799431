import Vue from "vue";
import Vuetify from "vuetify";

import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        options: {
            customProperties: true,
        },
        themes: {
            dark: {
                primary: "#009688",
                secondary:"#2196f3",
                accent:"#ffc107",
                error:"#f44336",
                warning:"#ff5722",
                info:"#00bcd4",
                success:"#4caf50"
            },
            light: {
                primary: "#009688",
                secondary:"#2196f3",
                accent:"#ffc107",
                error:"#f44336",
                warning:"#ff5722",
                info:"#00bcd4",
                success:"#4caf50"
            }
        }
    },
    icons: {
        iconfont: "mdi",
    },
});