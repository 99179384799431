















































































































import Vue from 'vue';

import MixinConstant from "@coreMixin/store/Constant.mixin";
import UserMixin from "@coreMixin/store/User/User.mixin";

import ApplicantEntity from "@coreEntity/master-proposal/proposal/applicant/Applicant.entity";

export default Vue.extend({

    mixins: [ UserMixin, MixinConstant ],

    props: {

        entity: {
            type: ApplicantEntity
        },

        disabled: {
            type: Boolean,
            default: false
        }

    },

    computed: {

        activeSupplierGroup () {
            return this.storeUserGetActiveSupplierGroup();
        },

        activeRegion () {
            return process.env.VUE_APP_REGION && process.env.VUE_APP_REGION === 'UK' ? 'UK': "LMO";
        },

        getTitleItems () {
            return this.storeConstantGetListWithEmpty("person_title");
        },

        getGenderItems () {
            return this.storeConstantGetListWithEmpty("person_gender");
        },

        getMaritalItems () {
            return this.storeConstantGetListWithEmpty("person_marital");
        },

        getCountryItems () {
            return this.storeConstantGetListWithEmpty("country");
        },

        formErrors () {
            const obj = {
                "applicant_pre_id": [],
                "applicant_gender_id": [],
                "applicant_marital_id": [],
                "applicant_country_id": [],
                "applicant_first_name": [],
                "applicant_last_name": [],
                "applicant_dob": [],
                "applicant_email": [],
                "applicant_mobile_tel": [],
                "applicant_national_insurance": []
            };

            if(!this.entity.preID) {
                obj.applicant_pre_id.push("Title required");
            }

            if(!this.entity.genderID) {
                obj.applicant_gender_id.push("Gender required");
            }

            if(!this.entity.maritalID) {
                obj.applicant_marital_id.push("Marital required");
            }

            if(!this.entity.countryID) {
                obj.applicant_country_id.push("Place of birth required");
            }

            if(!this.entity.firstName) {
                obj.applicant_first_name.push("First name required");
            }

            if(!this.entity.lastName) {
                obj.applicant_last_name.push("Last name required");
            }

            if(!this.entity.dob) {
                obj.applicant_dob.push("D.O.B required");
            }

            if(!this.entity.email) {
                obj.applicant_email.push("Email required");
            }

            if(!this.entity.mobileTel) {
                obj.applicant_mobile_tel.push("Mobile telephone required");
            }

            if(!this.entity.nationalInsurance && this.activeRegion === 'IRE') {
                obj.applicant_national_insurance.push("PPS number is required");
            }

            return obj;
        }

    },

    data ()
    {
        return {
            config: {
                dobManualRegEx:  process.env.VUE_APP_DATE_REG ? process.env.VUE_APP_DATE_REG: null
            }
        }
    },

    methods: {

        getFormErrors (text)
        {
            return this.formErrors[text] ? this.formErrors[text]: [];
        }

    }

});
