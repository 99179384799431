import Entity from "npm-marsh-core/src/entity/Entity";

import EntityChecklistItem from "../../item/Item.entity";

export default class EntityChecklistGroupItem extends Entity {

    public  id: number|null = null;
    public  typeID: number|null = null;
    public  groupID: number|null = null;
    public  departmentID: number|null = null;
    public  suppVisible: number = 0;
    public  removable: number = 0;

    public  text: string|null = null;

    protected mapDbProperties = {
        "checklist_group_item_id": "id",
        "checklist_group_item_type_id": "typeID",
        "checklist_group_item_group_id": "groupID",
        "checklist_group_item_department_id": "departmentID",
        "checklist_group_item_supplier_visible": "suppVisible",
        "checklist_group_item_removable": "removable",
        "checklist_group_item_text": "text"
    };

    convertToChecklistItem ():EntityChecklistItem {
        const entity = new EntityChecklistItem();

        entity.setTypeID(this.typeID);
        entity.setGroupID(this.groupID);
        entity.setText(this.text);
        entity.setSupplierVisible(this.suppVisible);
        entity.setRemovable(this.removable);

        return entity;
    }

    /**
     *
     * Getters
     *
     */

    public getID () {
        return this.id;
    }

    public getTypeID () {
        return this.typeID;
    }

    public getGroupID () {
        return this.groupID;
    }

    public getDepartmentID () {
        return this.departmentID;
    }

    public getSupplierVis () {
        return this.suppVisible;
    }

    public getText () {
        return this.text;
    }

}