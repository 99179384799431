





















import Vue from 'vue';

import BankEntity from "@coreEntity/master-proposal/proposal/bank/Bank.entity";

export default Vue.extend({

    props: {

        entity: {
            type: BankEntity
        },

        autoValidate: {
            type: Boolean,
            default: true
        },

        disabled: {
            type: Boolean
        }

    },

    data () {
        return {
            label: {
                account_num: process.env.VUE_APP_LABEL_BANK_ACC_NUM,
                sort_code: process.env.VUE_APP_LABEL_BANK_SORTCODE
            }
        };
    }

});
