
import Vue from 'vue';

import MixinUser from "@coreMixin/store/User/User.mixin";

export default Vue.extend({

    props: {

        userEntity: {

        },

        httpErrors: {

        }

    },

    mixins: [ MixinUser ],

    computed: {

        formErrors () {
            const arr  = [];

            if(!this.userEntity.user_email) {
                arr.push("Please enter an email");
            }

            return arr;
        },

        formSuccess () {
            return this.formErrors.length > 0 ? false: true;
        },

        isUserAdmin () {
            return this.storeUserGetAdmin();
        }

    },

    data () {
        return {
            boolSelect: [{text: "Yes", value: 1}, {text: "No", value: 0}],
            twoFaTypes: [{text: "Email", value: 'EMAIL'}, {text: "QR Code", value: 'QR_CODE'}]
        };
    },

    methods: {

        fireUpdateUser () {
            this.$emit("updateUser");
        },

        fireResetUser () {
            this.$emit("resetUser");
        },


        fireReset2Fa () {
            this.$emit("reset2Fa");
        },

        fireDeleteUser () {
            this.$emit("deleteUser");
        }

    }

});
