
import Entity from "npm-marsh-core/src/entity/Entity";

export default class AddressEntity extends Entity {

    public id: number|null = null;
    public countryID: number|null = null;
    public flatNumber: string|number|null = "";
    public houseNum: string|number|null = "";
    public houseName: string|null = "";
    public line1: string|null = "";
    public line2: string|null = "";
    public line3: string|null = "";
    public town: string|null = "";
    public postcode: string|null = "";
    public county: string|null = "";
    public createdAt: string|null = null;

    protected mapDbProperties = {
        "address_id": "id",
        "address_country_id": "countryID",
        "address_flat_num": "flatNumber",
        "address_house_num": "houseNum",
        "address_house_name": "houseName",
        "address_line_1": "line1",
        "address_town": "town",
        "address_postcode": "postcode",
        "address_county": "county",
        "createdAt": "createdAt"
    };

    public setCountryID (id: number): void
    {
        this.countryID = id;
    }

    public getSummaryText () {
        const arr = [];

        if(this.flatNumber) {
            arr.push(this.flatNumber);
        }

        if(this.houseNum) {
            arr.push(this.houseNum);
        }

        if(this.houseName) {
            arr.push(this.houseName);
        }

        if(this.line1) {
            arr.push(this.line1);
        }

        if(this.town) {
            arr.push(this.town);
        }

        if(this.postcode) {
            arr.push(this.postcode);
        }

        return arr.join(", ");
    }

}