
import Vue from 'vue';

//service
import MasterProposalService from "@coreService/master-proposal/Index.service";

//mixin
import MixinChecklist from "@coreMixin/store/Component/Checklist.mixin";

//entity
import MpChecklistEntity from "@coreEntity/master-proposal/checklist/MasterProposalChecklist.entity";

export default Vue.extend({

    mixins: [ MixinChecklist ],

    props: {

        mpID: {
            type: Number
        }

    },

    computed: {

        validation () {
            const arr = [];

            if(!this.model.mpChecklistEntity.getName()) {
                arr.push("Checklist needs a name");
            }

            if(!this.model.mpChecklistEntity.getDesc()) {
                arr.push("Checklist needs a description");
            }

            return arr;
        }

    },

    data () {
        return {
            model: {
                mpChecklistEntity: new MpChecklistEntity()
            }
        };
    },

    mounted () {
        this.mpService = new MasterProposalService(this);
    },

    methods: {

        async createNewChecklist () {
            const httpRes = await this.mpService.createChecklist(this.mpID, this.model.mpChecklistEntity);
            this.$emit("refresh");
        }

    }
});
