
import Entity from "npm-marsh-core/src/entity/Entity";

import CollectionQuests from "./questionnaire/Questionnaire.collection";

export default class EntitySignatory extends Entity {

    public id: number|null = null;
    public sessionID: number|null = null;
    public signatoryID: number|null = null;
    public applicantID: number|null = null;
    public stateID: number|null = null;
    public failedQNACount: number|null = null;
    public failedCardCount: number|null = null;
    public actionList: string|null = null;
    public emailLink: string|null = null;
    public dateCreated: string|null = null;

    public collectionQuests: CollectionQuests;

    protected mapDbProperties = {
        "edoc_signatory_id": "id",
        "edoc_signatory_session_id": "sessionID",
        "edoc_signatory_signatory_id": "signatoryID",
        "edoc_signatory_applicant_id": "applicantID",
        "edoc_signatory_state_id": "stateID",
        "edoc_signatory_action_list": "actionList",
        "edoc_signatory_failed_qna": "failedQNACount",
        "edoc_signatory_failed_card": "failedCardCount",
        "edoc_signatory_email_link": "emailLink",
        "edoc_signatory_date_created": "dateCreated",
        "edoc_signatory_date_updated": "dateUpdated",
        "edoc_signatory_date_deleted": "dateDeleted",
        "__questionnaire_collection": {
            entity_getter: "collectionQuests",
            entity_setter: "collectionQuests"
        }
    };

    constructor () {
        super();

        this.collectionQuests = new CollectionQuests();
    }

    public getCollecitonQuests  () {
        return this.collectionQuests;
    }

    public getFirstQuestionanaire  () {
        const items = this.getCollecitonQuests().getItems();

        return items.length ? items[0]: null;
    }

}