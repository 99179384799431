
































import Vue from 'vue';

import AddressCollectionEntity from "@coreEntity/master-proposal/proposal/applicant/ApplicantAddress.collection";

import AddressForm from "./Form.vue";

export default Vue.extend({

    components: {
        AddressForm
    },

    props: {

        collectionEntity: {
            type: AddressCollectionEntity
        },

        disabled: {
            type: Boolean,
            default: false
        },

        minItems: {
            type: Number,
            default: 1
        },

        maxItems: {
            type: Number,
            default: 3
        },

        proposalApplicantIndex: {
            type: Number
        }

    },

    data () {
        return {
            env: {
                appDefaultCountryID: process.env.VUE_APP_MP_DEFAULT_COUNTRY ? parseInt(process.env.VUE_APP_MP_DEFAULT_COUNTRY): null,
                appDefaultAddressCountryID: process.env.VUE_APP_MP_ADDR_DEFAULT_COUNTRY ? parseInt(process.env.VUE_APP_MP_ADDR_DEFAULT_COUNTRY): null
            }
        }
    },

    computed: {

        collectionItems () {
            return this.collectionEntity.getItems();
        },

        canAddAddress () {
            return this.collectionEntity.getItemCount() < this.maxItems;
        },

        canDeleteAddress () {
            return this.collectionEntity.getItemCount() > 1;
        }

    },

    methods: {

        addNewAddress () {
            if(this.collectionEntity.getItemCount() >= this.maxItems) {
                this.$toasted.error(`You cannot have more then ${this.maxItems} address record`);
                return;
            }

            const appAddressEntity = this.collectionEntity.addNewItem();

            if(this.env.appDefaultAddressCountryID) {
                appAddressEntity.getAddressEntity().setCountryID(this.env.appDefaultAddressCountryID);
            }
        },

        deleteItem (itemIndex) {
            if(this.collectionEntity.getItemCount() <= this.minItems) {
                this.$toasted.error(`You need atleast ${this.minItems} address records`);
                return;
            }

            this.collectionEntity.deleteItemIndex(itemIndex);
        }

    }

});
