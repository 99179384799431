



















import Vue from 'vue';

import MasterProposalService from "@coreService/master-proposal/Index.service";

import EntityMpChecklist from "@coreEntity/master-proposal/checklist/MasterProposalChecklist.entity";
import EntityChecklistItem from '../../../../../entity/checklist/item/Item.entity';

import Header from "./component/Header.vue";
import OptionBar from "./options/Options.vue";
import InfoBar from "./component/Info.vue";
import PercentileBar from "./component/PercentileBar.vue";
import ItemList from "./item/List.vue";
import ItemCreate from "./item/Create.vue";

export default Vue.extend({

    components: {
        Header, OptionBar, ItemList, InfoBar, PercentileBar, ItemCreate
    },

    props: {

        mpID: {
            type: Number
        },

        mpChecklistEntity: {
            type: EntityMpChecklist
        },

        showHeader: {
            type: Boolean,
            default: false
        },

        showFilter: {
            type: Boolean,
            default: true
        },

        optsShowChecked: {
            type: Number,
            default: null
        },

        disabled: {
            type: Boolean,
            default: false
        },

        compact: {
            type: Boolean,
            default: false
        },

        readOnly: {
            type: Boolean,
            default: false
        }

    },

    computed: {

        checklistEntity () {
            return this.mpChecklistEntity.getChecklistEntity();
        },

        checklistItemCollection () {
            return this.checklistEntity.getItemCollection();
        },

        arrChecklistItems () {
            const arr = this.checklistItemCollection.getItems()

            return arr.filter(this.filterChecklistItems);
        },

        checklistEditable () {
            return this.checklistEntity.getEditable();
        }

    },

    data () {
        return {
            modal: {
                addItem: false
            },
            opts: {
                itemChecked: null,
                departmentID: null,
                deletedID: 0
            }
        };
    },

    mounted () {
        this.mpService = new MasterProposalService(this);
        this.init();
    },

    methods: {

        init () {
            this.setProps();
        },

        setProps () {
            if(this.optsShowChecked !== null) {
                this.opts.itemChecked = this.optsShowChecked;
            }
        },


        async checklistItemComplete (itemID: Number) {
            const httpRes = await this.mpService.completeChecklistItem(this.mpID, itemID);
        },

        async checklistItemRemove (itemID: Number) {
            const httpRes = await this.mpService.deleteChecklistItem(this.mpID, itemID);
        },

        async checklistItemAddComment (itemID: Number, comment: String) {
            const httpRes = await this.mpService.addCommentChecklistItem(this.mpID, itemID, comment);
        },

        editChecklist () {

        },

        filterChecklistItems (ele: EntityChecklistItem) {
            if(this.opts.itemChecked !== null && ele.getChecked() !== this.opts.itemChecked) {
                return false;
            }

            if(this.opts.deletedID !== null && (+ele.isDeleted()) !== this.opts.deletedID) {
                return false;
            }

            return true;

        },

        /**
         *
         * Events
         *
         */

        evItemsAdded () {
            this.modal.addItem = false;

            this.$emit("refresh", this.mpChecklistEntity.getID());
        },

        evChecklistCompleteItem (itemID: Number) {
            this.checklistItemComplete(itemID);
        },

        evChecklistRemoveItem (itemID: Number) {
            this.checklistItemRemove(itemID);
        },

        evChecklistItemAddComment (itemID: Number, comment: String) {
            this.checklistItemAddComment(itemID, comment);
        },
    }

});
