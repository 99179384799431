export default {

    namespaced: true,

    state: {

        list_type_collection: [],
        item_type_collection: []

    },

    getters: {

        checklistStoreGetListTypes (state)
        {
            return state.list_type_collection;
        },

        checklistStoreGetItemTypes (state)
        {
            return state.item_type_collection;
        }

    },

    mutations: {

        checklistStoreSetTypeCollection (state, collection)
        {
            state.list_type_collection = collection;
        },

        checklistStoreSetItemTypeCollection (state, collection)
        {
            state.item_type_collection = collection;
        }

    }

}