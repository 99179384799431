
import Vue from 'vue';

import { AdminUserService } from "@coreService/admin/user/User.service";

export default Vue.extend({

    data () {
        return {
            list : []
        };
    },

    mounted () {
        this.getList();
    },

    methods: {

        async getList () {
            const serv = new AdminUserService(this);
            const httpRes = await serv.list();

            this.list = httpRes.getData();
        }

    }

});
