
    import Vue from "vue";

    import StoreMap from "@/core/store/store.map";

    export default Vue.extend({

        methods: {

            /**
             *
             * Getters
             *
             */

            storeSuppliersGetListWithEmpty (supplierType: number, defaultText: string = "Please Select") {
                switch (supplierType) {
                    case 1:
                        return [].concat([{funder_id: null, funder_name: defaultText}], this.storeSuppliersGetFunders());
                    case 2:
                        return [].concat([{broker_id: null, broker_name: defaultText}], this.storeSuppliersGetBrokers());
                    case 3:
                        return [].concat([{dealer_id: null, dealer_name: defaultText}], this.storeSuppliersGetDealers());
                    case 4:
                        return [].concat([{funder_id: null, funder_name: defaultText}], this.storeSuppliersGetOutFunders());
                }

                return [];
            },

            storeSuppliersGetOutFunders () {
                return this.$store.getters[`${StoreMap.app_api_quote_supplier_store}suppStoreGetOutBoundFunderCollection`]
            },

            storeSuppliersGetInFunders () {
                return this.$store.getters[`${StoreMap.app_api_quote_supplier_store}suppStoreGetInBoundFunderCollection`]
            },

            storeSuppliersGetFunders () {
                return this.$store.getters[`${StoreMap.app_api_quote_supplier_store}suppStoreGetFunderCollection`]
            },

            storeSuppliersGetBrokers () {
                return this.$store.getters[`${StoreMap.app_api_quote_supplier_store}suppStoreGetBrokerCollection`]
            },

            storeSuppliersGetDealers () {
                return this.$store.getters[`${StoreMap.app_api_quote_supplier_store}suppStoreGetDealerCollection`]
            },

            storeSuppliersGetFunder (id) {
                return this.$store.getters[`${StoreMap.app_api_quote_supplier_store}suppStoreGetFunder`]({id});
            },

            storeSuppliersGetBroker (id) {
                return this.$store.getters[`${StoreMap.app_api_quote_supplier_store}suppStoreGetBroker`]({id});
            },

            storeSuppliersGetDealer (id) {
                return this.$store.getters[`${StoreMap.app_api_quote_supplier_store}suppStoreGetDealer`]({id});
            },

            /**
             *
             * Actions
             *
             */

            storeSuppliersActionGetSupplier (typeID, id) {
                this.$store.dispatch(`${StoreMap.app_api_quote_supplier_store}suppStoreActGetSupplier`, { type_id: typeID, id })
            },

            /**
             *
             * Mutations
             *
             */

             storeSuppliersSetSupplierCollection (supplierObject) {
                this.$store.commit(`${StoreMap.app_api_quote_supplier_store}qsStoreSetSupplierCollection`, supplierObject);
            }

        }

    });