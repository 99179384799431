
import Entity from "npm-marsh-core/src/entity/Entity";

import EntityUser from '../../user/User.entity';

export default class EntityMpUpload extends Entity {

    public id: number|null = null;
    public mpID: number|null = null;
    public typeID: number|null = null;
    public userID: number|null = null;

    public name: string = "";
    public state: string = "";
    public hashedName: string = "";
    public fileExtension: string = "";
    public size: string|number = "";
    public createdAt: string|null = "";
    public deletedAt: string|null = "";

    public splitID: number|null = null;

    public userEntity: EntityUser;

    protected mapDbProperties = {
        proposal_master_document_id: "id",
        proposal_master_document_proposal_master_id: "mpID",
        // proposal_master_document_proposal_id: "",
        proposal_master_document_type_id: "typeID",
        proposal_master_document_user_id: "userID",
        proposal_master_document_name: "name",
        proposal_master_document_state: "state",
        proposal_master_document_hashed_name: "hashedName",
        // proposal_master_document_extension: "",
        proposal_master_document_size: "size",
        // proposal_master_document_note: "",
        // proposal_master_document_proof_type_id_json: "",
        // proposal_master_document_upload_department_id: "",
        // proposal_master_document_upload_user_id: "",
        // proposal_master_document_uploaded_at: "",
        proposal_master_document_created_at: "createdAt",
        proposal_master_document_deleted_at: "deletedAt",
        proposal_master_document_split_type_id: "splitID"
    }

    constructor (userEntity: EntityUser = null) {
        super();

        this.userEntity = userEntity ? userEntity: new EntityUser();
    }

    public canSplit (): boolean {
        return this.splitID ? true: false;
    }

    /**
     *
     * Getters
     *
     */

    public getEntityUser (): EntityUser {
        return this.userEntity;
    }

    public setState (state) {
        this.state = state;
    }

    public getUsersName () {
        return this.userEntity.getID() ? this.userEntity.getName(): "API";
    }

    public getID () {
        return this.id;
    }

    public getDocName () {
        return this.name;
    }

    public getTypeID () {
        return this.typeID;
    }

    public getState () {
        return this.state;
    }

    public getDocExtension () {
        return this.fileExtension;
    }

    public getDocSize () {
        return this.size;
    }

    public getCreatedAt () {
        return this.createdAt;
    }

    public getDeletedAt () {
        return this.deletedAt;
    }

}