





























import Vue from 'vue';

import ProposalApplicantCollection from "@coreEntity/master-proposal/proposal/Applicant.collection";
import ProposalApplicant from '@coreEntity/master-proposal/proposal/ProposalApplicant.entity';

import ProposalApplicantForm from "@coreComponent/master-proposal/proposal/applicant/Form.vue";

export default Vue.extend({

    components: {
        ProposalApplicantForm
    },

    props: {

        collectionEntity: {
            type: ProposalApplicantCollection
        }

    },


    computed: {

        canAddApplicant () {
            return this.getApplicantCount() >= 2 ? false: true;
        },

        canDeleteApplicant () {
            return this.getApplicantCount() <= 1 ? false: true;
        }

    },

    data () {
        return {
            rules: {
                maxApplicants: 2
            },
            env: {
                appDefaultCountryID: process.env.VUE_APP_MP_DEFAULT_COUNTRY ? parseInt(process.env.VUE_APP_MP_DEFAULT_COUNTRY): null,
                appDefaultAddressCountryID: process.env.VUE_APP_MP_ADDR_DEFAULT_COUNTRY ? parseInt(process.env.VUE_APP_MP_ADDR_DEFAULT_COUNTRY): null
            }
        };
    },

    methods: {

        addNewApplicant () {
            const proposalApplicant = new ProposalApplicant();
            const appAddressEntity = proposalApplicant.getApplicant().getAddressCollection().addNewItem();
            proposalApplicant.getApplicant().getEmploymentCollection().addNewItem();

             if(this.env.appDefaultCountryID) {
                proposalApplicant.getApplicant().setCountryID(this.env.appDefaultCountryID);
            }

            if(this.env.appDefaultAddressCountryID) {
                appAddressEntity.getAddressEntity().setCountryID(this.env.appDefaultAddressCountryID);
            }

            this.collectionEntity.addItem(proposalApplicant);
        },

        deleteApplicant (appIndex) {
            this.collectionEntity.deleteItemIndex(appIndex);

        },

        getApplicantCount () {
            return this.collectionEntity.getItemCount();
        },

        cloneAddress (applicantIndex, addressIndex) {
            let parentAddressItem = this.collectionEntity.getItem(0).getApplicant().getAddressCollection().getItem(addressIndex);
            if (!parentAddressItem) {
                return;
            }

            this.collectionEntity.getItem(applicantIndex).getApplicant().getAddressCollection().getItem(addressIndex).mapApplyDatabase(
                parentAddressItem.mapDatabaseToObject()
            );

        },

        getTabText (proposalAppEntity)
        {
            const name = proposalAppEntity.getName();

            if(!name.trim()) {
                return "New Applicant";
            }

            return name;
        }

    }


});
