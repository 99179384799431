import Vue from 'vue';
import Vuex from 'vuex';

import ApplicationStore from "./app/Application.store";

Vue.use(Vuex);

export default new Vuex.Store({

    modules: {
        ApplicationStore
    }

});
