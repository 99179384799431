    import Service from '../Service';

    export default class ChecklistGroupService extends Service
    {

        //get checklist groups
        getChecklistGroups (includeGroupItems = true)
        {
            let formData = new FormData();
            formData.append("include_item", includeGroupItems ? "true": "false");

            return this.post(`/checklist/group`, formData);
        }

        //get checklist group id
        getChecklistGroup (groupID, includeGroupItems = true)
        {
            let formData = new FormData();
            formData.append("include_item", includeGroupItems ? "true": "false");

            return this.post(`/checklist/group/${groupID}`, formData);
        }

       //create a new checklist
        createGroup (name, description)
        {
            let formData = new FormData();
            formData.append("name", name);
            formData.append("description", description);

            return this.post(`/checklist/group/create`, formData);
        }


        //delete a group
        deleteGroup (groupID)
        {
            return this.post(`/checklist/group/${groupID}/delete`);
        }

    }