
import Entity from "npm-marsh-core/src/entity/Entity";

import BankEntity from "./bank/Bank.entity";

import ApplicantCollection from "./Applicant.collection";
import EntityCollectionProposalQuote from "./quote/ProposalQuote.collection";
import CollectionEsign from "./esign/Esign.collection";

import ProposalApplicantEntity from "./ProposalApplicant.entity";
import EntityProposalQuote from "./quote/ProposalQuote.entity";
import EntityEsign from './esign/Esign.entity';

export default class Proposal extends Entity {

    public id: number|null = null;
    public parentProposalID: number|null = null;
    public masterProposalID: number|null = null;
    public statusID: number|null = null;
    public statusSubID: number|null = null;
    public activeQuoteID: number|null = null;
    public creditTier: number|null = null;
    public contractStartDate: string|null = null;
    public paymentFirstDate: string|null = null;
    public supplierBankAcc: string|null = null;
    public supplierBankSort: string|null = null;
    public mainApplicantID: number|null = null;
    public createdAt: string|null = null;

    public applicantCollection: ApplicantCollection;
    public quoteCollection: EntityCollectionProposalQuote;
    public bankEntity: BankEntity;
    public collectionEsign: CollectionEsign;

    protected mapDbProperties = {
        "proposal_id": "id",
        "proposal_parent_proposal_id": "parentProposalID",
        "proposal_proposal_master_id": "masterProposalID",
        "proposal_main_applicant_id": "mainApplicantID",
        "proposal_status_id": "statusID",
        "proposal_sub_status_id": "statusSubID",
        "proposal_credit_tier": "creditTier",
        "proposal_quote_active_id": "activeQuoteID",
        "proposal_contract_start_date": "contractStartDate",
        "proposal_payment_start_date": "paymentFirstDate",
        "proposal_supplier_bank_acc": "supplierBankAcc",
        "proposal_supplier_bank_sortcode": "supplierBankSort",
        "proposal_applicant_collection_entity": {
            entity_getter: "applicantCollection",
            entity_setter: "applicantCollection"
        },
        "proposal_quote_collection_entity": {
            entity_getter: "quoteCollection",
            entity_setter: "quoteCollection"
        },
        "proposal_bank_entity": {
            entity_getter: "bankEntity",
            entity_setter: "bankEntity"
        },
        "proposal_esigning_collection_entity": {
            entity_getter: "collectionEsign",
            entity_setter: "collectionEsign"
        },
        "proposal_created_at": "createdAt"
    };

    constructor () {
        super();

        this.applicantCollection = new ApplicantCollection();
        this.quoteCollection = new EntityCollectionProposalQuote();
        this.bankEntity = new BankEntity();
        this.collectionEsign = new CollectionEsign();
    }

    /**
     *
     * Entity methods
     *
     */

    public getAppCollection (): ApplicantCollection {
        return this.applicantCollection;
    }

    public getQuoteCollection (): EntityCollectionProposalQuote {
        return this.quoteCollection;
    }

    public getCollectionEsign () {
        return this.collectionEsign;
    }

    public addProposalApplicant (proposalApplicantEntity: ProposalApplicantEntity) {
        this.applicantCollection.addItem(proposalApplicantEntity);
    }


    public getBankEntity (): BankEntity {
        return this.bankEntity;
    }

    public getEntityActiveQuote (): EntityProposalQuote|null {
        const activeQuoteID = this.getActiveQuoteID();

        if(!activeQuoteID) {
            return null;
        }

        return this.getQuoteCollection().getQuoteID(activeQuoteID);
    }

    public getMainApplicant (): ProposalApplicantEntity|null {
        const   collectionApps = this.getAppCollection(),
                appItems = collectionApps.getItems();

        if(!appItems.length) {
            return null;
        }

        return appItems[0];
    }

    public getActiveEsign (): Array<EntityEsign> {
        const   activeQuoteID = this.getActiveQuoteID(),
                collectionEsignItems = this.getCollectionEsign().getItems();

        if(!collectionEsignItems.length) {
            return [];
        }

        return collectionEsignItems.filter((entityEsign: EntityEsign) => {
            return entityEsign.getQuoteID() === activeQuoteID;
        });
    }

    /**
     *
     * Setters
     *
     */

    public setActiveQuoteID (proposalQuoteID: number) {
        this.activeQuoteID = proposalQuoteID;
    }

    /**
     *
     * Getters
     *
     */

    public getID () {
        return this.id;
    }

    public getStatusID (): number|null {
        return this.statusID;
    }

    public getSubStatusID (): number|null {
        return this.statusSubID;
    }

    public getCreditTier () {
        return this.creditTier;
    }

    public getCreditTierText (): string {
        return this.creditTier !== null ? new String(this.creditTier).toString(): "N/A";
    }

    public getActiveQuoteID () {
        return this.activeQuoteID;
    }

    public getCreatedAt (): string {
        return this.createdAt;
    }

    public getDeletedAt (): string|null {
        return null;
    }
}