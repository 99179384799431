
import EntityCollection from "npm-marsh-core/src/entity/EntityCollection";

import EntityProposalQuote from "./ProposalQuote.entity";

export default class EntityCollectionProposalQuote extends EntityCollection {

    public getNewItem (): EntityProposalQuote {
        return new EntityProposalQuote();
    }

    public getQuoteID (quoteID: number) {
        return this.getItems().find((ele: EntityProposalQuote) => {
            return ele.getID() === quoteID;
        });
    }

}