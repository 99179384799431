/**
 *
 * Settings for the application that can be modified by the app itself or user settings
 *
 */

 import MpEventStore from "./Event/MpEvent.store";

export default {

    namespaced: true,

    modules: {
        MpEventStore
    },

    state: {
        active_mp_entity: null,
        proposal_state_map: [],
        proposal_upload_sub_types: []
    },

    getters: {

        applicationStoreGetMasterProposalEntity (state)
        {
            return state.active_mp_entity;
        },

        applicationStoreGetProposalStateMap (state)
        {
            return state.proposal_state_map;
        },

        applicationStoreGetProposalSubTypes (state)
        {
            return state.proposal_upload_sub_types;
        },

        applicationStoreGetProposalStateSubStates (state)
        {
            return (statusID) => {
                return state.proposal_state_map.filter((el) => {
                    return el.system_proposal_state_map_status_id == statusID;
                })
            }
        }

    },

    mutations: {

        applicationStoreUpdateMpEntity (state, mpEntity)
        {
            state.active_mp_entity = mpEntity;
        },

        applicationStoreUpdateStateMap (state, arrData)
        {
            state.proposal_state_map = arrData;
        },

        applicationStoreUpdateSubType (state, arrData)
        {
            state.proposal_upload_sub_types = arrData;
        }

    }
};