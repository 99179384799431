<template>
    <div id="app">
        <v-app>
            <v-main>
                <PublicApplication v-if="_isAuthed === false"/>
                <SecureApplication v-else/>
            </v-main>
        </v-app>
    </div>
</template>
<script>

    import AuthMixin from "@coreMixin/auth/Auth.mixin.js";

    import SecureApplication from "@secure/App.vue";
    import PublicApplication from "@public/App.vue";

    import Serv from "@coreService/Service";

    export default {

        mixins: [ AuthMixin ],

        components: {
            SecureApplication, PublicApplication
        },

        computed: {
            isCurrentRoutePublic () {
                return this.currentRoutePublic();
            },

            currentRouteName () {
                return this.$route.name;
            }
        },

        created () {
            window.document.title = process.env.VUE_APP_TITLE ? process.env.VUE_APP_TITLE: "Salesdot V2";

            this.setBaseUrl(process.env.VUE_APP_API_URL);
            this.setAuthTokenFromStorage();
        },

        mounted () {
            this.checkInitRoute();

            const serv = new Serv(this);
            serv.spinnerStart();
        },

        methods: {

            checkInitRoute () {
                const bool = this.currentRoutePublic();

                //public route
                if(bool === true) {
                    return;
                }

                //secure route and not authed
                if(this._isAuthed === false && this.currentRouteName !== "login") {
                    this.$router.push({name: "login"});
                }
            }

        }
    }
</script>
<style lang="scss">

    @import "./core/scss/html.scss";

</style>