<template>

    <LoginContainer>

         <v-container>
            <v-row>
                <h4>{{title}}</h4>
            </v-row>

            <v-row>
                <v-text-field
                    label="Username" required type="email"
                    v-model.trim.lazy="form.model.username" :rules="validateEmail()"
                />
            </v-row>

            <v-row class="form-button-row">
                <v-btn
                    color="info" type="submit" class="mt-4"
                    :small="true" :disabled="!form.model.username"
                    @click="resetPassword"
                >
                    Reset Password
                </v-btn>
            </v-row>
        </v-container>


    </LoginContainer>

</template>
<script>

    import { Validation } from "@/core/lib/validation/index";

    import AuthService from "@coreService/user/Auth.service";
    import AuthMixin from "@coreMixin/auth/Auth.mixin.js";

    import LoginContainer from "../component/LoginContainer.vue";

    export default {
        components: {
            LoginContainer
        },

        mixins: [ AuthMixin ],

        computed: {

            title () {
                return this.firstTimeSetup ? "Set your initial password": "Reset your password";
            }

        },

        data () {
            return {
                firstTimeSetup: false,
                form: {
                    model: {
                        username: ""
                    }
                }
            };
        },

        mounted () {
            this.checkQueryParams();
            this.deleteAuth();
        },

        methods: {

            //generate a new password for this user
            async resetPassword ()
            {
                const serv = new AuthService(this);
                const httpRes = await serv.resetPassword(this.form.model.username);

                if(httpRes.hasErrors()) {
                    this.$toasted.error("Failed to reset password");
                    return;
                }

                this.$toasted.info("Email has been sent to your account");
                this.$router.push({name: "login"});
            },

            //validation
            validateEmail ()
            {
                const ValidationCL = Validation;

                return [
                    (v) => { return v === "" ? "Please enter an email": true },
                    (v) => { return ValidationCL.validateEmail(v) ? true: "Invalid email" }
                ];
            },

            checkQueryParams ()
            {
                //first-time
                if(this.$route.query["first-time"]){
                    this.firstTimeSetup = true;
                }
            }

        }

    }

</script>