
import Service from "../../Service";

export class AdminUserService extends Service
{

    protected serviceName = "APP Service";
    protected servicePublic = true;

    public async list ()
    {
        return this.get("/admin/user");
    }

    public async create (userObj: any)
    {
        return this.put("/admin/user", userObj);
    }

    public async idGet (userID: number)
    {
        return this.get(`/admin/user/${userID}`);
    }

    public async idUpdate (userID: number, userObj: any)
    {
        return this.put(`/admin/user/${userID}`, userObj);
    }

    public async idDelete (userID: number)
    {
        return this.delete(`/admin/user/${userID}`);
    }

    public async idToggleSupplier (userID: number, remove: boolean, supplierType: string, supplierID: number)
    {
        return this.post(`/admin/user/${userID}/supplier`, {
            remove,
            supplier_type: supplierType,
            supplier_id: supplierID
        });
    }

    public async idResetPassword (userID: number)
    {
        return this.post(`/admin/user/${userID}/reset`);
    }

    public async idReset2Fa (userID: number)
    {
        return this.post(`/admin/user/${userID}/reset-twofa`);
    }

}
