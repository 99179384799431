import HttpResponse from 'npm-marsh-core/src/service/Response';

export default {

    namespaced: true,

    state: {

        application_event_types: [],
        nextRunDateTime: null,
        http: {
            request_entity: null,
            filters: {
                page: 1,
                item_count: 20,
                event_seen: 0,
                event_types: [],
                proposal_master_id: null,
                proposal_master_event_created_at: getYesterdaysDateTime()
            }
        }

    },

    getters: {

        mpEventStoreGetEventTypes (state) {
            return state.application_event_types;
        },

        mpEventStoreGetEventTypeID: (state) => (id) => {
            return state.application_event_types.find(ele => {
                return ele.system_pipeline_request_type_id === id;
            });
        },

        mpEventStoreGetPaginationData (state) {
            if(!state.http.request_entity) {
                return null;
            }

            if(state.http.request_entity.hasErrors()) {
                return null;
            }

            return state.http.request_entity.getData().pagination;
        },

        mpEventStoreGetFilters (state) {
            return state.http.filters;
        },

        mpEventStoreGetErrors (state): any[] {
            if(!state.http.request_entity) {
                return null;
            }

            return state.http.request_entity.getErrors();
        },

        mpEventStoreGetNextRunDateTime (state)
        {
            return state.nextRunDateTime;
        }

    },

    mutations: {

        mpEventStoreSetApplicationEventTypes (state, eventTypes) {
            state.application_event_types = eventTypes;
        },

        mpEventStoreSetHttpEntity (state, httpEntity: HttpResponse) {
            state.http.request_entity = httpEntity;
        },

        mpEventStoreSetFilter (state, payload)
        {
            state.http.filters[payload.key] = payload.value;
        },

        mpEventStoreSetNextRunDateTime (state, dateTime)
        {
            state.nextRunDateTime = dateTime;
        }
    }

}

function getYesterdaysDateTime () {

    let date: any = new Date();
    let yesterday: any = date - (1000 * 60 * 60 * 24 * 2);   // current date's milliseconds - 1,000 ms * 60 s * 60 mins * 24 hrs * (# of days beyond one to go back)
    yesterday = new Date(yesterday);

    return yesterday.toISOString().split('T')[0];
}