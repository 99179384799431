
    import Vue from "vue";

    import StoreMap from "@/core/store/store.map";
    import HttpResponse from 'npm-marsh-core/src/service/Response';

    export default Vue.extend({

       methods: {

            /**
             *
             * Setters
             *
             */

            mixinEventSetAppEventTypes (eventTypes) {
                this.$store.commit(`${StoreMap.app_proposal_event_store}mpEventStoreSetApplicationEventTypes`, eventTypes);
            },

            mixinEventSetEventHttp (httpRes: HttpResponse) {
                this.$store.commit(`${StoreMap.app_proposal_event_store}mpEventStoreSetHttpEntity`, httpRes);
            },

            mixinEventUpdateFilter (key: string, value: any) {
                this.$store.commit(`${StoreMap.app_proposal_event_store}mpEventStoreSetFilter`, { key, value });
            },

            mixinEventUpdateNextRunTime (value: any) {
                this.$store.commit(`${StoreMap.app_proposal_event_store}mpEventStoreSetNextRunDateTime`, value);
            },

            /**
             *
             * Getters
             *
             */

            mixinEventGetPaginationData () {
                return this.$store.getters[`${StoreMap.app_proposal_event_store}mpEventStoreGetPaginationData`];
            },

            mixinEventGetRequestFilters () {
                return this.$store.getters[`${StoreMap.app_proposal_event_store}mpEventStoreGetFilters`];
            },

            mixinEventGetEventTypes () {
                return this.$store.getters[`${StoreMap.app_proposal_event_store}mpEventStoreGetEventTypes`];
            },

            mixinEventGetEventType (id) {
                return this.$store.getters[`${StoreMap.app_proposal_event_store}mpEventStoreGetEventTypeID`](id);
            },

            mixinEventGetEventTypeText (id) {
                const obj = this.mixinEventGetEventType(id);

                return obj ? obj.system_pipeline_request_type_name: "N/A";
            },

            mixinEventGetNextRunTime () {
                return this.$store.getters[`${StoreMap.app_proposal_event_store}mpEventStoreGetNextRunDateTime`];
            }


       }

    });