


























































































import Vue from 'vue';

import ServiceMpQuote from "@coreService/master-proposal/quote/MpQuote.service";

import ConstantMixin from "@coreMixin/store/Constant.mixin";

import MasterProposalEntity from '@coreEntity/master-proposal/MasterProposal.entity';
import EntityProposalQuote from "@coreEntity/master-proposal/proposal/quote/ProposalQuote.entity";
import EntityCollectionMpVehicle from "@coreEntity/master-proposal/vehicle/Vehicle.collection";
import EntityQuote from "@coreEntity/quote/Quote.entity";

import Standard from "./form/Standard.vue";
import ProposalQuoteID from "../quote/id/ProposalQuote.vue";

export default Vue.extend({

    mixins: [ ConstantMixin ],

    components: {
        Standard,  ProposalQuoteID
    },

    props: {

        mpEntity: {
            type: MasterProposalEntity
        },

        disabled: {
            type: Boolean
        }

    },

    computed: {

        activeQuoteID () 
        {
            return this.mpEntity.getProposalEntity().getActiveQuoteID();
        },

        proposalQuoteCollection (): EntityProposalQuote[] 
        {
            return this.mpEntity.getProposalEntity().getQuoteCollection().getItems();
        },

        mpVehicleCollection (): EntityCollectionMpVehicle 
        {
            return this.mpEntity.getVehicleCollection();
        }

    },

    data () {
        return {
            modal: {
                budget: false,
                standard: false,
                proposalQuoteView: false
            },
            activeQuote: {
                entity: null,
                httpErrors: []
            }
        };
    },

    mounted () {
        this.mpService = new ServiceMpQuote(this);
    },

    methods: {

        quoteCreated () 
        {
            this.modal.standard = false;
            this.modal.budget = false;

            this.$emit('mp-refresh');
        },

        getProposalQuoteClass (proposalQuoteEntity: EntityProposalQuote) 
        {
            let arr = ["tr-proposal-quote"];
            const quoteEntity = proposalQuoteEntity.getQuoteEntity();

            if(this.activeQuoteID === proposalQuoteEntity.getID()) {
                arr.push("proposal-quote-active");
            }

            if(!quoteEntity.getQuoteValid()) {
                arr.push('quote-invalid');
            }

            if(quoteEntity.getQuoteEdited()) {
                arr.push('quote-edited');
            }

            return arr.join(" ");
        },

        getLoanTypeText (loanTypeID: Number): string 
        {
            return this.storeConstantGetListItemID("quote_loan", loanTypeID).text;
        },

        expandQuote (proposalQouteEntity: EntityProposalQuote) 
        {
            this.activeQuote.entity = proposalQouteEntity;
            this.modal.proposalQuoteView = true;
        },

        async activateQuote (proposalQuoteEntity: EntityProposalQuote) 
        {
            const proposalQuoteID = proposalQuoteEntity.getID();

            //http
            const httpRes = await this.httpMpActivateQuote(proposalQuoteID);

            const   baseErrors = httpRes.getErrors(),
                    data = httpRes.getData();

            if(baseErrors.length) {
                this.activeQuote.baseErrors = baseErrors;
                this.$toasted.error("Failed to activate quote, see errors");
                return;
            }

            //local
            this.mpEntity.getProposalEntity().setActiveQuoteID(proposalQuoteID);
            this.$toasted.success("Quote activated");
        },

        getQuoteClass(quoteEntity: EntityQuote)
        {
            if(!quoteEntity.getQuoteValid()) {
                return 'quote-invalid';
            }

            if(quoteEntity.getQuoteEdited()) {
                return 'quote-edited';
            }
            
            return null;
        },

        /**
         *
         * Http methods
         *
         */

        async httpMpActivateQuote (proposalQuoteID: Number) {
            return this.mpService.activeQuote(this.mpEntity.getID(), proposalQuoteID)
        }

    }

});
