
import Vue from 'vue';

import TestingData from "./lib/TestData";

//mixins
import MixinUser from "@coreMixin/store/User/User.mixin";

//servs
import MpCreateService from "@coreService/master-proposal/create/Create.service";

//entites
import MasterProposalEntity from "@coreEntity/master-proposal/MasterProposal.entity";
import ProposalApplicant from '@coreEntity/master-proposal/proposal/ProposalApplicant.entity';
import ApplicantCollectionEntity from "@coreEntity/master-proposal/proposal/Applicant.collection";

//comps
import TabCreate from "./component/tab/Create.vue";

export default Vue.extend({

    mixins: [ MixinUser ],

    components: {
        TabCreate
    },

    computed: {

        hasCreateErrors () {
            return (this.create.http.errors.length || this.create.http.validationErrors.length || this.create.http.ruleValidationErrors.length) ? true: false;
        },

        userList () {
            return this.storeUserGetActiveSupplierList();
        }
    },

    data () {
        return {
            create: {
                loadingRequest: false,
                component: "TabCreate",
                model: {
                    masterProposalEntity: null,
                },
                http: {
                    errors: [],
                    validationErrors: [],
                    ruleValidationErrors: []
                }
            },
            env: {
                appDefaultCountryID: process.env.VUE_APP_MP_DEFAULT_COUNTRY ? parseInt(process.env.VUE_APP_MP_DEFAULT_COUNTRY): null,
                appDefaultAddressCountryID: process.env.VUE_APP_MP_ADDR_DEFAULT_COUNTRY ? parseInt(process.env.VUE_APP_MP_ADDR_DEFAULT_COUNTRY): null
            }
        };
    },

    mounted () {
        this.create.model.masterProposalEntity = this.getEmptyMasterProposal();
    },

    methods: {

        /**
         *  Create a new master proposal
         */
        async createMasterProposal () {
            this.create.loadingRequest = true;
            this.clearHttpErrors();

            let serv = new MpCreateService(this),
                httpRes = await serv.createMP(this.create.model.masterProposalEntity);

            this.create.loadingRequest = false;

            if(httpRes.hasErrors()) {
                this.$toasted.error("Failed to create master proposal");
                this.create.http.errors = httpRes.getErrors();
                return;
            }

            const   data = httpRes.getData(),
                    dataErrors = data.errors,
                    validationErrors = data.proposal_errors.validation,
                    ruleValidationErrors = data.proposal_errors.rule_validation,
                    mpID = data.proposal_id;

            //rule errors
            if(dataErrors.length || validationErrors.length || ruleValidationErrors.length) {
                this.create.http.errors = dataErrors;
                this.create.http.validationErrors = validationErrors;
                this.create.http.ruleValidationErrors = ruleValidationErrors;
                this.$toasted.error("Failed to create master proposal");
                return;
            }

            //created okay
            if(mpID) {
                this.redirectToProposal(mpID);
            }
        },

        applyTestData () {
            TestingData(this.create.model.masterProposalEntity);
        },

        redirectToProposal (mpID: Number) {
            this.$toasted.success("Master proposal created, redirecting");
            this.$router.push({ name: "mp_id", params: {id: mpID} });
        },

        getMasterProposalEntity (): MasterProposalEntity {
            return this.create.model.masterProposalEntity;
        },

        getApplicantCollection (): ApplicantCollectionEntity {
            return this.getMasterProposalEntity().getProposalEntity().getAppCollection();
        },

        /**
         * Clear all http errors
         */
        clearHttpErrors () {
            this.create.http.errors = [];
            this.create.http.validationErrors = [];
            this.create.http.ruleValidationErrors = [];
        },

        /**
         * Setup a new master proposal entity
         */
        getEmptyMasterProposal (): MasterProposalEntity {
            let mpEntity = new MasterProposalEntity();

            const proposalApplicant = new ProposalApplicant();
            const appAddressEntity =proposalApplicant.getApplicant().getAddressCollection().addNewItem();
            proposalApplicant.getApplicant().getEmploymentCollection().addNewItem();
            mpEntity.getProposalEntity().addProposalApplicant(proposalApplicant);

            if(this.env.appDefaultCountryID) {
                proposalApplicant.getApplicant().setCountryID(this.env.appDefaultCountryID);
            }

            if(this.env.appDefaultAddressCountryID) {
                appAddressEntity.getAddressEntity().setCountryID(this.env.appDefaultAddressCountryID);
            }

            return mpEntity;
        },

        getCreateService (): MpCreateService {
            return this.createService;
        }

    }

});
