import Entity from "npm-marsh-core/src/entity/Entity";

export default class EntityChecklistItemType extends Entity {

    protected id: number|null = null;
    protected name: string|null = null;
    protected constant: string|null = null;
    protected createdAt: string|null = null;

    protected mapDbProperties = {
        "checklist_item_type_id": "id",
        "checklist_item_type_text": "name",
        "checklist_item_type_constant": "constant",
        "checklist_item_type_created_at": "createdAt"
    };

    public setID (val: number) {
        return this.id = val;
    }

    public getID () {
        return this.id;
    }

    public getName () {
        return this.name;
    }

}