
import Vue from 'vue';

export default Vue.extend({

    props: {
        imageType: {
            type: String
        }
    },

    computed: {

        imageData () {
            return this.imageType === "UK" ? this.uk: this.lmo
        }

    },

    data () {
        return {
            uk: require('@/core/assets/marsh-logo.png'),
            lmo: require('@/core/assets/lmo-logo.png')
        };
    }

});
