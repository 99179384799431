





































    //service
    import MasterProposalService from "@coreService/master-proposal/Index.service";
    import CollectionWetSign from "@coreEntity/master-proposal/wetsign/WetSign.collection"
    import MasterProposalEntity from "@/core/entity/master-proposal/MasterProposal.entity";
    import moment from 'moment';
import Links from "@/core/components/_ui/social/Links.vue";


    export default {

        
        props: {
            mpEntity:
            {
                type: MasterProposalEntity
            }
        },

        data () {
            return {

            };
        },

        created () {
            this.masterProposalService = new MasterProposalService(this);
        },
        mounted () {
            console.log('quote entity checking', this.mpEntity)
        },
        computed: {

            wetSignList() 
            {
                return this.mpEntity.getCollectionWetSign()
            },
            masterProposalID ()
            {
                return this.mpEntity.getID();
            },
            

        },
        methods: {
            getActiveQuote(){

                return this.mpEntity.getProposalEntity().getEntityActiveQuote().getID()
            },
            getVehicleReg(quoteId: number){   

                return this.mpEntity.getProposalEntity().getQuoteCollection().getQuoteID(quoteId).getVehicleEntity().getReg();

            },

            getQuoteAdvance(quoteId: number){

                return this.mpEntity.getProposalEntity().getQuoteCollection().getQuoteID(quoteId).getQuoteEntity().getAdvance();
                

            },
            getWetSignCreatedAt(quoteId: number){

                return this.mpEntity.getProposalEntity().getQuoteCollection().getQuoteID(quoteId).getQuoteEntity().getCreatedAt();
                

            },

            formattedCreatedAt(quoteId: number) {
              const createdAt = this.getWetSignCreatedAt(quoteId);
              return moment(createdAt).format("DD/MM/YYYY H:mm:ss");
            },
            downloadWetsign(wetDocID) {
                let me = this;          

                this.getWetDocByID(wetDocID)
                    .then(res => {
                        if (res["errors"].length > 0) {
                            for (var error of res["errors"]) {
                                me.$toasted.error(error);
                            }
                            return;
                        }           

                        // Convert base64 data to a Uint8Array
                        const byteCharacters = atob(res["data"].document_base64);
                        const byteArray = new Uint8Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteArray[i] = byteCharacters.charCodeAt(i);
                        }           

                        // Create a Blob from the Uint8Array
                        const blob = new Blob([byteArray], { type: res["data"].document_name });            

                        // Create a download link
                        const downloadLink = document.createElement('a');
                        downloadLink.href = URL.createObjectURL(blob);
                        downloadLink.download = res["data"].document_name;          

                        // Simulate a click on the link to trigger the download
                        downloadLink.click();           

                        // Clean up
                        URL.revokeObjectURL(downloadLink.href);         

                        console.log('file', res["data"].document_base64);
                    })
                    .catch(err => {
                        me.$toasted.error('Failed to get document data');
                    });
            },

            getWetDocByID (wetDocID) {
                const mpService = new MasterProposalService(this);
                return mpService.getWetSignDocument(this.masterProposalID, wetDocID);
            },

            getUserText (dbRow) {
                if(!dbRow.user_id) {
                    return "API";
                }

                return `${dbRow.user_first_name} ${dbRow.user_last_name}`;
            },

            getVehicleText (dbRow) {
                if(dbRow.vehicle_reg) {
                    return dbRow.vehicle_reg;
                }

                return [ dbRow.vehicle_cap_code, dbRow.vehicle_vin, dbRow.vehicle_make, dbRow.vehicle_model ].join();
            }

        }

    }

