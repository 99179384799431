
import Vue from 'vue';

import MasterProposalEntity from "@coreEntity/master-proposal/MasterProposal.entity";

import Summary from "@coreComponent/master-proposal/id/summary/Summary.vue";

import ApplicantTabs from "./applicant/Tabs.vue";
import QuoteView from "./quote/Index.vue";
import BankView from "@coreComponent/bank/Form/Form.vue";
import SignView from "./sign/Index.vue";
import OpenBanking from "./open-banking/index.vue";

export default Vue.extend({

    components: {
        Summary, ApplicantTabs, QuoteView, BankView,
        SignView, OpenBanking
    },

    props: {
        entity: {
            type: MasterProposalEntity
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    computed: {

        getApplicantCollection () {
            return this.entity.getProposalEntity().getAppCollection();
        }

    },

    data() {
        return {
            show: {
                openBanking: parseInt(process.env.VUE_APP_ALLOW_OPEN_BANKING) ? true: false
            }
        }
    }

});
