<script>
    import MixinConstant from "@coreMixin/store/Constant.mixin";

    export default {

        mixins: [ MixinConstant ],

        props: {

            typeID: {
                type: Number
            },

            eventJson: {
                type: String
            }
        },

        computed: {

            uiText () {
                const method = this.types[this.typeID];

                return method ? method(): this.notFound();
            },

            parsedJson () {
                try {
                    const parsed = JSON.parse(this.eventJson);
                    return parsed;
                }
                catch ( err ) {
                    return "";
                }
            }

        },

        data () {
            return {
                types: {
                    1: this.checklistCreated,
                    2: this.checklistItemCreated,
                    3: this.checklistItemCompleted,
                    4: this.proposalStateChange,
                    7: this.proposalQuoteCreated,
                    8: this.proposalUpdated,
                    13: this.proposalQuoteActivated,
                    14: this.proposalBooked,
                    20: this.externalNote
                }
            }
        },

        methods: {

            notFound () {
                return this.eventJson;
            },

            checklistCreated () {
                const name = this.parsedJson.checklist_name;

                return `New Checklist '${name}'`;
            },

            checklistItemCreated () {
                const text = this.parsedJson.item_text;

                return `New Checklist item '${text}'`;
            },

            checklistItemCompleted () {
                const text = this.parsedJson.item_text;

                return `New Checklist item '${text}'`;
            },

            proposalStateChange () {
                const stateText = this.getStatusText(this.parsedJson.status_id);

                return `Proposal Status has changed to ${stateText}`
            },

            proposalQuoteCreated () {
                return "";
            },

            proposalUpdated () {
                return "";
            },

            proposalQuoteActivated () {
                return "";
            },

            proposalBooked () {
                return "";
            },

            externalNote () {
                const text = this.parsedJson.proposal_master_note_text;
                return `New note received. ${text}`
            },

            /**
             *
             * Helping methods
             *
             */

            getStatusText (id) {
                return this.storeConstantGetListItemText("proposal_status", id);
            }

        }

    };
</script>
<template>

    <div>
        {{uiText}}
    </div>

</template>