
import Vue from 'vue';

export default Vue.extend({

    props: {
        id: {
            type: Number
        },
        typeID: {
            type: Number
        }
    },

    computed: {

        supplierComponent () {
            switch (this.typeID) {
                case 1:
                case 4:
                    return "SupplierFunderID";
                case 2:
                    return "SupplierBrokerID";
                case 3:
                    return "SupplierDealerID";
            }
        },

        isBookingFunder () {
            return this.typeID === 4;
        }

    }

});
