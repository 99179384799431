import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

//all views
import NotFound from "@/views/404.vue";

//secure views
import Dashboard from "@secure/views/dashboard/Index.vue";

//mp
import MpList from "@secure/views/master-proposal/list/Index.vue";
import MpCreate from "@secure/views/master-proposal/create/Index.vue";
import MpID from "@secure/views/master-proposal/id/Index.vue";

//admin
import AdminUserList from "@secure/views/admin/user/list/List.vue";
import AdminUserCreate from "@secure/views/admin/user/create/Create.vue";
import AdminUserUpdate from "@secure/views/admin/user/id/Index.vue";

//app
import NotAllowed from "@secure/views/permission/Invalid.vue";

//public views
import Login from "@public/views/login/Index.vue";
import LoginReset from "@public/views/login/reset/Index.vue";
import AccountRecovery from "@public/views/login/account/Recovery.vue";
import AccountSetup from "@public/views/account-setup/AccountSetup.vue";
import Logout from "@public/views/logout/Index.vue";

const routes: Array<RouteConfig> = [
    { path: "", name: "home", component: Dashboard },
    { path: "/dashboard",  name: "dashboard", component: Dashboard },

    { path: "/master-proposal",  name: "mp_list", component: MpList },
    { path: "/master-proposal/create",  name: "mp_create", component: MpCreate },
    { path: "/master-proposal/:id",  name: "mp_id", component: MpID, props: true },

    { path: "/admin/user",  name: "user_list", component: AdminUserList },
    { path: "/admin/user/create",  name: "user_create", component: AdminUserCreate },
    { path: "/admin/user/:userID",  name: "user_id", component: AdminUserUpdate, props: true },

    { path: "/not-allowed", component: NotAllowed, name: "permission_invalid", meta: { public: true } },
    { path: "/login", name: "login", component: Login, meta: { public: true } },
    { path: "/reset-password", name: "user_reset_password", component: LoginReset, meta: { public: true } },
    { path: "/account-recovery", name: "user_account_recovery", component: AccountRecovery, meta: { public: true } },
    { path: "/account-setup", name: "user_account_setup", component: AccountSetup, meta: { public: true } },
    { path: "/logout", name: "logout", component: Logout, meta: { public: true } },
    { path: "/404", component: NotFound, name: "FourOhFour", meta: { public: true } },
    { path: "*", redirect: "404",meta: { public: true } }
]

const router = new VueRouter({
    routes
});

export default router;
