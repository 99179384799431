
import Vue from 'vue';

import EventItem from "../Item/Event.vue";

export default Vue.extend({

    components: {
        EventItem
    },

    props: {

        list: {

        }

    },

    computed: {

        groupedList ()
        {
            const store = [],
                list = this.list;

            if(!list.length) {
                return store;
            }

            for (let index = 0; index < list.length; index++) {
                const dbEvent = list[index];

                let itemID = dbEvent.proposal_master_event_mp_id,
                    foundResult = store.find(ele => {
                        return ele.item_id === itemID;
                    });

                //no result found, add an entry
                if(!foundResult) {
                    store.push({
                        item_id: itemID,
                        notifications: [ dbEvent ]
                    });

                    continue;
                }

                //result found, add the record
                foundResult.notifications.push(dbEvent);
            }

            return store;
        }

    },

    methods: {

        readEvents (obj)
        {
            const mpID: number = obj.item_id;
            const eventIDs: number[] = [];

            obj.notifications.forEach(element => {
                eventIDs.push(element.proposal_master_event_id);
            });

            this.$emit("readEvents", {
                mp_id: mpID,
                event_ids: eventIDs
            });
        },

        checkForUnReadNotificaiton (notifications): boolean
        {
            for (let index = 0; index < notifications.length; index++) {
                const element = notifications[index];

                if(element.proposal_master_event_user_viewed_id === null) {
                    return true;
                }
            }

            return false;
        }

    }

});
