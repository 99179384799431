export default {

    auth_store: "AuthStore/",
    core_store: "CoreStore/",

    app_api_quote_store: "ApplicationStore/ApiQuoteStore/",
    app_api_quote_supplier_store: "ApplicationStore/ApiQuoteStore/SupplierStore/",

    app_checklist_store: "ApplicationStore/ChecklistStore/",
    app_constant_store: "ApplicationStore/ConstantStore/",
    app_proposal_store: "ApplicationStore/ProposalStore/",
    app_proposal_event_store: "ApplicationStore/ProposalStore/MpEventStore/",

    app_user_store: "ApplicationStore/UserStore/",
    app_user_supplier_store: "ApplicationStore/UserStore/SupplierStore/"
}