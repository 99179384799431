






















import Vue from 'vue';

import MasterProposalEntity from '@coreEntity/master-proposal/MasterProposal.entity';

import MasterProposalChecklistList from "@coreViews/master-proposal/id/original/checklist/View.vue";
import MasterProposalVehicles from "@coreViews/master-proposal/id/original/vehicle/Table.vue";
import MasterProposalUploads from "@coreViews/master-proposal/id/original/uploads/Index.vue";

import ProposalView from "./proposal/Index.vue";

export default Vue.extend({

    components: {
        ProposalView, MasterProposalChecklistList,
        MasterProposalVehicles,
        MasterProposalUploads
    },

    props: {
        entity: {
            type: MasterProposalEntity
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            lazy: {
                checklist: false
            }
        };
    }

});
