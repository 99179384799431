
import Vue from 'vue';

import MasterProposalEntity from "@coreEntity/master-proposal/MasterProposal.entity";
import CollectionMpUpload from "@coreEntity/master-proposal/upload/MpUpload.collection";

//service
import MpDocService from "@coreService/master-proposal/document/MpDocument.service";

//cmps
import UploadDefault from "./default/Index.vue";

export default Vue.extend({

    components: {
        UploadDefault,
    },

    props: {

        mpEntity: {
            type: MasterProposalEntity
        },

        compact: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        }

    },

    computed: {

        displayComponent () {
            return "UploadDefault";
        },

        documentCollection () {
            return this.mpEntity.getCollectionUploads();
        }

    },

    mounted () {
        this.MpDocService = new MpDocService(this);
    },

    methods: {

        refresh () {
            this.$emit('mp-refresh');
        }

    }

});
