
    import Vue from "vue";

    import UserSessionMixin from "./User/User.mixin";
    import ApiQuoteMixin from "./ApiQuote.mixin";
    import ConstantMixin from "./Constant.mixin";
    import ProposalMixin from "./Proposal.mixin";
    import ChecklistMixin from "./Component/Checklist.mixin";
    import MpEventMixix from "./MasterProposal/Mp.mixin";

    export default Vue.extend({

        mixins: [
            UserSessionMixin, ConstantMixin, ApiQuoteMixin, ProposalMixin, ChecklistMixin, MpEventMixix
        ]

    });