
import Vue from 'vue';

import MixinUser from "@coreMixin/store/User/User.mixin";
import QuoteMixin from "@coreMixin/store/ApiQuote.mixin";

import EventBus from "@core/event-bus/EventBus";
import SupplierMapEntity from "@coreEntity/user/supplier/UserSupplier.entity";
import UserService from "@coreService/my/User.service";
import TermsView from "@coreComponent/quote/TermsView.vue";

export default Vue.extend({

    components: {
        TermsView
    },

    mixins: [ MixinUser, QuoteMixin],

    computed: {

        title()
        {
            if(this.activeSupplierGroup) {
                const suppID = this.activeSupplierGroup.getSupplierID();
                const suppType = this.activeSupplierGroup.getSupplierEnum();

                return `Your Active Supplier. ${suppType} - ${suppID}`;
            }

            return "Your Active Supplier";
        },

        userSupplierCollection()
        {
            return this.storeUserSupGetCollection();
        },

        userSupplierList()
        {
            const   list = [];

            if(!this.userSupplierCollection) {
                return list;
            }

            const arr: SupplierMapEntity[] =  this.userSupplierCollection.getItems();

            arr.forEach((element: SupplierMapEntity) => {
                const obj = {
                    id: element.getID(),
                    name: ""
                };

                switch (element.getSupplierEnum()) {
                    case "FUNDER":
                        const funder = this.storeSuppliersGetFunder(element.getSupplierID());

                        if(funder) {
                            obj.name = funder.funder_name;
                        }
                        else {
                            console.log("Funder not found", element);
                        }

                        break;
                    case "BROKER":
                        const broker = this.storeSuppliersGetBroker(element.getSupplierID());

                        if(broker) {
                            obj.name = broker.broker_name;
                        }
                        else {
                            console.log("Broker not found", element);
                        }

                        break;
                    case "DEALER":
                        const dealer = this.storeSuppliersGetDealer(element.getSupplierID());
                        if(dealer) {
                            obj.name = dealer.dealer_name;
                        }
                        else {
                            console.log("Dealer not found", element);
                        }

                        break;
                }

                list.push(obj);
            });

            return list;
        },

        userEntity()
        {
            return this.storeUserGetSessionEntity();
        },

        activateGroupID()
        {
            return this.userEntity ? this.userEntity.activeGroupID: null;
        },

        supplierTerms()
        {
            return this.getActiveSupplierTerms();
        },

        activeSupplierGroup()
        {
            return this.storeUserGetActiveSupplierGroup();
        }

    },

    data() {
        return {
            modal: {
                terms: false
            }
        };
    },

    mounted()
    {
        this.checkUserSupplier();
    },

    methods: {

        async updateGroup ()
        {
            const serv = new UserService(this);
            const res = await serv.activateSupplier(this.activateGroupID);

            EventBus.userSupplierGroupChanged(this.activateGroupID);
        },

        checkUserSupplier()
        {               
            //we have an active supplier
            if(this.activeSupplierGroup && this.activeSupplierGroup.getID()) {
                return;
            }

            //the user doesn't have any suppliers attached to them
            if(!this.userSupplierList.length) {
                return;
            }

            //set the first supplier we have.
            this.userEntity.activeGroupID = this.userSupplierList[0].id;
            this.updateGroup(); 
        }

    }

});
