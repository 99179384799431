<script>

export default {

    props: {

        twoFaData: {

        }

    },

    computed: {

        qrUrl() 
        {
            return this.twoFaData.two_type_output.qr_code_url;
        }

    },

    data() {
        return {
            model: {
                two_fa_code: ''
            }
        }
    },

    methods: {

        emitCodeEntered()
        {
            this.$emit('codeEntered', { code: this.model.two_fa_code });
        }

    }

}

</script>
<template>

    <div>
        <h5 class="mb-4">2.1 Scan the QR code and enter a code below</h5>
        <div>
            <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_GB&gl=US">Phone App: Google Authenticator</a>
        </div>
        <iframe 
            ref="iframe" style="height: 200px; width: 200px;" frameborder="0" scrolling="no" 
            :src="qrUrl"
        />

        <AppFormInput label="Test two fa code" required>
            <AppTextField 
                :autocomplete="false" v-model.trim="model.two_fa_code" 
            />
        </AppFormInput>

        <div class="form-button-row">
            <AppButton text="complete" :disabled="!model.two_fa_code" @click="emitCodeEntered" />
        </div>
    </div>

</template>