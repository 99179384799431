
































import Vue from 'vue';

import ProposalApplicantEntity from "@coreEntity/master-proposal/proposal/ProposalApplicant.entity";

import PersonalForm from "./personal/Form.vue";
import AddressCollectionForm from "./address/Collection.vue";
import EmploymentCollectionForm from "./employment/Collection.vue";

export default Vue.extend({

    components: {
        PersonalForm, AddressCollectionForm, EmploymentCollectionForm
    },

    props: {
        entity: {
            type: ProposalApplicantEntity
        },

        proposalApplicantIndex: {
            type: Number
        },

        disabled: {
            type: Boolean,
            default: false
        }
    }

});
