
import Vue from 'vue';

import MasterProposalEntity from '@coreEntity/master-proposal/MasterProposal.entity';
import ConstantMixin from "@coreMixin/store/Constant.mixin";
import ServiceMasterProposal from "@coreService/master-proposal/Index.service";
import ProposalMixin from '@/core/mixin/store/Proposal.mixin';

import EsignList from '@coreComponent/master-proposal/id/signing/esign/List.vue';
import WetsignList from "@coreComponent/master-proposal/id/signing/wetsign/List.vue";

export default Vue.extend({

    mixins: [ ConstantMixin, ProposalMixin ],

    components: {
        EsignList, WetsignList
    },

    props: {

        mpEntity: {
            type: MasterProposalEntity
        },

        disabled: {
            type: Boolean,
            default: false
        }

    },

    computed: {
        masterProposalID ()
        {
            return this.mpEntity.getID();
        },

        esignCollection ()
        {
            return this.mpEntity.getProposalEntity().getCollectionEsign().getItems();
        },

        wetsignCollection ()
        {
            return this.mpEntity.getCollectionWetSign();
        },

        signingTypes ()
        {
            const signingTypes: any[] = this.storeConstantGetList("proposal_signing_type");
            const listSigningTypes: any[] = signingTypes;

            //if were not allow wetsigns, remove it
            if(!this.env.allowWetsign) {
                const wetsignIndex = listSigningTypes.findIndex(ele => {
                    return ele.standardized === "WETSIGN";
                });

                if(wetsignIndex !== -1) {
                    listSigningTypes.splice(wetsignIndex, 1);
                }
            }

            //if were not allow esigns, remove it
            if(!this.env.allowEsign) {
                const esignIndex = listSigningTypes.findIndex(ele => {
                    return ele.standardized === "ESIGN";
                });

                if(esignIndex !== -1) {
                    listSigningTypes.splice(esignIndex, 1);
                }
            }

            return signingTypes;
        }

    },

    data () {
        return {
            newSigning: {
                processID: null,
                httpErrors: []
            },
            env: {
                allowWetsign: process.env.VUE_APP_ALLOW_WETSIGN ? parseInt(process.env.VUE_APP_ALLOW_WETSIGN): 1,
                allowEsign: process.env.VUE_APP_ALLOW_ESIGN ? parseInt(process.env.VUE_APP_ALLOW_ESIGN): 1,
                defaultSigningID: process.env.VUE_APP_DEFAULT_SIGNING_ID ? parseInt(process.env.VUE_APP_DEFAULT_SIGNING_ID): 1
            }
        };
    },

    mounted () {
      
        this.newSigning.processID = this.env.defaultSigningID
        this.masterProposalService = new ServiceMasterProposal(this);
    },

    methods: {

        //start an signing process
        startProcess ()
        {
            let me = this;
            const signingID: number = this.newSigning.processID;

            this.httpSendProcess(this.masterProposalID, signingID).then(res => {
                if (!res) {
                    console.log(res);
                    return;
                }
                else if (!res["success"] && res["errors"].length > 0) {
                    this.newSigning.httpErrors = res["errors"];
                }

                this.handleSigningSuccess(res["data"], signingID);

            })
            .catch(err => {
                me.$toasted.error("Failed to start signing process");
                console.log(err);
            });
        },

        handleSigningSuccess (data: any, signingID: number)
        {
            switch (signingID) {
                //ESIGN
                case 1:

                    break;
                //WETSIGN
                case 2:
                    window.open(process.env.VUE_APP_API_URL + data.url, "_blank");
                    break;
            }

            this.$emit('mp-refresh');
        },

        /**
         *
         * Http methods
         *
         */

        httpSendProcess (masterProposalID, processID) {
            return this.masterProposalService.sign(masterProposalID, processID);
        }

    }

});
