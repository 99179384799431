<template>

    <AppPill class="component-supplier-broker">{{brokerName}}</AppPill>

</template>

<script>

    import ApiQuoteMixin from "@coreMixin/store/ApiQuote.mixin";

    export default {

        mixins: [ ApiQuoteMixin ],

        props: {
            id: {
                type: [String, Number],
                default: null
            },

            outFunder: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            brokerObj () {
                return this.storeSuppliersGetBroker(this.id);
            },

            brokerName () {
                return this.brokerObj ? this.brokerObj.broker_name: "Broker Not Found";
            }
        }
    };

</script>
<style lang="scss">

    .component-supplier-broker {

        &.component-app-pill.component-app-pill {
            background-color: var(--v-info-lighten1);
        }

    }

</style>