import StoreMap from "../../store.map";

import SupplierStore from "./supplier/Supplier.store";

const userStore = {

    namespaced: true,

    modules: {
        SupplierStore
    },

    state: {
        user: null,
        activeSupplierData: null
    },

    actions: {

        userStoreActUpdateUser ({ commit, state }, userEntity)
        {
            this.commit(`${StoreMap.app_user_store}userStoreUpdateUserData`, userEntity);
        },

        userStoreActUpdateActiveSupplierData ({ commit, state }, activeData)
        {
            this.commit(`${StoreMap.app_user_store}userStoreUpdateActiveSupplierData`, activeData);
        }

    },

    mutations: {

        userStoreUpdateUserData(state, userEntity)
        {
            state.user = userEntity;
        },

        userStoreUpdateActiveSupplierData(state, activeData)
        {
            state.activeSupplierData = activeData;
        },

        destroyUser (state)
        {
            state.user = null;
        }

    },

    getters: {

        usrStoreGetUserEntity (state)
        {
            return state.user;
        },

        usrStoreGetActiveSupplierData (state)
        {
            return state.activeSupplierData;
        },

        usrStoreGetName (state)
        {
            return `${state.user.email}`;
        },

        usrStoreGetAdmin (state)
        {
            return state.user.getAdmin();
        },

        usrStoreloggedIn (state)
        {
            return state.user === null ? false: true;
        }

    }

}

export default userStore;