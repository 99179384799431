
import Service from "../../Service";

export default class ServiceMasterProposalQuote extends Service
{

    protected serviceName = "Master Proposal Quote Service";
    protected servicePublic = false;

    createFullQuote (masterProposalID, quoteData) {
        return this.post(`/mp/${masterProposalID}/quote`, quoteData);
    }

    activeQuote(masterProposalID, quoteID) {
        var formData = new FormData();

        formData.set("quoteID", quoteID);

        return this.post(`/mp/${masterProposalID}/quote/${quoteID}`, formData);
    }

}