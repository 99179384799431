
import Vue from 'vue';

import CmpMpChecklist from "@coreComponent/master-proposal/id/checklist/id/Index.vue";

export default Vue.extend({

    components: {
        CmpMpChecklist
    },

    props: {

        mpEntity: {

        },

        dbMpChecklists: {

        },

        optsShowChecked: {
            type: Number,
            default: null
        },

        disabled: {
            type: Boolean,
            default: false
        },

        compact: {
            type: Boolean,
            default: false
        },

        readOnly: {
            type: Boolean,
            default: false
        }
    }

});
