import ConstantStore from "./constant/constant.store.js";
import ProposalStore from "./proposal/proposal.store.js";
import ApiQuoteStore from "./quote/quote.store.js";
import UserStore from "./user/user.store.js";
import ChecklistStore from "./checklist/checklist.store.js";

export default {

    namespaced: true,

    modules: {
        ConstantStore, ProposalStore, ApiQuoteStore, UserStore, ChecklistStore
    }

}