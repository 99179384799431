
import Service from "../Service";

export default class AuthService extends Service
{

    protected serviceName = "AUTH_SERVICE";
    protected servicePublic = true;

    //checkAccountStatus
    public async checkAccountStatus (username: string)
    {
        return await this.post("/login-status", { username });
    }

    /**
     * Attempt to login
     * @param username
     * @param password
     */
    public async auth (username: string, password: string, two_fa_code: string)
    {
        return await this.post("/login", { username, password, two_fa_code });
    }

    /**
     * Reset logging in users password
     * @param username
     */
    public async resetPassword (login: string)
    {
        return await this.post("/reset-password", { login });
    }

    /**
     * Account recovery
     * @param username
     */
    public async recoverAccount (token: string, password: string)
    {
        return await this.post("/recover-account", { token, password });
    }

    public async startAccountSetup (email: string, token: string)
    {
        return await this.post("/start-account-setup", { email, token });
    }

    public async completeAccountSetup (email: string, token: string, password: string, twoFaCodeTest: string)
    {
        return await this.post("/complete-account-setup", { 
            email, 
            token, 
            password, 
            test_2fa_code: twoFaCodeTest 
        });
    }

}