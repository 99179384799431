
import EntityCollection from "npm-marsh-core/src/entity/EntityCollection";

import WetSignEntity from "./WetSign.entity";

export default class EntityCollectionWetSign extends EntityCollection {

    public getNewItem (): WetSignEntity {
        return new WetSignEntity();
    }

}