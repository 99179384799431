
import Entity from "npm-marsh-core/src/entity/Entity";

import AddressCollection from "./ApplicantAddress.collection";
import EmploymentCollection from "./AppicantEmployment.collection";

export default class ApplicantEntity extends Entity {

    public id: number|null = null;
    public preID: number|null = null;
    public genderID: number|null = null;
    public maritalID: number|null = null;
    public countryID: number|null = null;
    public bankID: number|null = null;
    public firstName: string|null = "";
    public middleName: string|null = "";
    public lastName: string|null = "";
    public dob: string|null = "";
    public email: string|null = "";
    public homeTel: string|null = "";
    public mobileTel: string|null = "";
    public nationalInsurance: string|null = "";
    public passportNumber: string|null = "";
    public drivingLicense: string|null = "";
    public monthlyIncome: number|null = null;
    public createdAt: string|null = null;

    public addressCollection: AddressCollection;
    public employmentCollection: EmploymentCollection;

    protected mapDbProperties = {
        "applicant_id": "id",
        "applicant_pre_id": "preID",
        "applicant_gender_id": "genderID",
        "applicant_marital_id": "maritalID",
        "applicant_country_id": "countryID",
        "applicant_bank_id": "bankID",
        "applicant_first_name": "firstName",
        "applicant_middle_name": "middleName",
        "applicant_last_name": "lastName",
        "applicant_dob": "dob",
        "applicant_email": "email",
        "applicant_home_tel": "homeTel",
        "applicant_mobile_tel": "mobileTel",
        "applicant_national_insurance": "nationalInsurance",
        "applicant_passport_number": "passportNumber",
        "applicant_driving_license": "drivingLicense",
        "applicant_monthly_income": "monthlyIncome",
        "applicant_created_at": "createdAt",
        "applicant_address_collection_entity": {
            entity_getter: "addressCollection",
            entity_setter: "addressCollection"
        },
        "applicant_employment_collection_entity": {
            entity_getter: "employmentCollection",
            entity_setter: "employmentCollection"
        }
    };

    constructor () {
        super();

        this.addressCollection = new AddressCollection();
        this.employmentCollection = new EmploymentCollection();
    }

    public getAddressCollection (): AddressCollection {
        return this.addressCollection;
    }

    public getEmploymentCollection (): EmploymentCollection {
        return this.employmentCollection;
    }

    public setCountryID (id: number): void
    {
        this.countryID = id;
    }

    getName (): string {
        let arr = [];

        if(this.firstName) {
            arr.push(this.firstName);
        }

        if(this.middleName) {
            arr.push(this.middleName);
        }

        if(this.lastName) {
            arr.push(this.lastName);
        }

        return arr.join(" ");
    }

    setName (first: string, middle: string, last: string): void {
        if(first) {
            this.firstName = first;
        }

        if(middle) {
            this.middleName = middle;
        }

        if(last) {
            this.lastName = last;
        }
    }

    getDOB () {
        return this.dob;
    }

}
