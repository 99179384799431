
import Vue from 'vue';

import MixinEvent from "@coreMixin/store/MasterProposal/Mp.mixin";

import Grouped from "./List/Grouped.vue";
import Filters from "./Filter/Filter.vue";

export default Vue.extend({

    mixins: [ MixinEvent ],

    components: {
        Filters, Grouped
    },

    computed: {

        paginatedData ()
        {
            return this.mixinEventGetPaginationData();
        },

        eventList ()
        {
            return this.paginatedData ? this.paginatedData.data: [];
        },

        nextRunDateTime ()
        {
            return this.mixinEventGetNextRunTime();
        }

    },

    methods: {

        eventPageChangeEvent (nextPage: number)
        {
            this.mixinEventUpdateFilter("page", nextPage);
            this.emitRefresh();
        },

        emitRead (obj)
        {
            this.$emit("readEvents", obj);
        },

        emitRefresh ()
        {
            this.$emit("refresh");
        }

    }

});
