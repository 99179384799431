
import Service from "../Service";

export default class AppService extends Service
{

    protected serviceName = "APP Service";
    protected servicePublic = true;

   /**
    * Get the data for the application
    */
    public async getApplicationData ()
    {
        return this.get("/app");
    }

}
