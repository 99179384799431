










































import Vue from 'vue';

import MixinUser from "@coreMixin/store/User/User.mixin";

export default Vue.extend({

    mixins: [ MixinUser ],

    computed: {

        isAdmin () {
            return this.storeUserGetAdmin();
        }

    },

    data () {
       return {
            menu: {
                user: false
            }
        };
    }

});
