





















import Vue from 'vue';

import UserStoreMixin from "@coreMixin/store/User/User.mixin";

import List from "./List.vue";

export default Vue.extend({

    mixins: [ UserStoreMixin ],

    components: {
        List
    },

    computed: {

        userEntity () {
            return this.storeUserGetSessionEntity();
        },

        getUsersName () {
            return this.userEntity ? this.userEntity.getName(): "Loading...";
        }

    },

    data () {
        return {
            menu: false
        };
    }

});
