
import Service from "../Service";

export default class UserService extends Service
{

    protected serviceName = "My User Service";
    protected servicePublic = false;

    public async changePassword (data) {
        return this.post("/password", data);
    }

    //update users settings
    public async updateUserSettings (settingObj: any) {
        return this.post("/setting", settingObj);
    }

    public async activateSupplier (id)
    {
        return this.post(`/supplier/${id}`);
    }
}
