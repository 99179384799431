
import VueRouter from 'vue-router';

export default class AuthGuard
{
    private vueRouter: VueRouter = null;
    private authKeyGetter: () => {};

    public  authKey: string = null;


    constructor (vueRouter: VueRouter)
    {
        this.vueRouter = vueRouter;
    }


    /**
     * Bind our authing process to each route change
     */
    public bindRouter ()
    {
        let me = this;

        this.vueRouter.beforeEach(function (to, from, next) {
            me.authRoute(to, from, next);
        });
    }

    /**
     * authRoute
     */
    public async authRoute (to, from, next)
    {
        const publicRoute: boolean = to.meta.public ? to.meta.public: false;

        if(publicRoute === true) {
            next();
            return;
        }

        //lets check our auth key
        if(!this.checkAuth()) {
            next({ path: "/login" })
            return;
        }

        //were okay
        next();
    }

    public setAuthKeyGetter (getter)
    {
        this.authKeyGetter = getter;
    }

    public checkAuth (): boolean
    {
        const authKey = this.authKeyGetter();

        if(!authKey) {
            return false;
        }

        return true;
    }

    private async routerPushLogin()
    {
        try {
            await this.vueRouter.push("/login")
        }
        catch (error) {
            console.log(error);

        }
    }
}