
    import Vue from "vue";

    import StoreMap from "@/core/store/store.map";

    import MasterProposalEntity from '@coreEntity/master-proposal/MasterProposal.entity';

    export default Vue.extend({

        methods: {

            /**
             *
             * Getters
             *
             */
            
            storeProposalGetActiveMpEntity () {
                return this.$store.getters[`${StoreMap.app_proposal_store}applicationStoreGetMasterProposalEntity`];
            },

            storeProposalGetStateMap () {
                return this.$store.getters[`${StoreMap.app_proposal_store}applicationStoreGetProposalStateMap`];
            },

            storeProposalGetUploadSubTypes () {
                return this.$store.getters[`${StoreMap.app_proposal_store}applicationStoreGetProposalSubTypes`];
            },

            storeProposalGetStatusSubTypes (statusID: number) {
                return this.$store.getters[`${StoreMap.app_proposal_store}applicationStoreGetProposalStateSubStates`](statusID);
            },

            /**
             *
             * Mutations
             *
             */

            storeProposalUpdateMpEntity (mpEntity: MasterProposalEntity) {
                this.$store.commit(`${StoreMap.app_proposal_store}applicationStoreUpdateMpEntity`, mpEntity);
            },

            storeProposalDestroyMpEntity () {
                this.$store.commit(`${StoreMap.app_proposal_store}applicationStoreUpdateMpEntity`, null);
            },

            storeProposalUpdateStateMap (arrData) {
                this.$store.commit(`${StoreMap.app_proposal_store}applicationStoreUpdateStateMap`, arrData);
            },

            storeProposalUpdateUploadSubTypes (arrData) {
                this.$store.commit(`${StoreMap.app_proposal_store}applicationStoreUpdateSubType`, arrData);
            }

        }

    });