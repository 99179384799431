
    import Vue from "vue";

    export default Vue.extend({

        computed: {

            _isAuthed () {
                return this.$store.getters["AuthStore/isAuthed"];
            },

            _authKey () {
                return this.$store.getters["AuthStore/authKey"];
            }

        },

        methods: {

            setBaseUrl (url) {
                this.$store.commit("AuthStore/authStoreSetBaseUrl", url);
            },

            currentRoutePublic () {
                const  currentRoute = this.$router.currentRoute;

                return currentRoute.meta.public ? currentRoute.meta.public: false;
            },

            setAuthTokenFromStorage () {
                this.$store.dispatch("AuthStore/setTokenFromStorage");
            },

            setAuthToken (token) {
                this.$store.dispatch("AuthStore/authStoreAuthedSuccessful", token);
            },

            deleteAuth () {
                this.$store.dispatch("AuthStore/authStoreDeleteAuth");
            }
        }

    });