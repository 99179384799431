



































































































































































//service
import MasterProposalService from "@coreService/master-proposal/Index.service";

import MixinConstant from "@coreMixin/store/Constant.mixin";

export default {

    mixins: [ MixinConstant ],

    props: {
        masterProposalID: {
            type: [Number, null]
        },

        dbEsignID: {
            type: Object
        },

        dbEsignDocs: {
            type: Array
        },

        dbEsignSignatories: {
            type: Array
        },

        dbEsignQuestionnaires: {
            type: Array
        },

        canExecute: {
            type: Boolean
        },

        proposalExpired: {
            type: Boolean,
            default: false
        }

    },

    mounted () {
        this.masterProposalService = new MasterProposalService(this);
    },

    computed: {

        edocStatusStore () {
            return this.storeConstantGetList("edoc_status");
        }

    },

    methods: {

        copyEsignLink (index) {
            (<HTMLInputElement[]><any>document.getElementById("esign-link-" + index))[0].select();
            document.execCommand("copy");
        },

        getStatusText (esignStatusID) {
            let edocStatusConstant = this.getEdocStatusConstant(esignStatusID);

            if(edocStatusConstant === null) {
                return "Not Found";
            }

            return edocStatusConstant.text;
        },

        getEdocStatusConstant (esignStatusID) {
            for (let index = 0; index < this.edocStatusStore.length; index++) {
                const element = this.edocStatusStore[index];

                if(element.id == esignStatusID) {
                    return element;
                }
            }

            return null;
        },

        downloadEdoc () 
        {
            let me = this;
            this.view_id_collection = [];

            this.getEdocByID(this.dbEsignID.proposal_master_edoc_id).then(res => {
                if (!res) {
                    console.log(res);
                    return;
                }
                else if (!res["success"] && res["errors"].length > 0) {
                    for (var error of res["errors"]) {
                        me.$toasted.error(error);
                    }
                    console.log(res["errors"]);
                    return;
                }

                this.view_id_collection.push();

                const byteCharacters = atob(res["data"].document_base64);
                const byteArray = new Uint8Array(byteCharacters.length);

                for (let i = 0; i < byteCharacters.length; i++) {
                    byteArray[i] = byteCharacters.charCodeAt(i);
                }           

                // Create a Blob from the Uint8Array
                const blob = new Blob([byteArray]);            

                // Create a download link
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = res["data"].document_name;          

                // Simulate a click on the link to trigger the download
                downloadLink.click();           

                // Clean up
                URL.revokeObjectURL(downloadLink.href);
                
                //tell the user 
                me.$toasted.success('Your esign agreement has been downloaded.');
            })
            .catch(err => {
                console.log(err);
                me.$toasted.error("Failed to get document data");
            });
        },

        getEdocByID (edocID) {
            const mpService = new MasterProposalService(this);
            return mpService.getEsignDocument(this.masterProposalID, edocID);
        },

        getSignatoryNameByID (signatoryID) {
            let signatoryIndex = this.dbEsignSignatories.findIndex(sig => sig.signatory_id === signatoryID);
            return this.dbEsignSignatories[signatoryIndex].applicant_first_name
                + " " + this.dbEsignSignatories[signatoryIndex].applicant_last_name
        }
    }

};

