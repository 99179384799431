



























import Vue from 'vue';

import EntityProposalQuote from "@coreEntity/master-proposal/proposal/quote/ProposalQuote.entity";

import QuoteDisplay from "./Quote.vue";
import VehicleDisplay from "@coreComponent/vehicle/id/VehicleDisplay.vue";

export default Vue.extend({

    components: {
        QuoteDisplay, VehicleDisplay
    },

    props: {

        entityProposalQuote: {
            type: EntityProposalQuote
        }

    },

    computed: {

        hasVehicle () 
        {
            return this.entityProposalQuote.hasVehicleEntity();
        },

        createdUserText () 
        {
            return this.entityProposalQuote.hasUserEntity() ? this.entityProposalQuote.getUserText(): "API";
        },

        quoteCol () 
        {
            return this.hasVehicle === true ? "8": "12";
        }


    }

});
