
















































































































import Vue from 'vue';

import ConstantMixin from "@coreMixin/store/Constant.mixin";
import MixinQuote from "@coreMixin/store/ApiQuote.mixin";

import MasterProposalEntity from "@coreEntity/master-proposal/MasterProposal.entity";
import ProposalEntity from "@coreEntity/master-proposal/proposal/Proposal.entity";
import ApplicantCollection from "@coreEntity/master-proposal/proposal/Applicant.collection";

import NoteTable from "./component/NoteTable.vue";

export default Vue.extend({

    mixins: [ ConstantMixin, MixinQuote ],

    components: {
        NoteTable
    },

    props: {

        mpEntity: {
            type: MasterProposalEntity
        },

        noteCollectionEntity: {

        }

    },

    computed: {

        proposalEntity (): ProposalEntity {
            return this.mpEntity.getProposalEntity();
        },

        proposalAppCollection (): ApplicantCollection {
            return this.proposalEntity.getAppCollection().getItems();
        },

        statusText (): string {
            const obj = this.storeConstantGetListItemID("proposal_status", this.proposalEntity.getStatusID());

            return obj ? obj.text: "No Status";
        },

        subStatusText (): string {
            const obj = this.storeConstantGetListItemID("proposal_sub_status", this.proposalEntity.getSubStatusID());

            return obj ? obj.text: "No Sub Status";
        },

        bookingFunderList () {
            return [].concat(this.storeSuppliersGetOutFunders(), [{funder_id: null, funder_name: "No Booking Funder Selected"}]);
        },

        funderList () {
            return [].concat(this.storeSuppliersGetInFunders(), [{funder_id: null, funder_name: "No Funder Selected"}]);
        },

        brokerList () {
            return [].concat(this.storeSuppliersGetBrokers(), [{broker_id: null, broker_name: "No Broker Selected"}]);
        },

        dealerList () {
            return [].concat(this.storeSuppliersGetDealers(), [{dealer_id: null, dealer_name: "No Dealer Selected"}]);
        },

        getDaysUntilDeleted () {
            const deletedAt = this.mpEntity.getDeletedAt();

            if(!deletedAt) {
                return null;
            }

            const momentDeletedAt = this.$moment(deletedAt),
                today = this.$moment();

            return momentDeletedAt.diff(today, "days");
        }

    },

    data () {
        return {
            show: {
                supplier_summary: true,
                dealer: false,
                broker: false,
                funder: false
            }
        };
    }

});
