
import EntityCollection from "npm-marsh-core/src/entity/EntityCollection";

import { DealerFieldEntity } from "./DealerFields.entity";
import { DealerFieldEnum, makeDealerFieldEnum } from "./lib/DealerField.enum";
import { DatabaseInterface, FindEnumCallable, findEnumMap } from "./lib/DealerFieldMaps";

export class DealerFieldsCollection extends EntityCollection
{

    protected findEnumConfig: FindEnumCallable|null = null;

    constructor(enumMap: FindEnumCallable|null = findEnumMap)
    {
        super();

        this.findEnumConfig = enumMap;
    }

    public getNewItem(): DealerFieldEntity
    {
        return new DealerFieldEntity();
    }

    /**
     * Find an entity by enum
     * @param enumVal 
     * @returns 
     */
    public findEntity(enumVal: DealerFieldEnum): DealerFieldEnum|null
    {
        return this.getItems().find((ele: DealerFieldEntity) => {
            return ele.getType() === enumVal;
        });
    }

    /**
     * Add a new entity by type
     * @param type 
     * @param value 
     */
    public addEntity(type: DealerFieldEnum, value: any)
    {
        const entity = this.getNewItem();
        entity.setType(type);
        entity.setValue(value);

        this.addItem(entity);
    }

    /**
     * Delete an entity by enum
     * @param enumVal 
     */
    public deleteByType(enumVal: DealerFieldEnum)
    {
        const index = this.getItems().findIndex((entity: DealerFieldEntity) => {
            return entity.getType() === enumVal;
        });

        if(index === -1) {
            return;
        }

        this.deleteItemIndex(index);
    }

    public buildItemsFromDatabase(dbItems: Array<DatabaseInterface>)
    {
        if(!dbItems.length) {
            return;
        }

        dbItems.forEach(element => {            
            this.buildFromDatabase(element);
        });
    }

    public buildFromDatabase(obj: DatabaseInterface)
    {
        const type = obj.field_type;
        const dealerEnum = makeDealerFieldEnum(type);

        if(!dealerEnum) {
            return;
        }

        const dealerConfig = this.findEnumConfig(dealerEnum);

        if(!dealerConfig) {
            return;
        }

        const entity = this.getNewItem();
        this.addItem(entity);

        entity.title = dealerConfig.title;
        entity.type = dealerEnum;
        entity.value = obj.value

        return entity;
    }

}