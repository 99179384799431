
import Vue from 'vue';

import MixinUser from "@coreMixin/store/User/User.mixin";

export default Vue.extend({

    mixins: [ MixinUser ],

    props: {

        tier: {
            type: Number
        },

        product: {
            type: String
        }

    },

    computed: {

        terms () {
            return this.getActiveSupplierTermGetTier(this.tier);
        }

    }

});
