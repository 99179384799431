<template>

    <AppPill :class="`component-proposal-status-id ${btnColour}`">{{proposalStatusText}}</AppPill>

</template>

<script>

    import ConstantMixin from "@coreMixin/store/Constant.mixin";

    export default {

        mixins: [ ConstantMixin ],

        props: {
            id: {
                type: [String, Number],
                default: null
            }
        },

        computed: {
            statusObj () {
                return this.storeConstantGetListItemID("proposal_status", this.id);
            },

            proposalStatusText () {
                return this.statusObj ? this.statusObj.text: "Status Not Found";
            },

            btnColour () {
                return this.btnMap[this.id] ? this.btnMap[this.id]: this.btnMap.default;
            }
        },

        data () {
            return {
                btnMap: {
                    default: "colour-default",
                    1: "colour-error",
                    3: "colour-warning",
                    4: "colour-warning",
                    5: "colour-success",
                    10: "colour-success",
                    11: "colour-success",
                    12: "colour-warning",
                    13: "colour-warning",
                }
            };
        }
    };

</script>
<style lang="scss">

    .component-proposal-status-id {

        &.component-app-pill.component-app-pill {

            &.colour-default {
                background-color: var(--v-info-base);
            }

            &.colour-success {
                background-color: var(--v-success-base);
            }

            &.colour-warning {
                background-color: var(--v-warning-base);
            }

            &.colour-error {
                background-color: var(--v-error-base);
            }

        }

    }

</style>