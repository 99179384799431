import Entity from "npm-marsh-core/src/entity/Entity";

export default class EntityChecklistType extends Entity {

    protected id: number|null = null;
    protected text: string|null = null;
    protected constantText: string|null = null;

    protected mapDbProperties = {
        "checklist_type_id": "id",
        "checklist_type_text": "text",
        "checklist_type_constant": "constantText",
    };

    public getID () {
        return this.id;
    }

    public getText () {
        return this.text;
    }

}