<script>

    import Vue from 'vue';

    import ApplicationService from "@coreService/app/App.service";
    import EventBus from "@core/event-bus/EventBus";

    import AppUserEntity from "./entity/user/AppUserEntity";
    import MixinAll from "@coreMixin/store/Index.mixin";
    import AuthMixin from "@coreMixin/auth/Auth.mixin.js";
    
    import Header from "./components/header/Index";

    export default {

        mixins: [ MixinAll, AuthMixin ],

        components: {
            Header
        },

        computed: {

            appReady () {
                return this.userEntity ? true: false;
            },

            userEntity () {
                return this.storeUserGetSessionEntity();
            }
        },

        mounted () {
            this.init();
        },


        data () {
            return {
                ready: false
            };
        },

        methods: {

            /**
             * Init method
             */
            async init () {
                const me = this;

                await this.getApplicationData();
                this.ready = true;

                //bind to the supplier change, then we can refresh our data to put everything in sync across the app.
                EventBus.onUserSupplierGroupChanged(function(params) {
                    me.getApplicationData();
                });
            },

            /**
             * Every interval run this method
             */
            async getApplicationData() 
            {
                const applicationService = new ApplicationService(this);
                let httpRes = await applicationService.getApplicationData();

                if(httpRes.hasErrors() === true) {
                    this.deleteAuth();
                    this.$router.push({name: 'login'});
                    return;
                }

                this.updateAppStores(httpRes.getData());
            },

            /**
             * Update the store data
             */
            updateAppStores (appSetupData) {
                this.userSetup(appSetupData.user);
                this.storeConstantCommitUpdate(appSetupData.constant_collection);
                this.storeChecklistSetTypes(appSetupData.checklist.list_types, appSetupData.checklist.item_types);
                this.mixinEventSetAppEventTypes(appSetupData.event_types);
                this.storeUserActionUpdateActiveSupplierData(appSetupData.active_supplier_entity);

                //update suppliers
                this.storeSuppliersSetSupplierCollection({
                    funder_collection: appSetupData.quote_supplier_collection.funders ? appSetupData.quote_supplier_collection.funders: [],
                    broker_collection: appSetupData.quote_supplier_collection.brokers ? appSetupData.quote_supplier_collection.brokers: [],
                    dealer_collection: appSetupData.quote_supplier_collection.dealers ? appSetupData.quote_supplier_collection.dealers: []
                });
            },

            userSetup (userData) {
                const userEntity = new AppUserEntity(this);
                userEntity.mapApplyDatabase(userData);
                userEntity.applicationSetup();

                //shit but works for now
                Vue.prototype.$$UserEntity = userEntity;

                //set the user entity in the store
                this.storeUserActionUpdateUser(userEntity);
            }

        }

    }

</script>
<template>

    <div id="secure-application">
        <Header v-if="ready" />

        <div v-if="ready" id="secure-application-body">
            <div v-if="appReady === true" id="secure-application-view">
                <router-view :key="$route.fullPath" />
            </div>
        </div>
    </div>

</template>
<style lang="scss" scoped>

    #secure-application {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        flex: 1 0 100%;
        max-height: 100%;
        height: 100%;

        #secure-application-body {
            display: flex;
            flex-grow: 1;
            flex: 1 0 100%;
            max-height: calc(100% - 3rem);
            height: calc(100% - 3rem);

            #secure-application-view {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                height: 100%;
                max-height: 100%;
                overflow-y: auto;
                padding: 1rem;

                > * {
                    height: 100%;
                    max-height: 100%;
                }
            }
        }
    }

</style>