
import EntityCollection from "npm-marsh-core/src/entity/EntityCollection";

import EntityMpUpload from "./MpUpload.entity";

export default class EntityCollectionMPVehicle extends EntityCollection {

    public getNewItem (): EntityMpUpload {
        return new EntityMpUpload();
    }

}