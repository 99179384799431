
import Regioner from "./Region.vue";
import ImageLogo from "./Image.vue";

export default Regioner.extend({

    components: {
        ImageLogo
    },

    computed: {

        image () {
            return process.env.VUE_APP_REGION && process.env.VUE_APP_REGION === 'UK' ? 'UK': "LMO";
        },

    }
});

