

import MasterProposalEntity from "@/core/entity/master-proposal/MasterProposal.entity";
import { DealerFieldEntity } from "@/core/entity/master-proposal/dealer-fields/DealerFields.entity";

import { getEnumMap } from "@coreEntity/master-proposal/dealer-fields/lib/DealerFieldMaps";

export default {

    props: {

        mpEntity: {
            type: MasterProposalEntity
        },

    },

    computed: {

        dealerFieldsCollection()
        {
            return this.mpEntity.getDealerFieldsCollection();
        }

    },

    data() {
        return {
            enumMap: getEnumMap(),
            fieldsList: []
        }
    },

    mounted()
    {
        this.buildFieldsList();
    },
    
    methods: {

        updateMpEntity(fieldConfig)
        {   
            //if the value is empty, remove it from the collection. 
            if(!fieldConfig.value) {
                this.removeTypeFromCollection(fieldConfig);
                return;
            }

            this.updateTypeInCollection(fieldConfig);
        },

        removeTypeFromCollection(fieldConfig)
        {
            const dealerFieldEntity = this.dealerFieldsCollection.findEntity(fieldConfig.field_enum);

            //odd... doesn't exist
            if(!dealerFieldEntity) {
                return;
            }

            this.dealerFieldsCollection.deleteByType(fieldConfig.field_enum)
        },

        updateTypeInCollection(fieldConfig)
        {
            const dealerFieldEntity = this.dealerFieldsCollection.findEntity(fieldConfig.field_enum);

            //update the field.
            if(dealerFieldEntity) {
                dealerFieldEntity.setValue(fieldConfig.value);
                return;
            }

            //the field doesn't exist
            this.dealerFieldsCollection.addEntity(fieldConfig.field_enum, fieldConfig.value);
        },

        buildFieldsList()
        {
            const allFields = this.getAllFields();
            const mpFields = this.dealerFieldsCollection;

            if(!mpFields.hasItems()) {
                this.fieldsList = allFields;
                return;
            }

            mpFields.forEach((dealerFieldEntity: DealerFieldEntity) => {
                const fieldEnum = dealerFieldEntity.getType();

                const found = allFields.find((ele) => {
                    return ele.field_enum === fieldEnum;
                });

                if(found) {
                    found.value = dealerFieldEntity.value;
                }
            });

            this.fieldsList = allFields;
        },

        getAllFields()
        {
            const emunMap = getEnumMap();
            const fields = [];            

            for (const fieldEnum in emunMap) {
                if (!Object.prototype.hasOwnProperty.call(emunMap, fieldEnum)) {
                    continue;
                }

                const element = emunMap[fieldEnum];
                
                fields.push({
                    field_enum: fieldEnum,
                    title: element.title,
                    description: element.description,
                    value: null
                });
            }

            return fields;
        }

    }

}

