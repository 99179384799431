<template>

    <AppPill class="component-proposal-status-sub-id">{{proposalStatusText}}</AppPill>

</template>

<script>

    import ConstantMixin from "@coreMixin/store/Constant.mixin";

    export default {

        mixins: [ ConstantMixin ],

        props: {
            id: {
                type: [String, Number],
                default: null
            }
        },

        computed: {
            statusObj () {
                return this.storeConstantGetListItemID("proposal_sub_status", this.id);
            },

            proposalStatusText () {
                return this.statusObj ? this.statusObj.text: "Status Not Found";
            }
        }
    };

</script>
<style lang="scss">

    .component-proposal-status-sub-id {

        &.component-app-pill.component-app-pill {
            background-color: #234982;
        }

    }

</style>