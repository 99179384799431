<script>

    import AuthMixin from "@coreMixin/auth/Auth.mixin.js"

    export default {

        mixins: [ AuthMixin ],

        created () {
            this.deleteAuth();
            this.$router.push({name: "login"});
        }

    }

</script>
<template>

    <div>logging out....</div>

</template>