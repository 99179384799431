
import Vue from 'vue';

export default Vue.extend({

    props: {

        mpEntity: {

        },

        brokerList: {

        },

        dealerList: {

        }

    }
});
