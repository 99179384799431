import Entity from "npm-marsh-core/src/entity/Entity";

export default class EntityChecklistItemGroup extends Entity {

    protected id: number|null = null;
    protected name: string|null = null;
    protected desc: string|null = null;

    protected mapDbProperties = {
        "checklist_group_id": "id",
        "checklist_group_name": "name",
        "checklist_group_description": "desc",
    };

    public getID () {
        return this.id;
    }

    public setID (val: number) {
        return this.id = val;
    }

}