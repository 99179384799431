
import Entity from "npm-marsh-core/src/entity/Entity";

import AddressEntity from "./address/Address.entity";

export default class ApplicantAddressEntity extends Entity {

    public id: number|null = null;
    public appID: number|null = null;
    public addressID: number|null = null;

    public residenceTypeID: number|null = null;
    public addressTypeID: number|null = null;
    public startDate: string|null = null;
    public monthAt: number|null = null;
    public createdAt: string|null = null;

    public addressEntity: AddressEntity;

    protected mapDbProperties = {
        "applicant_address_id": "id",
        "applicant_address_applicant_id": "appID",
        "applicant_address_address_id": "addressID",
        "applicant_address_type_id": "addressTypeID",
        "applicant_address_residence_type_id": "residenceTypeID",
        "applicant_address_start_date": "startDate",
        "applicant_address_month_at": "monthAt",
        "applicant_address_created_at": "createdAt",
        "applicant_address_address_entity": {
            entity_getter: "addressEntity",
            entity_setter: "addressEntity",
        },
    };

    constructor (addressEntity: AddressEntity|null = null) {
        super();

        this.addressEntity = addressEntity ? addressEntity: new AddressEntity();
    }

    getAddressEntity (): AddressEntity {
        return this.addressEntity;
    }

}
