



























import Vue from 'vue';

import AppEmploymentCollection from "@coreEntity/master-proposal/proposal/applicant/AppicantEmployment.collection";

import EmploymentForm from "./Form.vue";

export default Vue.extend({

    components: {
        EmploymentForm
    },

    props: {

        collectionEntity: {
            type: AppEmploymentCollection
        },

        disabled: {
            type: Boolean,
            default: false
        },

        minItems: {
            type: Number,
            default: 1
        },

        maxItems: {
            type: Number,
            default: 3
        }

    },

    computed: {

        collectionItems () {
            return this.collectionEntity.getItems();
        },

        canAddEmployment () {
            return this.collectionEntity.getItemCount() < this.maxItems;
        },

        canDeleteEmployment () {
            return this.collectionEntity.getItemCount() > 1;
        }

    },

    methods: {

        addNewItem () {
            if(this.collectionEntity.getItemCount() >= this.maxItems) {
                this.$toasted.error(`You cannot have more then ${this.maxItems} employment record`);
                return;
            }

            this.collectionEntity.addNewItem();
        },

        deleteItem (itemIndex) {
            if(this.collectionEntity.getItemCount() <= this.minItems) {
                this.$toasted.error(`You need atleast ${this.minItems} employment records`);
                return;
            }

            this.collectionEntity.deleteItemIndex(itemIndex);
        }

    }


});
