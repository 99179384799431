import Entity from "npm-marsh-core/src/entity/Entity";

export default class EntityVehicle extends Entity {

    protected id: number|null = null;
    protected capCode: string|null = null;
    protected vin: string|null = null;
    protected reg: string|null = null;
    protected regDate: string|null = null;
    protected make: string|null = null;
    protected model: string|null = null;
    protected trim: string|null = null;
    protected desc: string|null = null;
    protected mileage: number|string|null = null;
    protected mileageUnit: string = "MILES";
    protected engine: string|null = null;
    protected engineNumber: string|null = null;
    protected colour: string|null = null;
    protected fuelID: number|null = null;
    protected transmID: number|null = null;
    protected gears: number|null = null;
    protected doors: number|null = null;
    protected cashPrice: number|null = null;
    protected cashPriceGGR: number|null = null;
    protected ggAdjustedPrice: number|null = null;
    protected priceExcellent: number|null = null;
    protected priceGood: number|null = null;
    protected priceAvg: number|null = null;
    protected tradePriceExcellent: number|null = null;
    protected tradePriceGood: number|null = null;
    protected tradePriceAvg: number|null = null;
    protected imported: number = 0;
    protected exported: number = 0;
    protected scrapped: number = 0;
    protected createdAt: string|null = null;

    protected mapDbProperties = {
        "vehicle_id": "id",
        "vehicle_cap_code": "capCode",
        "vehicle_vin": "vin",
        "vehicle_reg": "reg",
        "vehicle_reg_date": "regDate",
        "vehicle_make": "make",
        "vehicle_model": "model",
        "vehicle_trim": "trim",
        "vehicle_description": "desc",
        "vehicle_colour": "colour",
        "vehicle_fuel_id": "fuelID",
        "vehicle_transmission_id": "transmID",
        "vehicle_gears": "gears",
        "vehicle_doors": "doors",
        "vehicle_cashprice": "cashPrice",
        "vehicle_cashprice_ggr": "cashPriceGGR",
        "vehicle_gg_adjusted_price": "ggAdjustedPrice",
        "vehicle_price_excellent": "priceExcellent",
        "vehicle_price_good": "priceGood",
        "vehicle_price_avg": "priceAvg",
        "vehicle_tradein_price_excellent": "tradePriceExcellent",
        "vehicle_tradein_price_good": "tradePriceGood",
        "vehicle_tradein_price_avg": "tradePriceAvg",
        "vehicle_mileage": "mileage",
        "vehicle_mileage_unit": "mileageUnit",
        "vehicle_engine": "engine",
        "vehicle_engine_num": "engineNumber",
        "vehicle_imported": "imported",
        "vehicle_exported": "exported",
        "vehicle_scrapped": "scrapped",
        "vehicle_created_at": "createdAt"
    };

    /**
     *
     * Methods
     *
     */

    //is the vehicle filled in?
    public isEmpty (): boolean {
        return !this.capCode && !this.reg && !this.make ? true: false;
    }

    //get the text to describe the vehicle
    public getDescriptiveText (includeMileage: boolean = true): string {
        let arr = [];

        if(this.reg) {
            arr.push(this.reg);
        }

        if(this.make) {
            arr.push(this.make);
        }

        if(this.model) {
            arr.push(this.model);
        }

        if(this.desc) {
            arr.push(this.desc);
        }

        if(this.trim) {
            arr.push(this.trim);
        }

        if(this.mileage && includeMileage) {
            arr.push(`${this.mileage} ${this.mileageUnit}`)
        }

        return arr.join(", ");
    }

    public getShortDecription (): string
    {
        const   make = this.getMake(),
                iden = this.getReg() ? this.getReg(): this.getCapCode();

        return `${iden} ${make}`;
    }

    public getCapCode () {
        return this.capCode;
    }

    public getReg () {
        return this.reg;
    }

    public getRegDate () {
        return this.regDate;
    }

    public getCashPrice () {
        return this.cashPrice;
    }

    public getMileage () {
        return this.mileage;
    }

    public getMileageUnit ()
    {
        return this.mileageUnit;
    }

    public getMake() {
       return this.make;
    }

    public getColour () {
        return this.colour;
    }

    public getDoors () {
        return this.doors;
    }

    public getCashPriceGrr () {
        return this.cashPriceGGR;
    }

    public getGGAdjustedPrice () {
        return this.ggAdjustedPrice;
    }

    public getPriceExcellent () {
        return this.priceExcellent;
    }

    public getPriceGood ()
    {
        return this.priceGood;
    }

    public getPriceAvg ()
    {
        return this.priceAvg;
    }

    public getTradePriceExcellent () {
        return this.tradePriceExcellent;
    }

    public getTradePriceGood ()
    {
        return this.tradePriceGood;
    }

    public getTradePriceAvg ()
    {
        return this.tradePriceAvg;
    }

    public getImported () {
        return this.imported;
    }

    public getExported () {
        return this.exported;
    }

    public getScrapped () {
        return this.scrapped;
    }

    public getEngineNumber () {
        return this.engineNumber;
    }

}
