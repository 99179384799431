
import Vue from 'vue';

//entities
import EntityMpChecklist from "@coreEntity/master-proposal/checklist/MasterProposalChecklist.entity";

export default Vue.extend({
    props: {

        mpChecklistEntity: {
            type: EntityMpChecklist
        },

        opts: {
            type: Object
        },

        compact: {
            type: Boolean,
            default: false
        }

    },

    computed: {

        classList () {
            let arr = ["checklist-options-bar"];

            if(this.compact) {
                arr.push("cob--compact");
            }

            return arr.join(" ");
        },

        checklistEntity () {
            return this.mpChecklistEntity.getChecklistEntity();
        },

        typeEntity () {
            return this.checklistEntity.getTypeEntity();
        },

        isSupplierVisible () {
            return this.mpChecklistEntity.getSupplierVisible();
        },

        description () {
            return this.checklistEntity.getDesc();
        },

        listItemFilter () {
            return [{text: "All", value: null}, {text: "Only Checked", value: 1}, {text: "Only unchecked", value: 0}];
        }

    }

});
