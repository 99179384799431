
import EntityCollection from "npm-marsh-core/src/entity/EntityCollection";

import EntityMpVehicle from "./Vehicle.entity";

export default class EntityCollectionMPVehicle extends EntityCollection {

    public getNewItem (): EntityMpVehicle {
        return new EntityMpVehicle();
    }

}