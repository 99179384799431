import Entity from "npm-marsh-core/src/entity/Entity";

import EntityGroupItemCollection from "./item/GroupItem.collection";

import CollectionItemCollection from "../item/Item.collection";

export default class EntityChecklistGroup extends Entity {

    public  id: number|null = null;
    public  name: string|null = "";
    public  desc: string = "";
    public  createdAt: string|null = null;

    protected entityItemCollection: EntityGroupItemCollection;

    protected mapDbProperties = {
        "checklist_group_id": "id",
        "checklist_group_name": "name",
        "checklist_group_description": "desc",
        "checklist_group_created_at": "createdAt",
        "checklist_group_item_collection": {
            entity_getter: "entityItemCollection",
            entity_setter: "entityItemCollection"
        }
    };

    constructor () {
        super();

        this.entityItemCollection = new EntityGroupItemCollection();
    }

    /**
     *
     * Methods
     *
     */

    convertToItemCollection (): CollectionItemCollection {
        return this.entityItemCollection.convertToItemCollection();
    }

    /**
     *
     * Getters
     *
     */

    public getID () {
        return this.id;
    }

    public getItemCollection () {
        return this.entityItemCollection;
    }

    public getName () {
        return this.name;
    }

    public getDesc () {
        return this.desc;
    }

}
