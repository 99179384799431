<script>

    import ApiQuoteMixin from "@coreMixin/store/ApiQuote.mixin";

    export default {

        mixins: [ ApiQuoteMixin ],

        props: {
            id: {
                type: [String, Number],
                default: null
            },

            outFunder: {
                type: Boolean,
                default: false
            }
        },

        computed: {

            funderObj() 
            {
                return this.storeSuppliersGetFunder(this.id);
            },

            funderName() 
            {
                if(!this.funderObj) {
                    return 'Funder Not Found';
                }

                if(this.funderObj.funder_display_name) {
                    return this.funderObj.funder_display_name;
                }

                return this.funderObj.funder_name;
            }

        }
    };

</script>
<template>

    <AppPill class="component-supplier-funder">{{funderName}}</AppPill>

</template>

<style lang="scss">

    .component-supplier-dealer {

        &.component-app-pill.component-app-pill {
            background-color: #a02727;;
        }

    }

</style>