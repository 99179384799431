//vue imports
import Vue from 'vue';
import App from './App.vue';
import router from './core/router';
import store from './core/store';

//package imports
import vuetify from './core/plugin/vuetify/Index';

//app imports
import Install from "@core/install";
import AuthGuard from "@/core/router/guard/Auth.guard";

Install(Vue, { store });

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App),

    mounted () {
        this.bindRouter();
    },

    methods: {

        bindRouter () {
            this.$$routeAuther = new AuthGuard(this.$router);
        }

    }

})
.$mount('#app');
