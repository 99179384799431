
import Entity from "npm-marsh-core/src/entity/Entity"

export default class EntityMpWetSign extends Entity {

    public bookingFunderId: number|null = null;
    public dateCreated: string|null = null;
    public dateDeleted: string|null = null;
    public dateUpdated: string|null = null;
    public fileId: number|null = null;
    public id: number|null = null;
    public mpId: number|null = null;
    public proposalId: number|null = null;
    public proposalQuoteId: number|null = null;
    public tier: number|null = null;
    public userId: number|null = null;

    protected mapDbProperties = {
        proposal_master_wet_doc_booking_funder_id: "bookingFunderId",
        proposal_master_wet_doc_date_created: "dateCreated",
        proposal_master_wet_doc_date_deleted: "dateDeleted",
        proposal_master_wet_doc_date_updated: "dateUpdated",
        proposal_master_wet_doc_file_id: "fileId",
        proposal_master_wet_doc_id: "id",
        proposal_master_wet_doc_mp_id: "mpId",
        proposal_master_wet_doc_proposal_id: "proposalId",
        proposal_master_wet_doc_proposal_quote_id: "proposalQuoteId",
        proposal_master_wet_doc_tier: "tier",
        proposal_master_wet_doc_user_id: "userId",
    }

    constructor () {
        super();
    }

    /**
     *
     * Getters
     *
     */


}