
import Vue from 'vue';

import VehicleForm from "@coreComponent/vehicle/id/form/Index.vue";

import EntityMpVehicle from "@coreEntity/master-proposal/vehicle/Vehicle.entity";

export default Vue.extend({

    components: {
        VehicleForm
    },

    props: {

        entityMpVehicle: {
            type: EntityMpVehicle
        },

        disabled: {
            type: Boolean,
            default: false
        }

    }

});
