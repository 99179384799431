
import Entity from "npm-marsh-core/src/entity/Entity";

import UserSupplierMap from "./supplier/UserSupplier.entity";
import UserSupplierCollection from "./supplier/UserSupplier.collection";

export default class EntityUser extends Entity {

    protected   id: number|null = null;
    protected   admin: number|null = null;
    protected   email: string|null;
    protected   firstName: string|null;
    protected   lastName: string|null;
    protected   user2FAType: string|null;
    protected   userCompleted2Fa: number|null;
    protected   userLoginAllowed: number|null;
    protected   userSettings = null;
    protected   activeGroupID: number|null = null;

    protected   activeSupplierMap: UserSupplierMap|null = null;
    protected   supplierMapCollection: UserSupplierCollection|null = null;

    protected mapDbProperties = {
        user_id: "id",
        user_admin: "admin",
        user_email: "email",
        user_first_name: "firstName",
        user_last_name: "lastName",
        user_2fa_type: "user2FAType",
        user_completed_2fa: "userCompleted2Fa",
        user_login_allowed: "userLoginAllowed",
        user_setting_json: "userSettings",
        user_supplier_map_active_id: "activeGroupID",
        user_active_supplier: {
            entity_getter: "activeSupplierMap",
            entity_setter: "activeSupplierMap"
        },
        user_supplier_collection: {
            entity_getter: "supplierMapCollection",
            entity_setter: "supplierMapCollection"
        }
    };

    constructor () {
        super();

        this.activeSupplierMap = new UserSupplierMap();
        this.supplierMapCollection = new UserSupplierCollection();
    }

    //is the user entity filled in?
    public isEmpty (): boolean {
        return !this.id;
    }

    /**
     *
     * Setters
     *
     */

    public setID (val: number) {
        return this.id = val;
    }

    /**
     *
     * Getters
     *
     */

    public getActiveSupplierMap(): UserSupplierMap|null
    {
        return this.activeSupplierMap;
    }

    public getSupplierCollectionMap(): UserSupplierCollection
    {
        return this.supplierMapCollection;
    }

    public getID() 
    {
        return this.id;
    }

    public getAdmin(): number
    {
        return this.admin;
    }

    public getEmail() 
    {
        return this.email;
    }

    public getName(): string 
    {
        return `${this.firstName} ${this.lastName}`;
    }

    public get2FaType()
    {
        return this.user2FAType;
    }

    public getCompleted2Fa()
    {
        return this.userCompleted2Fa;
    }

    public getAllowedToLogin()
    {
        return this.userLoginAllowed;
    }

    public getSettingJson() 
    {
        return this.userSettings;
    }

    public getSupplierID()
    {
        return this.activeGroupID;
    }

}