
    import Vue from "vue";

    import StoreMap from "@/core/store/store.map";

    export default Vue.extend({

        methods: {

            /**
             *
             * Getters
             *
             */

            storeConstantGetList (list: string) {
                return this.$store.getters[`${StoreMap.app_constant_store}consStoreGetConstantObject`]({key: list})
            },

            storeConstantGetListWithEmpty (list: string) {
                return [].concat([{id: null, text: "Please select"}], this.storeConstantGetList(list));
            },

            storeConstantGetListItemID (list: string, itemID: number) {
                return this.$store.getters[`${StoreMap.app_constant_store}consStoreFilterConstantObject`]({constantKey: list, id: itemID});
            },

            storeConstantGetListItemName (list: string, standardName: string) {
                return this.$store.getters[`${StoreMap.app_constant_store}consStoreFindByStandardName`]({constantKey: list, standardized: standardName});
            },

            storeConstantGetListItemText (list: string, itemID: number) {
                const obj = this.storeConstantGetListItemID(list, itemID);

                return obj ? obj.text: "N/A";
            },

            /**
             *
             * "Mutations" Commit
             *
             */

            storeConstantCommitUpdate (constantObj) {
                this.$store.commit(`${StoreMap.app_constant_store}updateConstantStore`, constantObj);
            }

        }

    });