
import Vue from 'vue';

import MixinProposal from "@coreMixin/store/Proposal.mixin";
import MasterProposalEntity from '@coreEntity/master-proposal/MasterProposal.entity';

import MasterProposalService from "@coreService/master-proposal/Index.service";

import PageBody from "./body/Body.vue";

export default Vue.extend({

    components: {
        PageBody
    },

    mixins: [ MixinProposal ],

    props: {
        id: {
            type: [String, Number]
        }
    },

    computed: {

        classList () {
            const arr = ["app-master-proposal-id-basic"];

            if(this.isMpDisabled) {
                arr.push("mp-disabled");
            }

            return arr;
        },

        pageTitle () {
            if(!this.masterPropsalInstance) {
                return "Master Proposal ID";
            }

            const mainAppName = this.masterPropsalInstance.getProposalEntity().getMainApplicant().getName();

            return `${this.id} - ${mainAppName}`;
        },

        isMpDisabled () {
            if(!this.masterPropsalInstance) {
                return false;
            }

            return !this.masterPropsalInstance.canEdit();
        }

    },

    data () {
        return {
            masterPropsalInstance: null,
            modal: {
                dev: false,
                overview: false
            },
            get: {
                errors: [],
                filters: {
                    show_expired_proposals: true,
                    orderBy: (process.env.VUE_APP_MP_ID_ORDER_BY ? process.env.VUE_APP_MP_ID_ORDER_BY: 'asc')
                }
            },
            update: {
                loadingRequest: false,
                errors: {
                    base: [],
                    validation: [],
                    ruleValidation: []
                }
            }
        };
    },

    mounted () {
        this.getMasterProposalData();
    },

    destroyed () {
        this.storeProposalDestroyMpEntity();
    },

    methods: {

        /**
         * Get the mp data
         */
        async getMasterProposalData () {
            const   serv = new MasterProposalService(this),
                    httpRes = await serv.getMasterProposal(this.id, this.get.filters);

            let errors = httpRes.getErrors(),
                data = httpRes.getData(),
                entityErrors = data ? data.errors: [];

            if(errors.length || entityErrors.length) {
                this.$toasted.error("Failed to grab master proposal data");
                this.get.errors = errors.concat(entityErrors);
                return;
            }

            this.masterPropsalInstance = this.factoryMpInstance(data);
            this.storeProposalUpdateMpEntity(this.masterPropsalInstance);
        },

        /**
         * Update the proposal
         */
        async updateProposal () {
            this.update.errors.base = [];
            this.update.errors.validation = [];
            this.update.errors.ruleValidation = [];
            this.update.loadingRequest = true;

            //update service
            const   serv = new MasterProposalService(this),
                    httpRes = await serv.updateMasterProposal(this.masterPropsalInstance);

            this.update.loadingRequest = false;

            let baseErrors = httpRes.getErrors(),
                httpData = httpRes.getData();

            //check base errors
            if(baseErrors.length) {
                this.update.errors.base = httpRes.getErrors();
                return;
            }

            //check proposal update errors
            this.update.errors.validation = httpData.validation,
            this.update.errors.ruleValidation = httpData.rule_validation;

            //no proposal ID update failed
            if(httpData.proposal_id === null) {
                this.$toasted.error("Update failed, see errors");
                return;
            }

            //refresh the data to get the new proposal version
            if(httpData.new_proposal === true) {
                this.$toasted.success("New proposal version has been created, refreshing.");
            }
            else {
                this.$toasted.success("Master Proposal updated");
            }

            //refresh
            this.getMasterProposalData();
        },

        factoryMpInstance (data: any): MasterProposalEntity {
            const en = new MasterProposalEntity();
            en.mapApplyDatabase(data);

            return en;

        },

        getMpJson () {
            return JSON.stringify(this.masterPropsalInstance.mapDatabaseToObject(), null, 2);
        }

    }

});
