import EntityCollection from "npm-marsh-core/src/entity/EntityCollection";

import EntityItem from "./UserSupplier.entity";

export default class UserSupplierCollection extends EntityCollection {

    public getNewItem (): EntityItem {
        return new EntityItem();
    }

}
