
import Entity from "npm-marsh-core/src/entity/Entity";

export default class EntityDocument extends Entity {

    public id: number|null = null;
    public sessionID: number|null = null;
    public documentID: number|null = null;
    public stateID: number|null = null;
    public name: string|null = null;
    public dateCreated: string|null = null;

    protected mapDbProperties = {
        "edoc_document_id": "id",
        "edoc_document_session_id": "sessionID",
        "edoc_document_document_id": "documentID",
        "edoc_document_state_id": "stateID",
        "edoc_document_name": "name",
        "edoc_document_date_created": "dateCreated",
        "edoc_document_date_updated": "dateUpdated",
        "edoc_document_date_deleted": "dateDeleted"
    };

}