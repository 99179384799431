

import Vue from 'vue';

import MasterProposalEntity from "@coreEntity/master-proposal/MasterProposal.entity";
import EntityMpVehicle from "@coreEntity/master-proposal/vehicle/Vehicle.entity";

import MasterProposalService from "@coreService/master-proposal/Index.service";

import VehicleForm from "../Form.vue";

export default Vue.extend({

    components: {
        VehicleForm
    },

    props: {

        mpEntity: {
            type: MasterProposalEntity
        },

        entityMpVehicle: {
            type: EntityMpVehicle
        },

        disabled: {
            type: Boolean,
            default: false
        }

    },

    data () {
        return {
            update : {
                errors: []
            },
            search : {
                errors: []
            },
            deleted : {
                errors: []
            },
        }
    },

    mounted () {
        this.mpService = new MasterProposalService(this);
    },

    methods: {

        async deleteVehicle () {
            const httpRes = this.httpDeleteVehicle();

            this.deleted.errors = httpRes.data.errors;

            if(this.deleted.errors.length) {
                this.$toasted.error("failed to delete vehicle, see errors");
                return;
            }

            this.$toasted.success("Vehicle deleted");
            this.$emit("vehicleDeleted");
        },

        async runVehicleSearch () {
            const res = await this.httpSearchVehicle();

            this.search.errors = [];

            if(res["errors"].length) {
                this.search.errors = res["errors"];
                this.$toasted.error("Failed to search vehicle");
                return;
            }

            this.$toasted.success("Vehicle search and updated");
            this.$emit("vehicleUpdated");
        },

        async updateVehicle () {
            let me = this;

            const httpRes = await this.httpUpdateVehicle();

            this.update.errors = httpRes.errors;

            if(this.update.errors.length) {
                this.$toasted.error("Failed to update vehicle");
                return;
            }

            this.$toasted.success("Vehicle updated");
            this.$emit("vehicleUpdated");

        },

        /**
         *
         * http methods
         *
         */

        httpUpdateVehicle () {
            return this.mpService.updateVehicle(this.mpEntity.getID(), this.entityMpVehicle.getID(), this.entityMpVehicle);
        },

        httpSearchVehicle () {
            return this.mpService.searchVehicle(this.mpEntity.getID(), this.entityMpVehicle.getID());
        },

        httpDeleteVehicle () {
            return this.mpService.deleteVehicle(this.mpEntity.getID(), this.entityMpVehicle.getID());
        }

    }

});
