
import Entity from "npm-marsh-core/src/entity/Entity";

export default class EntityQuoteCommission extends Entity {

    protected   id: number|null = null;
    protected   proposalQuoteID: number|null = null;
    protected   commissionAmount: number|null = null;
    protected   supplierTypeID: number|null = null;
    protected   supplierID: number|null = null;
    protected   supplierUnknown: number|null = null;

    protected   supplierName: string|null = null;
    protected   bankAccount: string|null = null;
    protected   bankSortcode: string|null = null;
    protected   userFirstname: string|null = null;
    protected   userSecondname: string|null = null;
    protected   createdAt: string|null = null;

    protected mapDbProperties = {
        "proposal_quote_commission_id": "id",
        "proposal_quote_commission_proposal_quote_id": "proposalQuoteID",
        "proposal_quote_commission_amount": "commissionAmount",
        "proposal_quote_commission_supplier_type_id": "supplierTypeID",
        "proposal_quote_commission_supplier_id": "supplierID",
        "proposal_quote_commission_supplier_unknown": "supplierUnknown",
        "proposal_quote_commission_supplier_name": "supplierName",
        "proposal_quote_commission_supplier_bank_account": "bankAccount",
        "proposal_quote_commission_supplier_bank_sortcode": "bankSortcode",
        "user_first_name": "userFirstname",
        "user_last_name": "userSecondname",
        "proposal_quote_commission_created_at": "createdAt"
    };


}
