
import Vue from 'vue';

import MixinConstant from "@coreMixin/store/Constant.mixin";

import SupplierFilter from "./SupplierFilter.vue";

export default Vue.extend({

    mixins: [ MixinConstant ],

    components: {
        SupplierFilter
    },

    props: {
        filterObj:
        {
            type: Object
        },
        reduceFilter:
        {
            type: Boolean,
            default: false
        }
    },

    computed: {

        getProposalStates ()
        {
            return this.storeConstantGetList("proposal_status");
        },

        getListItemsPageOpts ()
        {
            return [
                { val: 10, text: "10" },
                { val: 15, text: "15" },
                { val: 20, text: "20" }
            ];
        }
    }

});
