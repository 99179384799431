<script>
    import Vue from 'vue';

    import EventBus from "@core/event-bus/EventBus";
    import EntityChecklistItem from "@coreEntity/checklist/item/Item.entity";

    export default Vue.extend({

        props: {

            entityChecklistItem: {
                type: EntityChecklistItem
            },

            disabled: {
                type: Boolean,
                default: false
            },

            compact: {
                type: Boolean,
                default: false
            },

            readOnly: {
                type: Boolean,
                default: false
            }

        },

        computed: {

            getClassList () {
                let arr = ["checklist-list-item"];

                if(this.entityChecklistItem.getDeletedAt()) {
                    arr.push("cli--deleted")
                }

                if(this.compact) {
                    arr.push("cli--compact")
                }

                return arr.join(" ");
            },

            itemID () {
                return this.entityChecklistItem.getID()
            },

            text () {
                return this.entityChecklistItem.getText();
            },

            typeEntity () {
                return this.entityChecklistItem.getEntityItemType();
            },

            hasType () {
                return this.typeEntity.getID() ? true: false;
            },

            itemDisabled () {
                return this.entityChecklistItem.getChecked() && !this.entityChecklistItem.getDeletedAt() || this.disabled === true ? true: false;
            },

            itemComment: {
                get () {
                    return this.entityChecklistItem.getComment();
                },
                set (val) {
                    this.entityChecklistItem.setComment(val);
                }
            },

            itemRemovable () {
                return this.entityChecklistItem.getRemovable() && !this.entityChecklistItem.getDeletedAt() ? true: false;
            },

            getItemTitle () {
                let createdBy = this.userCreatedText,
                    createdAt = this.$moment(this.entityChecklistItem.getCreatedAt()).format("DD/MM/Y HH:mm:ss"),
                    commentBy = this.userCommentText,
                    checkedBy = this.userCheckedText,
                    checkedAt = this.entityChecklistItem.getUpdatedAt() ? this.$moment(this.entityChecklistItem.getUpdatedAt()).format("DD/MM/Y HH:mm:ss"): null,
                    deletedBy = this.userDeletedText,
                    deletedAt = this.isDeleted === true ? this.$moment(this.entityChecklistItem.getDeletedAt()).format("DD/MM/Y HH:mm:ss"): null;

                let arr = [];

                if(createdAt) {
                    arr.push(`Created by - '${createdBy}' at '${createdAt}'`);
                }

                if(this.entityChecklistItem.hasComment()) {
                    arr.push(`Comment by - '${commentBy}'`);
                }

                if(this.entityChecklistItem.isChecked()) {
                    arr.push(`Checked by - '${checkedBy}' at '${checkedAt}'`);
                }

                if(this.entityChecklistItem.isDeleted()) {
                    arr.push(`Deleted by - '${deletedBy}' at '${deletedAt}'`);
                }

                return arr.join("\n");
            },

            isDeleted () {
                return this.entityChecklistItem.getDeletedAt() !== null;
            },

            userCreatedText () {
                let userEntity = this.entityChecklistItem.getEntityUserCreated();

                return userEntity.isEmpty() === true ? "System": userEntity.getName();
            },

            userCheckedText () {
                let userEntity = this.entityChecklistItem.getEntityUserCompleted();

                return userEntity.isEmpty() === true ? "System": userEntity.getName();
            },

            userCommentText ()
            {
                let userEntity = this.entityChecklistItem.getEntityUserComment();

                return userEntity.isEmpty() === true ? "System": userEntity.getName();
            },

            userDeletedText () {
                let userEntity = this.entityChecklistItem.getEntityUserDeleted();

                return userEntity.isEmpty() === true ? "System": userEntity.getName();
            },

        },

        data () {
            return {
                flag: {
                    canComment: true,
                    canCheck: true
                }
            };
        },

        mounted () {
            this.setFlags();
            EventBus.bindChecklistItemRefresh(this.itemID, this.setFlags);
        },

        methods: {

            setFlags () {
                this.flag.canCheck = this.entityChecklistItem.getChecked() || this.entityChecklistItem.getDeletedAt() ? false: true;
                this.flag.canComment = this.entityChecklistItem.getComment() || this.entityChecklistItem.getDeletedAt() ? false: true;
            },

            checkItem () {
                if(this.flag.canCheck === false) {
                    return;
                }

                this.entityChecklistItem.setChecked(1);
                this.$emit("checklistItemCompleted", this.itemID);
            },

            removedItem () {
                if(!this.entityChecklistItem.getRemovable()) {
                    return;
                }

                this.entityChecklistItem.setDeletedAt(this.$moment());
                this.$emit("checklistItemRemove", this.itemID);
            },

            addComment () {
                if(this.flag.canComment === false) {
                    return;
                }

                if(!this.itemComment) {
                    return;
                }

                //emit the event and apply comment
                EventBus.emitChecklistItemRefresh(this.itemID);
                this.$emit("evChecklistItemAddComment", this.itemID, this.itemComment);
                this.entityChecklistItem.setComment(this.itemComment);
            }

        }

    });
</script>
<template>

    <div v-if="entityChecklistItem" :class="getClassList">

        <div class="checklist-list-item-top">
            <div class="c-l-i-icon">
                <AppIcon icon="mdi-information" :colour="4" :title="getItemTitle" />
            </div>
            <div class="c-l-i-text">{{text}}</div>
            <div class="c-l-i-end">
                <!-- <AppIcon v-if="itemRemovable === true" title="Delete" icon="mdi-trash-can" :colour="6" @click="removedItem" /> -->
                <AppPill v-if="hasType === true">{{typeEntity.getName()}}</AppPill>
                <!-- <AppPill v-if="entityChecklistItem.supplierVis" colour="error">Supplier Visible</AppPill> -->

                <AppCheckbox :disabled="true"
                    :value="entityChecklistItem.checked" @click="checkItem"
                />
            </div>
        </div>

        <div class="checklist-list-item-bottom">
            <AppTextField placeholder="No Comment" :disabled="readOnly === true" v-model="itemComment" />
            <AppIcon v-if="flag.canComment === true && readOnly === false" title="Upload Comment" icon="mdi-chat-plus" :colour="3"
                @click="addComment"
            />
        </div>

    </div>

</template>
<style lang="scss">

    .checklist-list-item {

        .checklist-list-item-top {
            display: flex;
            align-items: flex-end;

            .c-l-i-icon {
                padding-right: .5em;

            }

            .c-l-i-text {
                flex-grow: 1;
                font-size: .8em;
            }

            .c-l-i-end {
                display: flex;
                align-items: flex-end;
                padding-left: .5em;

                > * {
                    margin-right: .3em;

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }
        }

        .checklist-list-item-bottom {
            display: flex;
            align-items: flex-end;

            .v-icon {
                padding-left: .5em;
            }
        }

        &.cli--deleted {
            background-color: var(--v-error-base);
            opacity: .75 ;

            &:hover {
                background-color: var(--v-error-base) !important;
            }
        }

        &.cli--compact {

            .checklist-list-item-top {
                flex-wrap: wrap;

                .c-l-i-text {
                    flex-basis: 100%;
                    order: 1;
                }

                .c-l-i-icon {
                    order: 2;
                }

                .c-l-i-end {
                    order: 3;
                    flex-grow: 1;
                    justify-content: flex-end;
                }

            }
        }

    }

</style>