
import Entity from "npm-marsh-core/src/entity/Entity";
export default class EntityQuote extends Entity {

    protected   id: number|null = null;
    protected   inputTypeID: number|null = null;
    protected   typeID: number|null = null;
    protected   validByQuote: number|null = 1;
    protected   editedByQuote: number|null = 0;
    protected   creditTier: number|null = null;
    protected   term: number|null = null;
    protected   firstPayment: any|null = null;
    protected   avgPayment: any|null = null;
    protected   lastPayment: any|null = null;
    protected   rate: any|null = null;
    protected   trueApr: any|null = null;
    protected   apr: any|null = null;
    protected   netApr: any|null = null;
    protected   amountPayable: any|null = null;
    protected   cashPrice: any|null = null;
    protected   vehicleMileage: any|null = null;
    protected   vehicleMileageUnit: any|null = null;
    protected   usedVehicleGGR: any|null = null;
    protected   annualMileage: any|null = null;
    protected   maxMileage: any|null = null;
    protected   interest: any|null = null;
    protected   advance: any|null = null;
    protected   comission: any|null = null;
    protected   comissionDisplay: any|null = null;
    protected   totalPayable: any|null = null;
    protected   totalCharge: any|null = null;
    protected   subsidy: any|null = null;
    protected   liabilityReposs: any|null = null;
    protected   liabilityTermin: any|null = null;
    protected   liabilityDailyInterst: any|null = null;
    protected   pencePerMile: any|null = null;
    protected   notes: string[]|null;
    protected   gfv: any|null = null;
    protected   gfvBase: any|null = null;
    protected   settlement: any|null = null;
    protected   deposit: any|null = null;
    protected   partEx: any|null = null;
    protected   feeDoc: any|null = null;
    protected   feePurchase: any|null = null;
    protected   vehicleDeviceReq: any|null = null;
    protected   vehicleConditionID: any|null = null;
    protected   paymentSchedule: Object[]|null = null;
    protected   minNetIncome: any|null = null;
    protected   ltvPercentage: number|null = null;
    protected   ltvMaxPrice: number|null = null;
    protected   createdAt: string|null = null;

    protected mapDbProperties = {
        "quote_id": "id",
        "quote_input_type_id": "inputTypeID",
        "quote_type_id": "typeID",
        "quote_valid": "validByQuote",
        "quote_edited": "editedByQuote",
        "quote_credit_tier": "creditTier",
        "quote_term": "term",
        "quote_first_payment": "firstPayment",
        "quote_avg_payment": "avgPayment",
        "quote_final_payment": "lastPayment",
        "quote_rate": "rate",
        "quote_true_apr": "trueApr",
        "quote_apr": "apr",
        "quote_net_apr": "netApr",
        "quote_amount_payable": "amountPayable",
        "quote_cash_price": "cashPrice",
        "quote_vehicle_mileage": "vehicleMileage",
        "quote_vehicle_mileage_unit": "vehicleMileageUnit",
        "quote_annual_mileage": "annualMileage",
        "quote_max_mileage": "maxMileage",
        "quote_input_vehicle_ggr": "usedVehicleGGR",
        "quote_interest": "interest",
        "quote_advance": "advance",
        "quote_marketing_support_total": "comission",
        "quote_marketing_support": "comissionDisplay",
        "quote_total_payable": "totalPayable",
        "quote_total_charge": "totalCharge",
        "quote_subsidy_amount": "subsidy",
        "quote_liability_repossession": "liabilityReposs",
        "quote_liability_termination": "liabilityTermin",
        "quote_liability_daily_interest": "liabilityDailyInterst",
        "quote_pence_per_mile": "pencePerMile",
        "quote_notes": "notes",
        "quote_residual_calc": "gfv",
        "quote_residual_base": "gfvBase",
        "quote_settlement": "settlement",
        "quote_deposit": "deposit",
        "quote_part_ex": "partEx",
        "quote_fee_document": "feeDoc",
        "quote_fee_purchase": "feePurchase",
        "quote_vehicle_device_req": "vehicleDeviceReq",
        "quote_vehicle_condition_id": "vehicleConditionID",
        "quote_schedule_json": "paymentSchedule",
        "quote_min_net_income": "minNetIncome",
        "quote_ltv_percentage": "ltvPercentage",
        "quote_ltv_max_price": "ltvMaxPrice",
        "quote_vat_entity": {
            entity_getter: "vatEntity",
            entity_setter: "vatEntity"
        },
        "quote_created_at": "createdAt",
    };

    public canActivate (): boolean 
    {
        return this.getQuoteValid() === 1;
    }

    public getShortDecription (): string
    {
        return `£${this.getAdvance()}`;
    }

    public isPcp (): boolean {
        return this.getTypeID() === 2;
    }

    public getTypeID (): number|null {
        return this.typeID;
    }

    public getCreatedAt (): string {
        return this.createdAt;
    }

    public getQuoteValid (): number|null 
    {
        return this.validByQuote;
    }

    public getQuoteEdited (): number|null 
    {
        return this.editedByQuote;
    }

    public getInputTypeID (): number|null {
        return this.inputTypeID;
    }

    public getInputTypeText (): string {
        switch (this.getInputTypeID()) {
            case 1:
                return "Standard";
            case 2:
                return "Budget";
            case 3:
                return "Non conformance";
        }

        return "No Type";
    }

    public getTier ()
    {
        return this.creditTier;
    }

    public getTerm () {
        return this.term;
    }

    public getInterest () {
        return this.interest;
    }

    public getAdvance () {
        return this.advance;
    }

    public getFirstPayment () {
        return this.firstPayment;
    }

    public getAveragePayment () {
        return this.avgPayment
    }

    public getFinalPayment () {
        return this.lastPayment;
    }

    public getFlatRate () {
        return this.rate;
    }

    public getCashPrice () {
        return this.cashPrice;
    }

    public getVehicleMileage () 
    {
        return this.vehicleMileage;
    }

    public getVehicleMileageUnit () 
    {
        return this.vehicleMileageUnit;
    }

    public getUsedVehicleGgr () 
    {
        return this.usedVehicleGGR;
    }

    public getAmountPayble () {
        return this.amountPayable;
    }

    public getApr () {
        return this.apr;
    }

    public getNetApr () {
        return this.netApr;
    }

    public getTrueApr () {
        return this.trueApr;
    }

    public getPayable ()
    {
        return this.amountPayable;
    }

    public getTotalPayable () {
        return this.totalPayable;
    }

    public getTotalCharge () {
        return this.totalCharge;
    }

    public getDeposit () {
        return this.deposit;
    }

    public getPartEx () {
        return this.partEx;
    }

    public getTotalDeposit () {
        return parseFloat(this.deposit) + parseFloat(this.partEx);
    }

    public getSettlement () {
        return this.settlement;
    }

    public getCommission () {
        return this.comission;
    }

    public getCommissionDisplay () {
        return this.comissionDisplay;
    }

    public getDocFee () {
        return this.feeDoc;
    }

    public getOptionFee () {
        return this.feePurchase;
    }

    public getNotes () {
        return this.notes;
    }

    public getSubsidy () {
        return this.subsidy;
    }

    public getDevReq () {
        return this.vehicleDeviceReq;
    }

    public getVehicleConditionID () {
        return this.vehicleConditionID;
    }

    public getVehicleConditionText () {
        switch (this.getVehicleConditionID()) {
            case 1:
                return "New";
            case 2:
                return "Used";
        }

        return "N/A";
    }

    public getAnnualMileage () {
        return this.annualMileage;
    }

    public getMaxMileage () {
        return this.maxMileage;
    }

    public getPencePerMile () {
        return this.pencePerMile;
    }

    public getGFV ()
    {
        return this.gfv;
    }

    public getLtvPercentage ()
    {
        return this.ltvPercentage;
    }

    public getLtvMaxPrice ()
    {
        return this.ltvMaxPrice;
    }

}