
import Service from "../../Service";

export default class QuoteService extends Service
{

    public getActiveSupplierLinks ()
    {
        return this.post("/quote/supplier-links", {});
    }

}