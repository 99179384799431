























































































































































































































import Vue from 'vue';

import EntityProposalQuote from "@coreEntity/master-proposal/proposal/quote/ProposalQuote.entity";
import ConstantMixin from "@coreMixin/store/Constant.mixin";

export default Vue.extend({

    mixins: [ ConstantMixin ],

    props: {

        entityProposalQuote:
        {
            type: EntityProposalQuote
        },

        showVehicleFields:
        {
            type: Boolean,
            default: true
        }

    },

    computed: {

        entityQuote () {
            return this.entityProposalQuote.getQuoteEntity();
        },

        notes () {
            return this.entityQuote.getNotes();
        }

    },

    data () {
        return {
            view: {
                input: false
            }
        };
    },

    methods: {

        getLoanTypeText (loanTypeID: Number): string {
            return this.storeConstantGetListItemID("quote_loan", loanTypeID).text;
        }

    }

});
