
import SupplierStore from "./supplier.store";

export default {

    namespaced: true,

    modules: {
        SupplierStore
    }

}