
import Vue from 'vue';

//service
import MasterProposalService from "@coreService/master-proposal/Index.service";

//entities
import MasterProposalEntity from "@coreEntity/master-proposal/MasterProposal.entity";
import MpChecklistCollectionEntity from "@coreEntity/master-proposal/checklist/MasterProposalChecklist.collection";

//components
import Tabbed from "./display/Tab.vue";
import Select from "./display/Select.vue";
import CmpChecklistCreate from "@coreComponent/master-proposal/id/checklist/create/CreateChecklist.vue";

export default Vue.extend({

    components: {
        Tabbed, Select, CmpChecklistCreate
    },

    props: {

        mpEntity: {
            type: MasterProposalEntity
        },

        display: {
            type: String,
            default: "Tabbed"
        },

        loadChecklistItems: {
            type: Boolean,
            default: true
        },

        optsShowChecked: {
            type: Number,
            default: null
        },

        disabled: {
            type: Boolean,
            default: false
        },

        compact: {
            type: Boolean,
            default: false
        },

        readOnly: {
            type: Boolean,
            default: false
        }

    },

    computed: {

        mpChecklistCollectionEntity () {
            return this.mpEntity.getCollectionChecklist();
        },

        dbMpChecklists () {
            if(!this.mpChecklistCollectionEntity) {
                return [];
            }

            return this.mpChecklistCollectionEntity.getItems();
        },

        canApplyPreset () {
            if(this.disabled === true) {
                return false;
            }

            return this.dbMpChecklists.length >= 1;
        }

    },

    data (): IData {
        return {
            modal: {
                createChecklist: false
            }
        };
    },

    methods: {

        async setChecklists () {
            const httpRes = await this.httpGetChecklists();

            const mpChecklistCollection = new MpChecklistCollectionEntity();
            mpChecklistCollection.mapApplyDatabase(httpRes.data);

            this.mpEntity.setCollectionChecklist(mpChecklistCollection);
        },

        //an checklist has been altered, reload data
        evChecklistRefresh (mpChecklistID: Number) {
            this.setChecklists();
        }
    }

});

interface IData {
    modal: IModals
}

interface IModals {
    createChecklist: Boolean
}

