import Entity from "npm-marsh-core/src/entity/Entity";


import EntityItemCollection from "./item/Item.collection";
import EntityChecklistType from "./Type.entity";

export default class EntityChecklist extends Entity {

    protected id: Number|null = null;
    protected name: String|null = null;
    protected desc: String|null = null;
    protected editable: Number|null = 0;
    protected createdAt: String|null = null;

    protected entityItemCollection: EntityItemCollection;
    protected entityType: EntityChecklistType;

    protected mapDbProperties = {
        "checklist_id": "id",
        "checklist_name": "name",
        "checklist_desc": "desc",
        "checklist_editable": "editable",
        "checklist_created_at": "createdAt",
        "checklist_type_entity": {
            entity_getter: "entityType",
            entity_setter: "entityType",
        },
        "checklist_item_collection_entity": {
            entity_getter: "entityItemCollection",
            entity_setter: "entityItemCollection",
        }
    };

    constructor () {
        super();

        this.entityItemCollection = new EntityItemCollection();
        this.entityType = new EntityChecklistType();
    }

    public getItemCollection () {
        return this.entityItemCollection;
    }

    public getTypeEntity () {
        return this.entityType;
    }

    public getName () {
        return this.name;
    }

    public getDesc () {
        return this.desc;
    }

    public getEditable () {
        return this.editable;
    }
}