import Entity from "npm-marsh-core/src/entity/Entity";

import EntityGroup from "./ItemGroup.entity";
import EntityItemType from "./ItemType.entity";
import EntityUser from '../../user/User.entity';

export default class EntityChecklistItem extends Entity {

    protected id: number|null = null;
    protected checked: number = 0;
    protected removeable: number = 0;
    protected supplierVis: number = 0;
    protected text: string = "";
    protected comment: string = "";
    protected createdAt: string|null = null;
    protected updatedAt: string|null = null;
    protected deletedAt: string|null = null;

    protected entityItemType: EntityItemType;
    protected entityItemGroup: EntityGroup;
    protected userCreatedEntity: EntityUser;
    protected userCompletedEntity: EntityUser;
    protected userDeletedEntity: EntityUser;
    protected userCommentEntity: EntityUser;

    protected mapDbProperties = {
        "checklist_item_id": "id",
        "checklist_item_checked": "checked",
        "checklist_item_text": "text",
        "checklist_item_comment": "comment",
        "checklist_item_removable": "removeable",
        "checklist_item_supplier_visible": "supplierVis",
        "checklist_item_created_at": "createdAt",
        "checklist_item_updated_at": "updatedAt",
        "checklist_item_deleted_at": "deletedAt",
        "checklist_item_type_id": {
            getter: "getTypeID",
            setter: "setTypeID"
        },
        "checklist_item_group_id": {
            getter: "getGroupID",
            setter: "setGroupID"
        },
        "checklist_item_group_entity":{
            entity_getter: "entityItemGroup",
            entity_setter: "entityItemGroup",
        },
        "checklist_item_type_entity": {
            entity_getter: "entityItemType",
            entity_setter: "entityItemType",
        },
        "checklist_item_user_created_entity": {
            entity_getter: "userCreatedEntity",
            entity_setter: "userCreatedEntity",
        },
        "checklist_item_user_completed_entity": {
            entity_getter: "userCompletedEntity",
            entity_setter: "userCompletedEntity",
        },
        "checklist_item_user_deleted_entity": {
            entity_getter: "userDeletedEntity",
            entity_setter: "userDeletedEntity",
        },
        "checklist_item_user_comment_entity": {
            entity_getter: "userCommentEntity",
            entity_setter: "userCommentEntity",
        }
    };

    constructor () {
        super();

        this.entityItemGroup = new EntityGroup();
        this.entityItemType = new EntityItemType();

        this.userCreatedEntity = new EntityUser();
        this.userCompletedEntity = new EntityUser();
        this.userDeletedEntity = new EntityUser();
        this.userCommentEntity = new EntityUser();
    }

    public hasComment (): boolean {
        return this.comment === null || this.comment === "" ? false: true;
    }

    public isChecked (): boolean {
        return !!this.checked;
    }

    public isDeleted (): boolean {
        return this.getDeletedAt() ? true: false;
    }

    /**
     *
     * Getters
     *
     */

    public getEntityItemGroup () {
        return this.entityItemGroup;
    }

    public getEntityItemType () {
        return this.entityItemType;
    }

    public getEntityUserCreated () {
        return this.userCreatedEntity;
    }

    public getEntityUserCompleted () {
        return this.userCompletedEntity;
    }

    public getEntityUserDeleted () {
        return this.userDeletedEntity;
    }

    public getEntityUserComment () {
        return this.userCommentEntity;
    }

    /**
     *
     * Setters
     *
     */

    setTypeID(typeID: number) {
        this.entityItemType.setID(typeID);
    }

    setGroupID(typeID: number) {
        this.entityItemGroup.setID(typeID);
    }

    setChecked (val: number) {
        this.checked = val  ? val: 0;
    }

    setText (text: string) {
        this.text = text;
    }

    setSupplierVisible (val: number) {
        this.supplierVis = val;
    }

    setRemovable(val: number) {
        this.removeable = val;
    }

    setComment (comment: string) {
        this.comment = comment;
    }

    setDeletedAt (dt: string) {
        this.deletedAt = dt;
    }

    /**
     *
     * Getters
     *
     */

    public getID () {
        return this.id;
    }

    public getTypeID () {
        return this.entityItemType.getID();
    }

    public getGroupID () {
        return this.entityItemGroup.getID();
    }

    public getText () {
        return this.text;
    }

    public getComment () {
        return this.comment;
    }

    public getChecked () {
        return this.checked;
    }

    public getRemovable () {
        return this.removeable;
    }

    public getVisible () {
        return this.supplierVis;
    }

    public getCreatedAt () {
        return this.createdAt;
    }

    public getUpdatedAt () {
        return this.updatedAt;
    }

    public getDeletedAt () {
        return this.deletedAt;
    }

    /**
     *
     * Custom maps
     *
     */

    public static getMapCreatable () {
        return this.mapToCreate;
    }

    //map to send the entity to a create object
    protected static mapToCreate = {
        checklist_item_text: "text",
        checklist_item_supplier_visible: "supplierVis",
        checklist_item_removable: "removeable",
        checklist_item_type_id: {
            getter: "getTypeID"
        },
        checklist_item_group_id: {
            getter: "getGroupID"
        }
    };
}