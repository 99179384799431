

















































































































import Vue from 'vue';

import EntityVehicle from "@coreEntity/vehicle/Vehicle.entity";

export default Vue.extend({

    props: {

        entityVehicle: 
        {
            type: EntityVehicle
        },

        showQuotingFields: {
            type: Boolean,
            default: true
        }

    },

    data () {
        return {
            config: {
                showIreFields: parseInt(process.env.VUE_APP_QUOTE_IRE_FIELDS) ? true: false
            }
        }
    }

});
