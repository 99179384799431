
import Vue from 'vue';

import MixinQuote from "@coreMixin/store/ApiQuote.mixin";

export default Vue.extend({

    mixins: [MixinQuote],

    props: {
        userSuppliers: {
            type: Array
        }
    },

    computed: {

        allowAttachFunder () {
            const env = process.env.VUE_APP_USER_CREATE_ALLOW_FUNDER_ALLOC ? parseInt(process.env.VUE_APP_USER_CREATE_ALLOW_FUNDER_ALLOC): 0;

            return env ? true: false;
        }

    },

    data () {
        return {
            supplier: {
                funderList: [],
                brokerList: [],
                dealerList: []
            }
        };
    },

    mounted () {
        this.supplier.funderList = this.getFunders();
        this.supplier.brokerList = this.getBrokers();
        this.supplier.dealerList = this.getDealers();
    },

    methods: {

        hasUserSupplier (type: string, id: number): boolean {
            const obj =  this.userSuppliers.find(ele => {
                return ele.user_supplier_map_supplier_id === id && ele.user_supplier_map_supplier_enum === type;
            });

            return obj ? true: false;
        },

        toggleGroupID (type: string, id: number) {
            const exists: boolean = this.hasUserSupplier(type, id);

            if(exists) {
                this.$emit("removeSupplier", type, id);
            }
            else {
                this.$emit("addSupplier", type, id);
            }

        },

        /**
         *
         * Supplier setup
         *
         */

        getFunders () {
            return this.storeSuppliersGetFunders();
        },

        getBrokers() {
            return this.storeSuppliersGetBrokers();
        },

        getDealers () {
            return this.storeSuppliersGetDealers();
        }

    }

});
