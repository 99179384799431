import Entity from "npm-marsh-core/src/entity/Entity";

export default class Bank extends Entity {

    public id: number|null = null;
    public name: string|null = null;
    public accountNum: string|null = null;
    public sortcode: string|null = null;
    public yearMonth: string|null = null;
    public createdAt: string|null = null;

    protected mapDbProperties = {
        "bank_id": "id",
        "bank_name": "name",
        "bank_account_num": "accountNum",
        "bank_sortcode": "sortcode",
        "bank_year_month": "yearMonth",
        "bank_created_at": "createdAt",
    };

}