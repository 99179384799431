
import EntityCollection from "npm-marsh-core/src/entity/EntityCollection";

import EntityNote from "./MpNote.entity";

export default class EntityCollectionNote extends EntityCollection {

    public getNewItem (): EntityNote {
        return new EntityNote();
    }

}