

import AuthMixin from "@coreMixin/auth/Auth.mixin.js";

export default {

    mixins: [ AuthMixin ],

    created()
    {
        this.deleteAuth();
    }

}

