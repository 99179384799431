
import Vue from 'vue';

import AuthService from "@coreService/user/Auth.service";

import LoginContainer from "../component/LoginContainer.vue";

export default Vue.extend({

    components: {
        LoginContainer
    },

    computed: {

        setupErrors () {
            return !this.token;
        },

        formErrors () {
            let arr = [];

            if(this.model.password !== this.model.new_password) {
                arr.push("Passwords don't match");
            }

            return arr;
        }

    },

    data () {
        return {
            token: null,
            model: {
                password: "",
                new_password: ""
            },
            httpErrors: []
        };
    },

    mounted () {
        this.token = this.$route.query.token_rp;
    },

    methods: {

        async fireUpdate () {
            this.httpErrors = [];

            const serv = new AuthService(this);
            const httpRes = await serv.recoverAccount(this.token, this.model.password);

            if(httpRes.hasErrors()) {
                this.$toasted.error("Account recover failed, please see errors");
                this.httpErrors = httpRes.getErrors();
                return;
            }

            this.$toasted.info("Password has been changed, please login");
            this.$router.push({name: 'login'});
        }

    }

});
