
import EntityCollection from "npm-marsh-core/src/entity/EntityCollection";

import EntityDocument from "./Document.entity";

export default class CollectionDocument extends EntityCollection {

    public getNewItem (): EntityDocument {
        return new EntityDocument();
    }

}