
import Entity from "npm-marsh-core/src/entity/Entity";

import EntityVehicle from "@coreEntity/vehicle/Vehicle.entity";
import EntityUser from '../../user/User.entity';

export default class EntityMasterProposalVehicle extends Entity {

    protected   id: number|null = null;
                vehicleID: number|null = null;
                createdAt: string|null = null;

    protected vehicleEntity: EntityVehicle;
    protected userEntity: EntityUser;

    protected mapDbProperties = {
        "proposal_master_vehicle_id": "id",
        "proposal_master_vehicle_vehicle_id": "vehicleID",
        "proposal_master_vehicle_created_at": "createdAt",
        "proposal_master_vehicle_entity": {
            entity_getter: "vehicleEntity",
            entity_setter: "vehicleEntity"
        }
    };

    constructor (vehicleEntity: EntityVehicle|null = null, userEntity: EntityUser = null) {
        super();

        this.vehicleEntity = vehicleEntity ? vehicleEntity: new EntityVehicle();
        this.userEntity = userEntity ? userEntity: new EntityUser();
    }

    /**
     *
     * Entity methods
     *
     */

    public getEntityVehicle (): EntityVehicle {
        return this.vehicleEntity;
    }

    public getEntityUser (): EntityUser {
        return this.userEntity;
    }

    public getVehicleText (): string {
        return this.vehicleEntity.getDescriptiveText();
    }

    public getUsersName () {
        return this.userEntity.getID() ? this.userEntity.getName(): "System";
    }

    //is the vehicle filled in?
    public isEmpty (): boolean {
        return this.vehicleEntity.isEmpty();
    }

    /**
     *
     * Getters
     *
     */

    public getID (): number|null {
        return this.id;
    }

    public getCreatedAt () {
        return this.createdAt;
    }
}