
import Entity from "npm-marsh-core/src/entity/Entity"

export default class EntityMpOpenBanking extends Entity {

    public id: number|null = null;
    public referralNote: string|null = null;
    public referred: string|null = null;
    public applicantDisplayName: string|null = null;
    public createdAt: number|null = null;
    public internalId: number|null = null;
    public sessionId: number|null = null;
    public stateId: number|null = null;
    public url: string|null = null;
    public cancellationComment:  string|null = null;
    public browser:  string|null = null;
    public changedAt: number|null = null;

    protected mapDbProperties = {
        proposal_master_ob_id: "id",
        proposal_master_ob_referral_note: "referralNote",
        proposal_master_ob_referred: "referred",
        proposal_master_ob_applicant_display_name: "applicantDisplayName",
        proposal_master_ob_created_at: "createdAt",
        ob_journey_internal_id: "internalId",
        ob_journey_internal_session_id: "sessionId",
        ob_journey_internal_state_id: "stateId",
        ob_journey_internal_url: "url",
        ob_journey_internal_cancellation_comment: "cancellationComment",
        ob_journey_internal_browser: "browser",
        ob_journey_internal_changed_at: "changedAt",

    }
    constructor () {
        super();
    }

    /**
     *
     * Getters
     *
     */


}