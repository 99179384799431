
import EntityCollection from "npm-marsh-core/src/entity/EntityCollection";

import OpenBankingEntity from "./OpenBanking.entity";

export default class EntityCollectionOpenBanking extends EntityCollection {

    public getNewItem (): OpenBankingEntity {
        return new OpenBankingEntity();
    }

}