// event-bus.js
import Vue from 'vue';
const EventBus = new Vue({

    methods: {

        userSupplierGroupChanged (groupID: number) {
            this.$emit(`userSupplierGroupChanged$`, {
                id: groupID
            });
        },

        onUserSupplierGroupChanged (callable) {
            this.$on(`userSupplierGroupChanged$`, callable);
        },

        emitChecklistItemRefresh (checklistItemID: number) {
            this.$emit(`checklistItemRefresh${checklistItemID}`);
        },

        bindChecklistItemRefresh (checklistItemID: number, method: any) {
            this.$on(`checklistItemRefresh${checklistItemID}`, method);
        }

    }

});

export default EventBus;