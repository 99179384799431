
import Vue from 'vue';

//service
import MpService from "@coreService/master-proposal/Index.service";
import ChecklistGroupService from "@coreService/checklist/Group.service";

//mixin
import MixinChecklist from "@coreMixin/store/Component/Checklist.mixin";

//entites
import EntityMpChecklist from "@coreEntity/master-proposal/checklist/MasterProposalChecklist.entity";
import EntityChecklist from "@coreEntity/checklist/Checklist.entity";
import CollectionChecklistItem from "@coreEntity/checklist/item/Item.collection";
import EntityChecklistItem from "@coreEntity/checklist/item/Item.entity";
import CollectionChecklistGroup from "@coreEntity/checklist/group//ChecklistGroup.collection";
import EntityChecklistGroup from "@coreEntity/checklist/group//ChecklistGroup.entity";

import ItemGroupView from "./component/ItemGroup.vue";

export default Vue.extend({

    mixins: [ MixinChecklist ],

    components: {
        ItemGroupView
    },

    props: {

        mpID: {
            type: Number
        },

        mpChecklistEntity: {
            type: EntityMpChecklist
        },

        checklistGroupItemCollection: {
            type: null,
            default: null
        },

        allowSupplierVisible: {
            type: Boolean,
            default: false
        }

    },

    computed: {

        groupArr () {
            return this.entityGroupCollection.getItems();
        },

        newItemsArr () {
            return this.items.newCollection.getItems()
        },

        createIsValid () {
            if(!this.create.checklist_item_text || this.create.checklist_item_department_id === null) {
                return false;
            }

            return true;
        },

        itemTypeList () {
            return this.storeComponentGetChecklistItemTypesEmpty();
        },

        departmentList () {
            return this.storeUserDepartmentGetCollectionEmpty();
        },

        removableList () {
            return this.getRemovableList();
        },

        supplierVisibleList () {
            return this.getSupplierVisibleList();
        }

    },

    data () {
        return {
            entityGroupCollection: null,
            items: {
                groupsAdded: [],
                customItemsCollection: new CollectionChecklistItem(),
                newCollection: new CollectionChecklistItem()
            },
            create: {
                checklist_item_text: null,
                checklist_item_type_id: null,
                checklist_item_department_id: null,
                checklist_item_supplier_visible: false,
                checklist_item_removable: false
            },
            http: {
                createErrors: []
            },
            viewGroup: {
                modal: false,
                entity: null
            }
        };
    },

    async mounted () {
        this.mpService = new MpService(this);
        this.checklistGroupService = new ChecklistGroupService(this);

        if(this.checklistGroupItemCollection === null) {
            this.entityGroupCollection = await this.getGroupItems();
        }
        else {
            this.entityGroupCollection = this.checklistGroupItemCollection;
        }
    },

    methods: {

        async getGroupItems () {
            const httpRes = await this.checklistGroupService.getChecklistGroups(true);

            const entityGroupCollection = new CollectionChecklistGroup();
            entityGroupCollection.mapApplyDatabase(httpRes.getData());

            return entityGroupCollection;
        },

        //add the new items to the checklist
        async createNewItems () {
            const newCustomItems = this.items.customItemsCollection.runCustomMap(EntityChecklistItem.getMapCreatable());

            const httpRes = await this.mpService.checklistAddItems(this.mpID, this.mpChecklistEntity.getID(), this.items.groupsAdded, newCustomItems);

            if(httpRes.hasErrors()) {
                this.$toasted.error("Failed to add checklist");
                this.http.createErrors = httpRes.getErrors();
                return;
            }

            this.items.groupsAdded = [];
            this.items.customItemsCollection = new CollectionChecklistItem();
            this.items.newCollection = new CollectionChecklistItem();

            this.$toasted.success("Items added");
            this.$emit("itemsAdded");
        },

        //decide whether to add/remove group items
        toggleGroup (groupEntity: EntityChecklistGroup) {
            const groupID = groupEntity.getID();

            if(this.items.groupsAdded.indexOf(groupID) === -1) {
                this.items.groupsAdded.push(groupID);
                this.addGroupItems(groupEntity);
            }
            else {
                this.items.groupsAdded.splice(this.items.groupsAdded.indexOf(groupID), 1);
                this.removeGroupItems(groupEntity);
            }
        },

        viewChecklistGroup (groupEntity: EntityChecklistGroup) {
            this.viewGroup.entity = groupEntity;
            this.viewGroup.modal = true;
        },

        //append a custom item
        addCustomItem () {
            let customItemEntity = new EntityChecklistItem();
            customItemEntity.mapApplyDatabase(this.create);

            this.items.newCollection.addItem(customItemEntity);
            this.items.customItemsCollection.addItem(customItemEntity);

            this.resetCreate();
        },

        addGroupItems (groupEntity: EntityChecklistGroup) {
            const itemCollection = groupEntity.convertToItemCollection();
            this.items.newCollection.merge(itemCollection);
        },

        removeGroupItems (groupEntity: EntityChecklistGroup) {
            this.items.newCollection.removeItemsViaGroupID(groupEntity.getID());
        },

        //reset create inputs
        resetCreate (groupEntity: EntityChecklistGroup) {
            this.create.checklist_item_text =  null;
            this.create.checklist_item_department_id =  null;
            this.create.checklist_item_type_id =  null;
            this.create.checklist_item_supplier_visible =  false;
            this.create.checklist_item_removable =  false;
        }

    }

});
