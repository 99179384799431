
import Vue from 'vue';

import UserMixin from "@coreMixin/store/User/User.mixin";

export default Vue.extend({

    mixins: [ UserMixin ],

    computed: {

        hasUserEntity()
        {
            return this.storeUserGetSessionEntity();
        },

        activeSupplierGroup () 
        {
            if(!this.hasUserEntity) {
                return null;
            }
            
            return this.storeUserGetActiveSupplierGroup();
        },

        activeRegion () 
        {
            return process.env.VUE_APP_REGION && process.env.VUE_APP_REGION === 'UK' ? 'UK': "LMO";
            // return this.activeSupplierGroup ? this.activeSupplierGroup.user_group_region: "UK";
        }

    }

});

