
import Vue from 'vue';

import CollectionMpUpload from "@coreEntity/master-proposal/upload/MpUpload.collection";

//cmps
import UploadList from "@coreComponent/master-proposal/id/upload/List/Index.vue";
import UploadCreate from "@coreComponent/master-proposal/id/upload/create/Create.vue";

export default Vue.extend({

    components: {
        UploadList, UploadCreate
    },

    props: {

        collectionMpUploads: {
            type: CollectionMpUpload
        },

        masterProposalID: {
            type: Number
        },

        disabled: {
            type: Boolean,
            default: false
        }

    },

    data () {
        return {
            last_filters: {},
        };
    },

    methods: {

        searchFilters (filters) {
            this.last_filters = filters;

            this.$emit("refresh", filters);
        },

        newUpload () {
            this.$emit("refresh", this.last_filters);
        }

    }

});
