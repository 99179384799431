import EntityCollection from "npm-marsh-core/src/entity/EntityCollection";

import EntityMpChecklist from "./MasterProposalChecklist.entity";

export default class MasterProposalChecklist extends EntityCollection {

    public getNewItem (): EntityMpChecklist {
        return new EntityMpChecklist();
    }

}