











































//consts
import MixinConstant from "@coreMixin/store/Constant.mixin";

//service
import MpDocService from "@coreService/master-proposal/document/MpDocument.service";

export default {

    mixins: [MixinConstant],

    props: {

        masterProposalID: {
            type: Number
        },

        disabled: {
            type: Boolean,
            default: false
        }

    },

    computed: {
        selectedFileIconClass() {
            // Check if a file has been selected
            if (this.droppedFile) {
                // Get the file extension
                const fileExtension = this.droppedFile.name.split('.').pop().toLowerCase();
                
                // Determine the icon class based on the file extension
                if (fileExtension === 'pdf') {
                    return 'mdi mdi-file-pdf'; // Replace with the actual class for PDF icon
                } else if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
                    return 'mdi mdi-file-image'; // Replace with the actual class for image icon
                }
            }
            // Default icon class when no file is selected
            return 'mdi mdi-file'; // Replace with a suitable default icon class
        },

        documentType() {
            return this.storeConstantGetList("proposal_document");
        },

        subProofCollection() {
            return this.storeConstantGetList("proposal_document_sub_type");
        },

        isValid() {
            let valid = true;

            if (this.model.typeID === null) {
                valid = false;
            }

            return valid;
        }
    },

    data() {
        return {
            model: {
                typeID: null
            },
            create: {
                errors: []
            },
            droppedFile: null,
        };
    },

    mounted() {
        this.docServ = new MpDocService(this);
    },

    methods: {

        handleFileInputClick() {
            // Clear the input value to allow selecting the same file again
            this.$refs["file-input"].value = null;
            this.$refs["file-input"].click();
        },
        handleFileInput(event) {
            const fileCollection = event.target.files;

            if (!fileCollection.length) {
                this.$toasted.error("Please select a file");
                return;
            }

            // Update droppedFile array with new files
            this.droppedFile = fileCollection[0];
        },

        // Update this method to handle both file drop and input change
        handleFileDrop(event) {
            event.preventDefault();
            const fileCollection = event.dataTransfer.files;

            if (!fileCollection.length) {
                this.$toasted.error("Please select a file");
                return;
            }

            // Update selectedFiles array with new files
            this.droppedFile = fileCollection[0];
        },

        //create handler
        createSubmit(formEvent) {
            
            if (!this.droppedFile) {
                this.$toasted.error("Please select a file")
                return;
            }
            //create the form data
            let formData = new FormData();

            formData.append("upload_type_id", this.model.typeID);
            formData.append("document", this.droppedFile, this.droppedFile.name);

            //emit it
            this.mpCreateDocument(formData);
        },

        //http create the document
        async mpCreateDocument(formData) {
            const httpRes = await this.docServ.createDocument(this.masterProposalID, formData);
        
            this.create.errors = httpRes.errors.concat(httpRes.data.errors ? httpRes.data.errors : []);
        
            //upload failed
            if (this.create.errors.length) {
                this.$toasted.error("Failed to upload the document, see errors");
                return;
            }
        
            // Clear selected files and reset document type selection
            this.droppedFile = null;
            this.model.typeID = "";
        
            this.$toasted.success("Document in queue to be uploaded");
            this.$emit("uploadCreated");
        },
    }

};

