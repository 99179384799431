




























































import Vue from 'vue';

import MixinConstant from "@coreMixin/store/Constant.mixin";

import AppEmploymentEntity from "@coreEntity/master-proposal/proposal/applicant/ApplicantEmployment.entity";
import EmploymentEntity from "@coreEntity/master-proposal/proposal/applicant/employment/Employment.entity";

import YearMonth from "@coreComponent/master-proposal/id/YearMonth.vue";

export default Vue.extend({

    components: { YearMonth },

    mixins: [ MixinConstant ],

    props: {
        entity: {
            type: AppEmploymentEntity
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    computed: {

        activeRegion () {
            return process.env.VUE_APP_REGION && process.env.VUE_APP_REGION === 'UK' ? 'UK': "LMO";
        },

        getEmploymentTypes () {
            return this.storeConstantGetListWithEmpty("employment_status");
        },


        employmentEntity (): EmploymentEntity {
            return this.entity.getEmploymentEntity();
        },

        formErrors () {
            const obj = {
                "applicant_employment_employment_type_id": [],
                "applicant_employment_job": [],
                "applicant_employment_month_at": [],
                "employment_name": [],
                "employment_line_1": [],
                "employment_town": [],
                "employment_postcode": []
            };

            if(!this.entity.employmentTypeID) {
                obj.applicant_employment_employment_type_id.push("Employment requires a type");
            }

            if(!this.employmentEntity.name) {
                obj.employment_name.push("Employment requires a name");
            }

            if(!this.entity.jobName) {
                obj.applicant_employment_job.push("Employment requires a job title");
            }

            if(!this.entity.monthAt) {
                obj.applicant_employment_month_at.push("Employment requires the months spent working at this job.");
            }

            // if(!this.employmentEntity.line1) {
            //     obj.employment_line_1.push("Employment requires a line 1");
            // }

            // if(!this.employmentEntity.town) {
            //     obj.employment_town.push("Employment requires a town");
            // }

            // if(!this.employmentEntity.postcode) {
            //     obj.employment_postcode.push("Employment requires a Eircode");
            // }

            return obj;
        }

    },

    methods: {

        getFormErrors (text) {
            return this.formErrors[text] ? this.formErrors[text]: [];
        }

    }

});
