export default {

    namespaced: true,

    state: {
        funder_collection: [],
        broker_collection: [],
        dealer_collection: []
    },

    mutations: {

        qsStoreSetSupplierCollection (state, objSupplierData) {
            state.funder_collection = objSupplierData.funder_collection;
            state.broker_collection = objSupplierData.broker_collection;
            state.dealer_collection = objSupplierData.dealer_collection;
        }

    },

    actions: {

        suppStoreActGetSupplier: function (context, data) {

            switch (data.type_id) {
                case 1:
                    return context.getters.suppStoreGetFunder({id: data.id});
                case 2:
                    return context.getters.suppStoreGetBroker({id: data.id});
                case 3:
                    return context.getters.suppStoreGetDealer({id: data.id});
            }

            return null;
        },

    },

    getters: {

        suppStoreGetFunder: (state) => (payload) => {
            return state.funder_collection.find( data => { return data.funder_id == payload.id } );
        },

        suppStoreGetBroker: (state) => (payload) => {
            return state.broker_collection.find( data => { return data.broker_id == payload.id } );
        },

        suppStoreGetDealer: (state) => (payload) => {
            return state.dealer_collection.find( data => { return data.dealer_id == payload.id } );
        },

        suppStoreGetFunderCollection (state) {
            return state.funder_collection;
        },

        suppStoreGetBrokerCollection (state) {
            return state.broker_collection;
        },

        suppStoreGetDealerCollection (state) {
            return state.dealer_collection;
        },

        suppStoreGetOutBoundFunderCollection (state) {
            return state.funder_collection.filter( data => { return data.funder_outbound == 1 } );
        },

        suppStoreGetInBoundFunderCollection (state) {
            return state.funder_collection.filter( data => { return data.funder_inbound == 1 } );
        },

    }

}