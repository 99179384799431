
import Entity from "npm-marsh-core/src/entity/Entity";

import CollectionSignatory from "./signatory/Signatory.collection";
import CollectionDocument from "./document/Document.collection";

export default class EntityEsign extends Entity {

    public id: number|null = null;
    public proposalID: number|null = null;
    public quoteID: number|null = null;
    public sessionID: number|null = null;
    public stateID: number|null = null;
    public comment: string|null = null;
    public dateCreated: string|null = null;

    public collectionSignatory: CollectionSignatory;
    public collectionDocument: CollectionDocument;

    protected mapDbProperties = {
        "proposal_edoc_id": "id",
        "proposal_edoc_proposal_id": "proposalID",
        "proposal_edoc_proposal_quote_id": "quoteID",
        "proposal_edoc_session_id": "sessionID",
        "proposal_edoc_state_id": "stateID",
        "proposal_edoc_comment": "comment",
        "proposal_edoc_date_created": "dateCreated",
        "proposal_edoc_date_updated": "dateUpdated",
        "proposal_edoc_date_deleted": "dateDeleted",
        "__signatory_collection": {
            entity_getter: "collectionSignatory",
            entity_setter: "collectionSignatory"
        },
        "__document_collection": {
            entity_getter: "collectionDocument",
            entity_setter: "collectionDocument"
        }
    };

    public constructor () {
        super();

        this.collectionSignatory = new CollectionSignatory();
        this.collectionDocument = new CollectionDocument();
    }

    public getCollectionSignatory () {
        return this.collectionSignatory;
    }

    public getCollectionDocument () {
        return this.collectionDocument;
    }

    public getID () {
        return this.id;
    }

    public getQuoteID () {
        return this.quoteID;
    }
}