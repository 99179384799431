
import Vue from 'vue';
import ImageLogo from "@coreComponent/_ui/logo/Image.vue";
import SocialLinks from "@coreComponent/_ui/social/Links.vue";

export default Vue.extend({

    props: {
        showResetPassword: {
            type: Boolean,
            default: true
        }
    },

    components: {
        ImageLogo, SocialLinks
    },

    computed: {

        image () {
            return process.env.VUE_APP_REGION ? process.env.VUE_APP_REGION: "LMO";
        }

    }

});
