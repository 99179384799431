
import Vue from 'vue';

import CollectionNotes from "@coreEntity/master-proposal/note/MpNote.collection";

export default Vue.extend({

    props: {


        noteCollection: {
            type: CollectionNotes
        }

    },

    computed: {

        items () {
            return this.noteCollection.getItems();
        }

    }

});
