import EntityCollection from "npm-marsh-core/src/entity/EntityCollection";

import EntityChecklistGroup from "./ChecklistGroup.entity";

export default class ChecklistGroupCollection extends EntityCollection {

    public getNewItem (): EntityChecklistGroup {
        return new EntityChecklistGroup();
    }

}