
import Vue from 'vue';

export default Vue.extend({

    props: {
        twoFactorUrl: {
            type: String
        }
    }

});
