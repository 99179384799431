
import Vue from 'vue';

import MixinChecklist from "@coreMixin/store/Component/Checklist.mixin";

import EntityChecklistGroup from "@coreEntity/checklist/group//ChecklistGroup.entity";

export default Vue.extend({

    mixins: [ MixinChecklist ],

    props: {
        itemGroupEntity: {
            type: EntityChecklistGroup
        }
    },

    computed: {

        items () {
            return this.itemGroupEntity.convertToItemCollection().getItems();
        }

    }

});
